import classes from "./ListText.module.css";
import { ListStar } from "../_icons";

interface ListProps {
  list: string[];
  tagClassName?: string;
}

export const List = ({ list, tagClassName = "p2" }: ListProps) => {
  return (
    <ul className={`${classes.ListWrap}`}>
      {list.map((item, index) => (
        <li className={classes.TextWrap} key={index}>
          <ListStar />
          <p className={tagClassName}>{item}</p>
        </li>
      ))}
    </ul>
  );
};
