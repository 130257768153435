export const GreenTelegramIcon = () => (
  <svg
    width="23"
    height="22"
    viewBox="0 0 23 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_4073_240)">
      <path
        d="M11.5045 21.8841C17.5131 21.8841 22.3841 17.0131 22.3841 11.0045C22.3841 4.99593 17.5131 0.125 11.5045 0.125C5.49593 0.125 0.625 4.99593 0.625 11.0045C0.625 17.0131 5.49593 21.8841 11.5045 21.8841Z"
        fill="#CFD04C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.54403 10.8866C8.71459 9.50972 10.8253 8.59479 11.8851 8.15091C14.9017 6.89175 15.5358 6.67434 15.9435 6.66528C16.034 6.66528 16.2333 6.6834 16.3692 6.79211C16.4779 6.88269 16.5051 7.00046 16.5232 7.09104C16.5413 7.18163 16.5594 7.37186 16.5413 7.5168C16.3783 9.23796 15.6717 13.414 15.3093 15.3345C15.1553 16.1498 14.8564 16.4215 14.5665 16.4487C13.9324 16.5031 13.4523 16.032 12.8454 15.6334C11.8851 15.0084 11.3507 14.6188 10.4176 14.0028C9.33964 13.2963 10.0372 12.9067 10.6532 12.2726C10.8162 12.1096 13.5972 9.57313 13.6516 9.34667C13.6607 9.31949 13.6607 9.21078 13.5972 9.15643C13.5338 9.10208 13.4432 9.1202 13.3708 9.13832C13.2711 9.15643 11.7493 10.171 8.78706 12.173C8.35224 12.4719 7.96271 12.6169 7.60942 12.6078C7.2199 12.5987 6.47708 12.3904 5.91544 12.2092C5.23604 11.9918 4.69251 11.874 4.73781 11.4936C4.76498 11.2943 5.03675 11.095 5.54403 10.8866Z"
        fill="#323232"
      />
    </g>
    <defs>
      <clipPath id="clip0_4073_240">
        <rect
          width="21.75"
          height="21.75"
          fill="white"
          transform="translate(0.625 0.125)"
        />
      </clipPath>
    </defs>
  </svg>
);
