import classes from "./PromotionContent.module.css";
import classNames from "classnames";
import { FeedbackForm } from "../../../../modules/FeedbackModal/components/FeedbackForm";
import contactsImg from "../../assets/contacts.jpeg";
import img2 from "../../assets/2.jpeg";
import img3 from "../../assets/3.jpeg";
import img4 from "../../assets/4.jpeg";
import img5 from "../../assets/5.jpeg";
import img6 from "../../assets/6.jpeg";
import img7 from "../../assets/7.jpeg";
import img8 from "../../assets/8.jpeg";
// import aboutImg from "../../assets/about.jpeg";
import { PHONE } from "../../../../common/constants/contacts";

export const PromotionContent = () => {
  return (
    <>
      <section
        className={classNames("container section-m", classes.SimpleTitles)}
      >
        <h1 className="h2">ГРУЗОВИК FAW J7 4Х2 - ВАШ</h1>
        <h1 className={classNames("h2", classes.PromoText)}>
          ТЕХНИЧЕСКОЕ ОБСЛУЖИВАНИЕ - С НАС
        </h1>
      </section>
      <FeedbackForm title="Не пропустите! Количество машин ограничено" />
      <section className="container section-m p1">
        <div className={classes.Text}>
          <p>Уважаемые клиенты,</p>
          <p>
            Мы рады представить вам специальное предложение на грузовые
            автомобили FAW J7 4x2: «Техническое обслуживание на 2 года в
            подарок».
          </p>
          <p>
            С подробными условиями акции вы можете ознакомиться на данной
            странице или заказать обратный звонок и наши специалисты свяжутся с
            вами.
          </p>
          <p>
            1. Акция действует при условии приобретения у ООО «Лидер Трак энд
            Бас» транспортного средства FAW J7 4x2 в период с 27 ноября 2023
            года до 29 декабря 2023 года и подразумевает проведение всех
            регламентных работ по техническому обслуживанию, включая расходные
            материалы для проведения работ, в течение 24 месяцев с момента
            приобретения транспортного средства или до 400 000 км. пробега в
            зависимости от того, что наступит раньше.
          </p>
          <p>
            2. Срок действия акции с 27 ноября 2023 года до 29 декабря 2023
            года. Акция может быть прекращена ранее, без предварительного
            уведомления. Количество участников ограничено.
          </p>
          <p>3. Предложение не является офертой.</p>
          <p>
            4. Акция не распространяется на транспортные средства FAW J7 4x2,
            приобретённых на специальных условиях в рамках крупных сделок.
          </p>
          <p>
            5. Условия акции могут быть изменены ООО «Лидер Трак энд Бас» и ООО
            «ФАВ - Восточная Европа» без предварительного уведомления.
          </p>
          <p>
            6. Для получения подробной информации обращайтесь в отдел продаж ООО
            «Лидер Трак энд Бас» по телефону 8 800 250 22 55 или электронной
            почте sales@leader-trucks.ru.
          </p>
          <p>
            7. Организатор акции: ООО «ФАВ - Восточная Европа», ИНН 7726571783
            Юридический адрес:117485, г. Москва, ул. Обручева, д. 30/1 стр.2
          </p>
          <p>
            8. Партнер акции: ООО «Лидер Трак энд Бас», ИНН 9714006340
            Юридический адрес: 125124, г. Москва, вн. тер. г. Муниципальный
            округ Беговой, ул. Правды, д. 8, к. 13, пом. 1/3
          </p>
        </div>
      </section>
      <section className={classNames("container section", classes.Contacts)}>
        <div className={classes.ContactsContent}>
          <h2 className="h2">Контакты</h2>
          <p className="p1">Заинтересовало предложение? Звоните:</p>
          <a
            className={classNames("h2", classes.PromoText)}
            href={PHONE}
            target="_blank"
            rel="noreferrer"
          >
            {PHONE}
          </a>
          <a className="h3">sales@leader-trucks.ru</a>
          <a className="h3">www.leader-trucks.ru</a>
        </div>
        <picture>
          <img src={contactsImg} />
        </picture>
      </section>
      <section className="container section">
        <h2 className={classNames("h2 center", classes.Title)}>FAW J7</h2>
        <div className={classes.Images}>
          {[contactsImg, img2, img3, img4, img5, img6, img7, img8].map(
            (img, idx) => (
              <picture key={idx}>
                <img src={img} className="img-cover" />
              </picture>
            ),
          )}
        </div>
      </section>
      {/* hide section */}
      {/* <section className="container section">
        <div className={classNames("container section", classes.About)}>
          <h2 className="h2 center">Компания «Лидер Трак энд Бас»</h2>
          <div className={classes.AboutContent}>
            <div>
              <p className="p2">
                Деятельность компании сфокусирована на продаже новой и
                подержанной грузовой техники и автобусов, сервисной поддержке и
                гарантии, запасных частях и комплексных транспортных решениях
                для эффективного владения автопарком таких как: обучение
                водителей, телематический сервис, сервисные контракты, а также
                помощь на дорогах и многое другое.
              </p>
              <p className="p2">
                Компания динамично развивается и представлена центрами продаж в
                Москве и Санкт-Петербурге, в ближайшее время планируется
                расширение коммерческой сети ООО «Лидер Трак энд Бас» за счет
                привлечения новых партнеров в других городах России.
              </p>
            </div>
            <picture>
              <img src={aboutImg} />
            </picture>
          </div>
        </div>
      </section> */}
    </>
  );
};
