import classes from "./NewsCard.module.css";
import { INew } from "../../interfaces/INew";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import { ru } from "date-fns/locale";

interface NewsCardProps {
  item: INew;
}

export const NewsCard = ({ item }: NewsCardProps) => {
  return (
    <Link
      to={`/news/${item.attributes.slug}`}
      className={classNames(classes.Card)}
    >
      <picture className={classes.Picture}>
        <img
          src={item.attributes.previewImage.data?.attributes.url}
          className="img-cover"
          alt={item.attributes.previewImage.data?.attributes.alternativeText}
        />
      </picture>
      <div className={classes.Content}>
        <h4 className="p1 uppercase">{item.attributes.title}</h4>
        <p className={classNames("p3", classes.Date)}>
          {format(new Date(item.attributes.date), "dd MMMM", {
            locale: ru,
          })}
        </p>
      </div>
    </Link>
  );
};
