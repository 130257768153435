export const PROGRAM_LIST = [
  "Экономичная эксплуатация грузовой техники",
  "Эксплуатация грузовой техники в условиях бездорожья",
  "Основы устройства автобусной техники",
  "Основы устройства грузовой техники",
  "Экономичная эксплуатация автобусной техники",
];

export const BENEFITS_LIST = [
  "Благодаря индивидуальному обучению водители могут добиваться более экономичной манеры вождения",
  "Правильное использование круиз-контроля, интардера, газа и тормоза экономит топливо, уменьшает износ и расходы на ТО",
  "Обученные водители могут проходить до 15 % своего маршрута в режиме принудительного холостого хода без расхода топлива",
  "Водитель, обеспечивающий экономию, в сравнении с неэкономичным водителем снижает расходы",
];
