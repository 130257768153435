export const PdfIcon = () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.66853 12.6353H7.77962C8.24634 12.6353 8.74903 12.865 8.7282 13.4425C8.7099 13.9616 8.39623 14.274 7.77962 14.274H6.66853V12.6353ZM5.5 11.6691V17.2504H6.66853V15.2242H7.96359C9.01378 15.2242 9.75346 14.4814 9.75346 13.4757C9.75346 12.4255 8.95824 11.6694 7.96359 11.6694L5.5 11.6691Z"
      fill="black"
    />
    <path
      d="M11.7434 12.6353H12.6075C13.7403 12.6353 14.161 13.3715 14.1644 14.4003C14.1657 15.4489 13.6974 16.3003 12.71 16.3003H11.7434V12.6353ZM10.6074 11.6691V17.2504H12.9448C14.5456 17.2504 15.3822 15.8424 15.3573 14.4006C15.3295 12.8307 14.2528 11.6691 12.769 11.6691H10.6074Z"
      fill="black"
    />
    <path
      d="M16.3223 11.6691V17.2501H17.4911V14.897H19.9449V13.9124H17.4911V12.6435H20.2983V11.6691H16.3223Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.87381 2L5.50391 7.03227V10.8553H6.57524V7.77036H11.4368V4.17422H10.3632V6.69714H7.21457L10.3632 3.07291H19.2223V10.8553H20.2956V2H9.87381Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.50391 18.0705V21.9854L20.2956 21.9999V16.1772H19.2223V20.9254L6.57524 20.9137V18.0705H5.50391Z"
      fill="black"
    />
  </svg>
);
