import { useEffect, useState } from "react";

import { promiseErrorHandle } from "../libs/promiseErrorHandle";

import { MenuService } from "../api/MenuService";

export type TMenuResponse = {
  data: {
    attributes: {
      createdAt: string;
      slug: string;
      title: string;
      items: {
        data: TMenuItemResponse[];
      };
    };
  }[];
};

export type TMenuItemResponse = {
  attributes: {
    title: string;
    url?: string;
    children: {
      data: TMenuItemResponse[];
    };
  };
};

export const useMenu = () => {
  const [menu, setMenu] = useState<TMenuItemResponse[]>();

  useEffect(() => {
    const menu = new MenuService();
    menu
      .get()
      .then((res: { data: TMenuResponse }) => {
        setMenu(res.data.data[0].attributes.items.data);
      })
      .catch((e) => {
        promiseErrorHandle(e);
      });
  }, []);

  return { menu };
};
