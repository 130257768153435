import { SpecialOffersSections } from "../../modules/SpecialOffers";
import { BottomLinks } from "../../modules/BottomLinks";
import { Line } from "../../ui/Line";
import { Layout } from "../../modules/Layout";
import { NewHero } from "../../components/NewHero";
import imgSm from "./assets/hero.jpg";

export const SpecialOffersPage = () => {
  return (
    <Layout>
      <NewHero
        title={
          <>
            Специальные <br />
            предложения
          </>
        }
        imgSm={imgSm}
      />
      <Line />
      <SpecialOffersSections />
      <BottomLinks />
    </Layout>
  );
};
