import classes from "./Search.module.css";
import { BoldCrossIcon, SearchIcon, ArrowRightIcon } from "../../ui/_icons";
import classNames from "classnames";
import { useState } from "react";

interface SearchProps {
  setValue: (v: string) => void;
  closeSearch?: () => void;
  header?: boolean;
}

export const Search = ({ setValue, closeSearch, header }: SearchProps) => {
  const [inputValue, setInputValue] = useState("");

  return (
    <div
      className={classNames(classes.Label, header ? classes.LabelHeader : "")}
    >
      <span className={classNames(classes.Icon, classes.SearchIcon)}>
        <SearchIcon />
      </span>
      {closeSearch && (
        <button
          className={classNames(classes.Icon, classes.BackIcon)}
          onClick={closeSearch}
        >
          <ArrowRightIcon />
        </button>
      )}
      <input
        type="text"
        placeholder="Поиск"
        className={classes.Input}
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            setValue(inputValue);
          }
        }}
      />
      {inputValue.length > 0 && (
        <span
          className={classNames(classes.Icon, classes.ClearIcon)}
          onClick={() => {
            setInputValue("");
            setValue("");
          }}
        >
          <BoldCrossIcon />
        </span>
      )}
    </div>
  );
};
