import { apiInstance } from "../../../core/apiInstance";

export class DealershipService {
  private BASE_URL = "/dealerships";

  async get(isSale: boolean, isService: boolean, brandId?: string) {
    const filters =
      isSale && isService
        ? ""
        : `${isSale ? `filters[isSales][$eq]=${isSale}` : ""}&${
            isService ? `filters[isService][$eq]=${isService}` : ""
          }`;

    return apiInstance.get(
      `${this.BASE_URL}?&populate=brands&${filters}${
        brandId ? `&filters[brands][slug]=${brandId}` : ""
      }`,
    );
  }
}
