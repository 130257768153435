import { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

import { ArrowDownIcon } from "../../../../ui/_icons";

import { useOnClickOutside } from "../../../../common/hooks/useClickOutside";

import { CommonContext } from "../../../../contexts/CommonContext";

import { MobileItems } from "../MobileItems";
import { MobileSubList } from "../MobileSubList";
import { MobileContacts } from "../MobileContacts";

import { TMenuItem } from "../../types";

import classes from "./MobileMenu.module.css";

type MobileMenuProps = {
  onButtonClick: () => void;
};

export const MobileMenu = ({ onButtonClick }: MobileMenuProps) => {
  const [selectedMenuItem, setSelectedMenuItem] = useState<{
    title: string;
    subList?: TMenuItem;
  }>();

  const [commonValue] = useContext(CommonContext);

  const ref = useRef<HTMLDivElement | null>(null);
  const { isShowing, setIsShowing } = useOnClickOutside(ref);

  const handleItemClick = (item: TMenuItem) => {
    setSelectedMenuItem((prev) => {
      if (item.title === prev?.title) return undefined;

      return {
        title: item.title,
        subList: undefined,
      };
    });
  };

  const handleSubItemClick = (item: TMenuItem) => {
    setSelectedMenuItem((prev) => {
      if (!prev) return;
      return {
        ...prev,
        subList: item,
      };
    });
  };

  const handleBackButtonClick = () => {
    setSelectedMenuItem((prev) => {
      if (!prev) return;
      return {
        ...prev,
        subList: undefined,
      };
    });
  };

  useEffect(() => {
    if (!isShowing) setSelectedMenuItem(undefined);
  }, [isShowing]);

  return (
    <nav className={classes.Nav} onClick={() => setIsShowing(true)}>
      <ul
        className={classNames(
          classes.List,
          "p2",
          selectedMenuItem?.subList && classes.List,
        )}
        data-subList={!!selectedMenuItem?.subList}
      >
        {commonValue.menu.map((item) => (
          <li key={item.title}>
            {item.url ? (
              <Link to={item.url} className={classes.Item}>
                {item.title}
              </Link>
            ) : (
              <p
                className={classes.SubList}
                data-selected={selectedMenuItem?.title === item.title}
              >
                <button
                  className={classes.Item}
                  data-selected={selectedMenuItem?.title === item.title}
                  onClick={() => handleItemClick(item)}
                >
                  {item.title}
                  <ArrowDownIcon />
                </button>
                {item.items?.length && (
                  <MobileItems
                    items={item.items}
                    onButtonClick={handleSubItemClick}
                  />
                )}
              </p>
            )}
          </li>
        ))}
      </ul>

      <button
        className={classNames("btn", classes.ModalButton)}
        onClick={onButtonClick}
        data-subList={!!selectedMenuItem?.subList}
      >
        Связаться с нами
      </button>

      <div
        className={classes.Contacts}
        data-subList={!!selectedMenuItem?.subList}
      >
        <MobileContacts />
      </div>

      {selectedMenuItem?.subList?.items?.length && (
        <MobileSubList
          items={selectedMenuItem?.subList}
          onButtonClick={handleBackButtonClick}
        />
      )}
    </nav>
  );
};
