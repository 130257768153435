import classes from "./About.module.css";
import classNames from "classnames";
import { MarkdownRenderer } from "../../../../components/MarkdownRenderer/MarkdownRenderer";

interface AboutProps {
  title?: string;
  text: string;
  primaryImage?: string;
  secondaryImage?: string;
}

export const About = ({
  secondaryImage,
  primaryImage,
  text,
  title,
}: AboutProps) => {
  return (
    <section className={classNames("container section", classes.Section)}>
      <h2 className="h2">{title}</h2>
      <div />
      <MarkdownRenderer>{text}</MarkdownRenderer>
      <picture className={classes.SmallImg}>
        <img src={primaryImage} />
      </picture>
      <picture className={classes.Img}>
        <img src={secondaryImage} />
      </picture>
    </section>
  );
};
