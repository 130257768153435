import classes from "./LegalSidebar.module.css";
import { ChevronRightIcon } from "../../../../ui/_icons";
import { useNavigate } from "react-router-dom";
import { LegalList } from "../LegalList";
import { ILigalList } from "../LegalList/LegalList";

export const LegalSidebar = (props: ILigalList) => {
  const navigate = useNavigate();

  return (
    <aside className={classes.Sidebar}>
      <button className="text-btn p2" onClick={() => navigate(-1)}>
        <ChevronRightIcon />
        Назад
      </button>
      <LegalList titles={props.titles} />
    </aside>
  );
};
