export const WhatsAppIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M24 11.6909C24 18.1477 18.7256 23.3819 12.2181 23.3819C10.1523 23.3819 8.21147 22.8539 6.52293 21.9272L0 24L2.12667 17.7275C1.05387 15.9659 0.436 13.8997 0.436 11.6909C0.436 5.23413 5.71093 0 12.2181 0C18.7261 0 24 5.23413 24 11.6909ZM12.2181 1.86187C6.75573 1.86187 2.31253 6.2712 2.31253 11.6909C2.31253 13.8416 3.0136 15.8333 4.19947 17.4536L2.96187 21.104L6.76853 19.8941C8.33253 20.9211 10.2061 21.52 12.2184 21.52C17.68 21.52 22.124 17.1112 22.124 11.6915C22.124 6.27173 17.6803 1.86187 12.2181 1.86187ZM18.1677 14.3835C18.0949 14.2643 17.9027 14.1923 17.6141 14.0491C17.3251 13.9059 15.9048 13.2123 15.6408 13.1171C15.376 13.0216 15.1829 12.9736 14.9907 13.2603C14.7984 13.5472 14.2448 14.1923 14.076 14.3835C13.9075 14.5752 13.7392 14.5992 13.4501 14.4557C13.1616 14.3125 12.2312 14.0096 11.128 13.0336C10.2696 12.2741 9.68987 11.3365 9.52133 11.0493C9.35307 10.7627 9.50373 10.6077 9.648 10.4651C9.77813 10.3365 9.93707 10.1304 10.0813 9.9632C10.2261 9.79573 10.2741 9.67653 10.3699 9.48507C10.4667 9.29387 10.4184 9.12667 10.3459 8.98293C10.2739 8.83973 9.69573 7.4296 9.4552 6.85573C9.21467 6.2824 8.9744 6.37787 8.8056 6.37787C8.63733 6.37787 8.44453 6.35387 8.252 6.35387C8.05947 6.35387 7.7464 6.4256 7.4816 6.71227C7.21707 6.9992 6.4712 7.69253 6.4712 9.1024C6.4712 10.5125 7.5056 11.8749 7.6504 12.0659C7.79467 12.2568 9.64773 15.2445 12.5837 16.392C15.52 17.5389 15.52 17.1563 16.0496 17.1083C16.5787 17.0605 17.7579 16.4152 17.9995 15.7464C18.2395 15.0765 18.2395 14.5029 18.1677 14.3835Z"
      fill="#FAFAFA"
    />
  </svg>
);
