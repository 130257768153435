import classNames from "classnames";

import { useProducts } from "../../../Products/hooks/useProducts";

import classes from "./BrandProducts.module.css";
import { BigProductCard } from "../../../Products/components/BigProductCard";
import { NAVIGATION } from "../../../../common/constants/navigation";

interface BrandProductsProps {
  title?: string;
  brandSlug: string;
}

export const BrandProducts = ({ title, brandSlug }: BrandProductsProps) => {
  const products = useProducts(brandSlug);

  return (
    <section className={classNames(classes.Section, "section container")}>
      {title && <h1 className={classNames("h1", classes.Title)}>{title}</h1>}

      <ul className={classes.Cards}>
        {products.map((item) => (
          <li key={item.attributes.title}>
            <BigProductCard
              link={`${NAVIGATION.products.link}/${item.attributes.slug}`}
              image={item.attributes.coverImage?.data?.attributes.url}
              title={item.attributes.categoryName}
            />
          </li>
        ))}
      </ul>
    </section>
  );
};
