import classes from "./Pagination.module.css";
import { ChevronRightIcon } from "../../../../ui/_icons";
import classNames from "classnames";
import { IPagination } from "../../../../common/interfaces/IPagination";

interface PaginationProps {
  page: number;
  setPage: (v: number) => void;
  pagination: IPagination;
}

export const Pagination = ({ page, pagination, setPage }: PaginationProps) => {
  return (
    <div className={classes.Pagination}>
      <button
        className={classNames("p2 uppercase", classes.Btn)}
        disabled={page === 1}
        onClick={() => setPage(page - 1)}
      >
        <ChevronRightIcon />
        Назад
      </button>
      <button
        className={classNames("p2 uppercase", classes.Btn)}
        disabled={page === pagination.pageCount}
        onClick={() => setPage(page + 1)}
      >
        Далее
        <ChevronRightIcon />
      </button>
    </div>
  );
};
