import { useState } from "react";
import classNames from "classnames";

import { AutoCardWithProperties } from "../AutoCardWithProperties";

import classes from "./AutoSection.module.css";
import { useModels } from "../../hooks/useModels";

import { IModel } from "../../interfaces/IModel";

interface IAutoSection {
  title?: string;
  brandSlug?: string | null | undefined;
  modificationId?: number;
  models?: IModel[];
}

export const AutoSection = ({
  title,
  brandSlug,
  modificationId,
  models: initialModels,
}: IAutoSection) => {
  const [showedModelsNum, setShowedModelsNum] = useState(3);

  const modelsResponse = useModels(brandSlug, modificationId);

  const handleMoreClick = () => {
    setShowedModelsNum((prev) => prev + 3);
  };

  const models = initialModels || modelsResponse;

  return models?.length ? (
    <section className="container section">
      {title && <h2 className={classNames("h2", classes.Title)}>{title}</h2>}
      <ul className={classes.List}>
        {models.slice(0, showedModelsNum).map((item, index) => (
          <li key={index}>
            <AutoCardWithProperties {...item.attributes} />
          </li>
        ))}
      </ul>
      {showedModelsNum <= models.length && (
        <div className={classes.ButtonWrapper}>
          <button
            className={classNames(classes.Button, "text-btn")}
            onClick={handleMoreClick}
          >
            Показать ещё
          </button>
        </div>
      )}
    </section>
  ) : (
    <></>
  );
};
