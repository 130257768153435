import classes from "./AutoCard.module.css";
import { Link } from "react-router-dom";
import { IModel } from "../../interfaces/IModel";
import classNames from "classnames";

interface AutoCardProps {
  auto: IModel;
  small?: boolean;
}

export const AutoCard = ({ auto, small }: AutoCardProps) => {
  return (
    <div className={classes.Card}>
      <picture
        className={classNames(classes.Image, small ? classes.Small : "")}
      >
        <img
          src={auto.attributes.previewImage.data?.attributes.url}
          className="img-cover"
        />
      </picture>
      <div className={classes.Content}>
        <h4 className="h4">{auto.attributes.title}</h4>
        <Link to={`/models/${auto.attributes.slug}`} className="btn block">
          Подробнее
        </Link>
      </div>
    </div>
  );
};
