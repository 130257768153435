import { useEffect, useRef } from "react";
import { Map, Placemark, YMaps } from "@pbe/react-yandex-maps";

import classNames from "classnames";

import { env } from "../../../../core/env";

import { IDealership } from "../../interfaces/IDealership";

import selectedPin from "../../assets/selected-pin.png";
import pin from "../../assets/pin.png";

import classes from "./YMap.module.css";

interface YMapProps {
  points: IDealership[];
  openMobileMap: boolean;
  selected: IDealership | null;
  setSelected: (v: IDealership | null) => void;
}

export const YMap = ({
  points,
  openMobileMap,
  selected,
  setSelected,
}: YMapProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const map = useRef<any>(null);

  useEffect(() => {
    if (map.current) {
      if (selected) {
        map.current.setCenter([
          selected.attributes.latitude,
          selected.attributes.longitude,
        ]);
      }
      map.current.setZoom(selected ? 16 : 5, { duration: 300 });
    }
  }, [selected, openMobileMap]);

  return (
    <div
      className={classNames(
        classes.Map,
        openMobileMap ? classes.OpenedMobileMap : "",
      )}
    >
      <YMaps query={{ lang: "ru_RU", apikey: env.YMAPS_KEY }}>
        <Map
          width="100%"
          height="100%"
          state={{ center: [54.73574467210817, 55.94280367607394], zoom: 4 }}
          options={{ suppressMapOpenBlock: true }}
          instanceRef={map}
        >
          {points.map((point) => (
            <Placemark
              onClick={() => setSelected(point)}
              key={point.id}
              geometry={[point.attributes.latitude, point.attributes.longitude]}
              options={{
                iconLayout: "default#image",
                iconImageSize: [48, 48],
                iconImageOffset: [-24, -48],
                iconImageHref: selected?.id === point.id ? selectedPin : pin,
              }}
            />
          ))}
        </Map>
      </YMaps>
    </div>
  );
};
