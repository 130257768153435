import { useState, useEffect } from "react";
import { ModelsService } from "../api/ModelsService";
import { IModel } from "../interfaces/IModel";
import { IPagination } from "../../../common/interfaces/IPagination";
import { ISelectOption } from "../../../common/interfaces/ISelectOption";

export const useModels = (
  brandSlug?: string | undefined | null,
  modificationId?: number,
) => {
  const [models, setModels] = useState<IModel[]>([]);

  useEffect(() => {
    if (!modificationId && !brandSlug) {
      setModels([]);
      return;
    }

    const models = new ModelsService();
    models.getByBrandSlug(brandSlug ?? "", modificationId).then((res) => {
      setModels(res.data.data);
    });
  }, [brandSlug, modificationId]);

  return models;
};

export const useModel = (slug: string | undefined) => {
  const [model, setModel] = useState<IModel | undefined | null>();

  useEffect(() => {
    if (slug) {
      const model = new ModelsService();
      model
        .getBySlug(slug)
        .then((res) => {
          setModel(res.data.data[0]);
        })
        .catch(() => {
          setModel(null);
        });
    }
  }, [slug]);
  return model;
};

export const useModelsWithFilters = (
  page: number,
  pageSize: number,
  brands: ISelectOption[],
  filter2: ISelectOption[],
  filter3: ISelectOption[],
) => {
  const [models, setModels] = useState<IModel[]>([]);
  const [pagination, setPagination] = useState<IPagination | undefined>();

  useEffect(() => {
    const models = new ModelsService();
    models
      .getWithFilters(
        page,
        pageSize,
        brands.map((b) => b.value),
        filter2.map((g) => g.value),
        filter3.map((p) => p.value),
      )
      .then((res) => {
        setModels(res.data.data);
        setPagination(res.data.meta.pagination);
      });
  }, [page, pageSize, brands, filter2, filter3]);

  return { models, pagination };
};
