import { GuaranteeList } from "../GuaranteeList";
import classes from "./GuaranteeSection.module.css";
import classNames from "classnames";
import imgXl from "../../assets/guarantee.png";
import imgLg from "../../assets/guarantee-1024.png";
import imgMd from "../../assets/guarantee-768.png";
import imgSm from "../../assets/guarantee-320.png";

export const GuaranteeSection = () => {
  return (
    <section className={classNames(classes.Section, "container section")}>
      <picture>
        <source media="(min-width: 1440px)" srcSet={imgXl} />
        <source media="(min-width: 1024px)" srcSet={imgLg} />
        <source media="(min-width: 768px)" srcSet={imgMd} />
        <img src={imgSm} />
      </picture>
      <div className={classes.LeftSide}>
        <div className={classes.GuaranteeList}>
          <h2 className="h2">условия гарантии</h2>
          <GuaranteeList />
        </div>
        <p className={classNames(classes.Explanation, "p2")}>
          * - есть ограничения по сроку/пробегу для отдельных деталей в составе
          узлов и агрегатов
        </p>
      </div>
      <p
        className={classNames(
          classes.Explanation,
          classes.ExplanationSmall,
          "p2",
        )}
      >
        * - есть ограничения по сроку/пробегу для отдельных деталей в составе
        узлов и агрегатов
      </p>
    </section>
  );
};
