import { NewHero } from "../../components/NewHero";
import { Layout } from "../../modules/Layout";
import imgXl from "./assets/hero.png";
import imgLg from "./assets/hero-1024.png";
import imgMd from "./assets/hero-768.png";
import imgSm from "./assets/hero-320.png";
import { BottomLinks } from "../../modules/BottomLinks";
import { JobSection } from "./components/JobSection";
import { useJob } from "../../modules/JobSection/hooks/useJob";

export const JobPage = () => {
  const jobResponse = useJob();
  const { jobContent } = jobResponse;
  if (!jobContent) return;

  return (
    <Layout>
      <NewHero
        title={jobContent?.attributes.hero.heroTitle || "Работа в компании"}
        imgSm={
          jobContent?.attributes.hero.heroBackgroundImage.data.attributes.url ||
          imgSm
        }
        imgMd={
          jobContent?.attributes.hero.heroBackgroundImage.data.attributes.url ||
          imgMd
        }
        imgLg={
          jobContent?.attributes.hero.heroBackgroundImage.data.attributes.url ||
          imgLg
        }
        imgXl={
          jobContent?.attributes.hero.heroBackgroundImage.data.attributes.url ||
          imgXl
        }
      />
      <JobSection data={jobContent?.attributes.content} />
      <BottomLinks />
    </Layout>
  );
};
