export const ArrowRightIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="57"
    height="56"
    viewBox="0 0 57 56"
    fill="none"
  >
    <path
      d="M42.9016 28H14.1016"
      stroke="#FAFAFA"
      strokeWidth="2"
      strokeLinecap="square"
    />
    <path
      d="M30.9016 41.1998L44.1016 27.9998L30.9016 14.7998"
      stroke="#FAFAFA"
      strokeWidth="2"
      strokeLinecap="square"
    />
  </svg>
);
