export const ContactIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.375 8.2408C3.375 8.13145 3.42491 8.02807 3.51055 7.96006L11.7747 1.39736C11.9053 1.29369 12.09 1.29369 12.2206 1.39736L20.4847 7.96007C20.5704 8.02807 20.6203 8.13144 20.6203 8.2408V22.6415C20.6203 22.8395 20.4598 23 20.2618 23H3.73349C3.5355 23 3.375 22.8395 3.375 22.6415V8.2408Z"
      strokeWidth="2"
    />
  </svg>
);
