import { apiInstance } from "../../../core/apiInstance";

export class NewsService {
  private BASE_URL = "/news-tables";

  async getNews(page: number, pageSize: number) {
    return apiInstance.get(
      `${this.BASE_URL}?sort[0]=date:desc&pagination[pageSize]=${pageSize}&pagination[page]=${page}&populate=*`,
    );
  }

  async getById(id: string) {
    return apiInstance.get(`${this.BASE_URL}/${id}?populate=*`);
  }

  async getBySlug(slug: string) {
    return apiInstance.get(`${this.BASE_URL}?populate=*&filters[slug]=${slug}`);
  }

  async getOther(id: string) {
    return apiInstance.get(
      `${this.BASE_URL}?sort[0]=date:desc&populate=*&filters[id][$ne]=${id}&pagination[pageSize]=4`,
    );
  }
}
