import classNames from "classnames";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";

import classes from "./SimpleAutoSection.module.css";

import { AutoCard } from "../AutoCard";
import { CarouselNavigation } from "../../../../components/CarouselNavigation";

import { useModels } from "../../hooks/useModels";

interface SimpleAutoSectionProps {
  brandSlug?: string | null | undefined;
  modificationId?: number;
}

export const SimpleAutoSection = ({
  brandSlug,
  modificationId,
}: SimpleAutoSectionProps) => {
  const models = useModels(brandSlug, modificationId);

  return models.length ? (
    <section className="container section auto-swiper">
      <h2 className={classNames("h2", classes.Title)}>Техника в наличии</h2>
      <Swiper
        spaceBetween={20}
        slidesPerView={1}
        modules={[Navigation]}
        navigation={{
          prevEl: `.auto-swiper .prev`,
          nextEl: `.auto-swiper .next`,
        }}
        breakpoints={{
          768: {
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 3,
          },
        }}
      >
        {models.map((auto) => (
          <SwiperSlide key={auto.id}>
            <AutoCard auto={auto} />
          </SwiperSlide>
        ))}
      </Swiper>
      <CarouselNavigation />
    </section>
  ) : null;
};
