import { NewHero } from "../../components/NewHero";
import { BottomLinks } from "../../modules/BottomLinks";
import { Layout } from "../../modules/Layout";
import { RepairParts } from "./components/RepairParts";

import imgSm from "./assets/hero.jpg";

import { NAVIGATION } from "../../common/constants/navigation";

export const BOTTOM_LINKS = [
  NAVIGATION.contacts,
  NAVIGATION.specialOffers,
  NAVIGATION.models,
];

export const RepairPartsPage = () => {
  return (
    <Layout>
      <NewHero title={"запасные части"} imgSm={imgSm} />
      <RepairParts />
      <BottomLinks list={BOTTOM_LINKS} />
    </Layout>
  );
};
