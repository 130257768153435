import { NAVIGATION } from "../../../common/constants/navigation";

export const BOTTOM_LINKS = [
  NAVIGATION.models,
  NAVIGATION.contacts,
  {
    title: "География дилеров",
    link: NAVIGATION.dealerMap.link,
  },
];
