import classNames from "classnames";
import classes from "./Service.module.css";
import { ServiceType } from "../ServiceType";
import img from "../../assets/service.jpg";

export const Service = () => {
  return (
    <section className={classNames(classes.Section, "container section")}>
      <h2 className="h2">виды работ</h2>
      <div className={classes.TypesList}>
        <picture className={classes.Image}>
          <img src={img} className="img-cover" />
        </picture>
        <ServiceType
          title="Официальные сервисные станции в Москве и Санкт-Петербурге ООО «Лидер Трак энд Бас» предлагают полный перечень услуг по диагностике, техническому обслуживанию и ремонту автомобилей и автобусов."
          text="Производственные площади позволяют проводить диагностику, ремонт и обслуживание до 30 единиц техники в день. Коллектив прошедший обучение и продолжающий совершенствование своих навыков в собственном учебном центре в России на базе сервисной станции, неоднократно занимал призовые места в международных соревнованиях среди сотрудников сервисных станций обладает наивысшей компетенцией в сфере диагностики, ремонта и обслуживания коммерческой техники."
        />
        <ServiceType
          title="Все официальные сервисные станции ООО «Лидер Трак энд Бас» обладают большим количеством специнструмента и оборудования , что позволяет выполнять любые виды диагностики, технического обслуживания, и ремонта, в том числе и кузовного ремонта, автомобилей и автобусов."
          text="Сервисная станция филиала имеет оборудованные автомобили технической помощи для осуществления диагностики, ремонта и технического обслуживания автомобилей и автобусов на территории клиента"
        />
        <ServiceType
          list
          title="Также наши станции предлагаю обширный спектор услуг по кузовному ремонту:"
          text=""
        />
      </div>
    </section>
  );
};
