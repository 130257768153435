import classes from "./AutoWithFilters.module.css";
import { useState } from "react";
import { AutoCard } from "../AutoCard";
import { Pagination } from "../Pagination";
import { Filters } from "../Filters";
import classNames from "classnames";
import { useModelsWithFilters } from "../../hooks/useModels";
import { useFilters } from "../../hooks/useFilters";
import { Line } from "../../../../ui/Line";

export const AutoWithFilters = () => {
  const [page, setPage] = useState(1);

  const {
    selectedFilter1,
    selectedFilter2,
    selectedFilter3,
    setSelectedFilter1,
    setSelectedFilter2,
    setSelectedFilter3,
  } = useFilters();

  const { models, pagination } = useModelsWithFilters(
    page,
    9,
    selectedFilter1,
    selectedFilter2,
    selectedFilter3,
  );

  return (
    <>
      <h1 className={classNames("container h2", classes.Title)}>
        Техника в наличии
      </h1>
      <Line />
      <div className={classNames("container section", classes.Wrapper)}>
        <Filters
          selectedFilter1={selectedFilter1}
          selectedFilter2={selectedFilter2}
          selectedFilter3={selectedFilter3}
          setSelectedFilter1={setSelectedFilter1}
          setSelectedFilter2={setSelectedFilter2}
          setSelectedFilter3={setSelectedFilter3}
        />
        <div>
          <div className={classes.Cards}>
            {models.map((auto) => (
              <AutoCard key={auto.id} auto={auto} small />
            ))}
          </div>
          {pagination && pagination.pageCount > 1 && (
            <Pagination pagination={pagination} page={page} setPage={setPage} />
          )}
        </div>
      </div>
    </>
  );
};
