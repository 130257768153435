import imgSm from "./assets/test-drive-hero-320.png";
import imgMd from "./assets/test-drive-hero-768.png";
import imgLg from "./assets/test-drive-hero-1024.png";
import imgXl from "./assets/test-drive-hero-1440.png";
import { NewHero } from "../../components/NewHero";
import { Layout } from "../../modules/Layout";
import { BottomLinks } from "../../modules/BottomLinks";
import { TestTruck } from "./components/TestTruck";

export const TestDrivePage = () => {
  return (
    <Layout>
      <NewHero
        imgSm={imgSm}
        imgMd={imgMd}
        imgLg={imgLg}
        imgXl={imgXl}
        title={`Тест-Драйв`}
      />
      <TestTruck />
      <BottomLinks />
    </Layout>
  );
};
