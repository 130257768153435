import classNames from "classnames";

import classes from "./TernaryProductMainSection.module.css";

type TernaryProductMainSectionProps = {
  title: string;
  description: string;
  image?: string;
};

export const TernaryProductMainSection = ({
  title,
  description,
  image,
}: TernaryProductMainSectionProps) => {
  return (
    <div className={classNames("container", classes.Section)}>
      <h2 className={"h1 uppercase"}>{title}</h2>
      <p className={classNames("p1", classes.Text)}>{description}</p>
      <div className={classes.Image}>
        <picture>
          <img src={image} className="img-cover" />
        </picture>
      </div>
    </div>
  );
};
