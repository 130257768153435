import { useEffect, useState } from "react";

import { promiseErrorHandle } from "../../../common/libs/promiseErrorHandle";

import { LegalService } from "../api/LegalService";

import { TLegalContent } from "../types";

export const useLegal = () => {
  const [legalContent, setLegalContent] = useState<TLegalContent>();

  useEffect(() => {
    const response = new LegalService();
    response
      .get()
      .then((res) => {
        setLegalContent(res.data.data);
      })
      .catch((e) => {
        promiseErrorHandle(e);
      });
  }, []);

  return { legalContent };
};
