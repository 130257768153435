import { DoubleImageBlock } from "../../../../components/DoubleImageBlock";
import { useModel } from "../../hooks/useModels";
import classes from "./ModelGarantee.module.css";
import classNames from "classnames";
import { renderMd } from "../../../../common/libs/renderMd";

interface ModelGaranteeProps {
  slug?: string;
}

export const ModelGarantee = ({ slug }: ModelGaranteeProps) => {
  const model = useModel(slug);

  return model && model.attributes.warrantyText ? (
    <div className={classes.Wrap}>
      <DoubleImageBlock
        firstImg={model.attributes.warrantyImage1.data?.attributes.url}
        secondImg={model.attributes.warrantyImage2.data?.attributes.url}
      >
        <div className={classes.Main}>
          <h2 className="h2">{model.attributes.warrantyTitle}</h2>

          <div
            className={classNames(classes.Text, "p2")}
            dangerouslySetInnerHTML={{
              __html: renderMd(model.attributes.warrantyText),
            }}
          />
        </div>
      </DoubleImageBlock>
    </div>
  ) : null;
};
