import classNames from "classnames";
import { Link } from "react-router-dom";

import classes from "./Hexagons.module.css";
import { IBgImage } from "../../../modules/Main/types";

export type THexagon = {
  title: string;
  backgroundImage: { data: IBgImage };
  link: string;
};

type HexagonsProps = {
  hexagons: THexagon[];
};

export const Hexagons = ({ hexagons }: HexagonsProps) => {
  return (
    <ul className={classes.Hexagons}>
      {hexagons.map((item, index) => (
        <li key={item.title} className={classNames(classes.Hexagon)}>
          <Link to={item.link} className={classes.HexagonLink}>
            <img
              src={item.backgroundImage?.data.attributes?.url}
              className={"img-cover"}
              alt={item.title}
            />
            <span className={classNames("h3", classes.Title)}>
              {item.title}
            </span>
          </Link>
          {index === 0 && (
            <>
              <span className={classes.BackFirstHexagon}> </span>
              <span className={classes.BackSecondHexagon}> </span>
            </>
          )}
        </li>
      ))}
    </ul>
  );
};
