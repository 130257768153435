export const DiagonalArrowIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
  >
    <path
      d="M38.3813 18.2619L18.0166 38.6266"
      strokeWidth="2"
      strokeLinecap="square"
    />
    <path
      d="M38.9129 35.7795V17.1119H20.2453"
      strokeWidth="2"
      strokeLinecap="square"
    />
  </svg>
);
