export const UpArrowIcon = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 7.28475L15 22.7133"
      stroke="white"
      strokeWidth="1.75"
      strokeLinecap="square"
    />
    <path
      d="M22.0711 13.7136L14.9997 6.64215L7.92822 13.7136"
      stroke="white"
      strokeWidth="1.75"
      strokeLinecap="square"
    />
  </svg>
);
