import classes from "./Checkbox.module.css";
import { JSX } from "react";
import classNames from "classnames";

interface CheckboxProps {
  children: JSX.Element;
  text: JSX.Element | string;
  p2?: boolean;
  error?: string;
}

export const Checkbox = ({ children, text, error, p2 }: CheckboxProps) => {
  return (
    <label className={classNames(classes.Label, error ? classes.Error : "")}>
      {children}
      <span className={classes.Checkbox}></span>
      <p className={p2 ? "p2" : "p4"}>{text}</p>
    </label>
  );
};
