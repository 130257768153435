import classes from "./NewsSection.module.css";
import { useNews } from "../../hooks/useNews";
import { NewsCard } from "../NewsCard";
import classNames from "classnames";
import { Pagination } from "../Pagination";
import { useState } from "react";
import { useBreakpoint } from "../../../../common/hooks/useBreakpoint";

export const NewsSection = () => {
  const [page, setPage] = useState(1);
  const breakpoint = useBreakpoint();
  const { news, pagination } = useNews(
    page,
    breakpoint === undefined
      ? undefined
      : breakpoint === "tablet" || breakpoint === "mobile"
        ? 3
        : 4,
  );

  return (
    <section className={classNames("container section", classes.Section)}>
      <div className={classes.Wrapper}>
        <h1 className="h2">Новости</h1>
        <div className={classes.News}>
          {news.map((item) => (
            <NewsCard key={item.id} item={item} />
          ))}
        </div>
        {pagination && (
          <Pagination page={page} setPage={setPage} pagination={pagination} />
        )}
      </div>
    </section>
  );
};
