import { Layout } from "../../modules/Layout";
import { Line } from "../../ui/Line";
import { BottomLinks } from "../../modules/BottomLinks";
import { NewHero } from "../../components/NewHero";
import { Brands } from "../../modules/Brands";

import imgSm from "./assets/hero.jpg";

export const ProductsPage = () => {
  return (
    <Layout>
      <NewHero title="Продукция" imgSm={imgSm} />
      <Line />
      <Brands />
      <BottomLinks />
    </Layout>
  );
};
