import { DecorationTop } from "../ui/Decorations";

import { MainHero } from "./components/MainHero";
import { NewAboutSection } from "./components/NewAboutSection";

import { Layout } from "../modules/Layout";
import { NewsPreview } from "../modules/News";

import { useMain } from "../modules/Main/hooks/useMain";

export const HomePage = () => {
  const mainResponse = useMain();
  const { mainContent } = mainResponse;
  if (!mainContent) return;

  const {
    attributes: { hero, content, hexagons, news },
  } = mainContent;

  return (
    <Layout>
      <DecorationTop />
      <MainHero
        title={hero.heroTitle || ""}
        image={hero.heroBackgroundImage.data[0]?.attributes?.url || ""}
        hexagons={hexagons}
      />
      <NewAboutSection
        title={content.title}
        text={content.primaryText}
        buttonLink={content.buttonLink}
        buttonText={content.buttonText}
        primaryImage={content.primaryImage?.data?.attributes.url || ""}
        secondaryImage={content.secondaryImage?.data?.attributes.url || ""}
      />
      <NewsPreview title={news.newsTitle} buttonText={news.newsButtonText} />
    </Layout>
  );
};
