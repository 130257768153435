import { MutableRefObject } from "react";
import classNames from "classnames";

import { TOffice } from "../../types";

import classes from "./Points.module.css";

interface PointsProps {
  refList?: MutableRefObject<HTMLUListElement | null>;
  offices?: TOffice[];
  selectedOffice?: TOffice;
  onClick?: (value: TOffice) => void;
}

export const Points = ({
  offices,
  selectedOffice,
  refList,
  onClick,
}: PointsProps) => {
  const handlePointClick = (point: TOffice) => {
    onClick?.(point);
  };

  return (
    <ul className={classes.List} ref={refList}>
      {offices?.map((item) => (
        <li
          className={classNames(
            classes.Item,
            selectedOffice?.title === item.title ? classes.Selected : "",
          )}
          data-id={item.title}
          key={item.title}
          onClick={() => handlePointClick(item)}
        >
          <h6 className="p2">{item.title?.replace(/\\n/g, "\n")}</h6>
          <p className="p4">{item.address?.replace(/\\n/g, "\n")}</p>
        </li>
      ))}
    </ul>
  );
};
