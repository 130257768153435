import classes from "./BenefitsBlock.module.css";
import { List } from "../../../../ui/List";
import { BENEFITS_LIST } from "../../constants/textContent";
import imgSecond from "./../../assets/training-4-1440.png";
import imgMd from "./../../assets/training-3-768.png";
import { DoubleImageBlock } from "../../../../components/DoubleImageBlock";

export const BenefitsBlock = () => {
  return (
    <DoubleImageBlock firstImg={imgMd} secondImg={imgSecond}>
      <div className={classes.TextContent}>
        <h2 className="h2">ВАШИ ПРЕИМУЩЕСТВА</h2>
        <List list={BENEFITS_LIST} />
      </div>
    </DoubleImageBlock>
  );
};
