import { Link } from "react-router-dom";
import { ArrowRightIcon } from "../../../../ui/_icons";
import { useOtherNews } from "../../hooks/useNews";
import classes from "./PressReleaseLinks.module.css";
import { format } from "date-fns";
import { ru } from "date-fns/locale";

export const PressReleaseLinks = ({ id }: { id: string }) => {
  const news = useOtherNews(id);

  return (
    <div className={classes.Section}>
      <h4 className="h4">Другие новости</h4>
      <ul className={classes.List}>
        {news.map((item) => (
          <li key={item.attributes.title}>
            <Link
              to={`/news/${item.attributes.slug}`}
              className={classes.LinkWrap}
            >
              <div className={classes.TitleBox}>
                <p className="p1 uppercase">{item.attributes.title}</p>
                <ArrowRightIcon />
              </div>
              <p className="p3">
                {format(new Date(item.attributes.date), "dd MMMM", {
                  locale: ru,
                })}
              </p>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};
