import classes from "./ServiceList.module.css";
import { Link } from "react-router-dom";
import { ArrowRightIcon } from "../../../../ui/_icons";
import classNames from "classnames";
import { IService } from "../../types";
import { MarkdownRenderer } from "../../../../components/MarkdownRenderer/MarkdownRenderer";

interface IServiceListProps {
  data: IService[];
}

export const ServiceList = ({ data }: IServiceListProps) => {
  return (
    <ul className={classes.Services}>
      {data.map((service) => (
        <li key={service.title} className={classes.Service}>
          <h3 className="h3">{service.title}</h3>
          <picture>
            <img src={service.image?.data.attributes.url} alt={service.title} />
          </picture>
          <div className={classes.Text}>
            <MarkdownRenderer>{service.text}</MarkdownRenderer>
            <Link
              to={service.link || ""}
              className={classNames("p2", classes.Link)}
            >
              {service.buttonText} <ArrowRightIcon />
            </Link>
          </div>
        </li>
      ))}
    </ul>
  );
};
