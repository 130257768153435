import { apiInstance } from "../../../core/apiInstance";

export class SpecialOffersService {
  private BASE_URL = "/special-offers";

  get(offerType: string, pageSize: number) {
    return apiInstance.get(
      `${this.BASE_URL}?populate=*&filters[offer_types][name]=${offerType}&pagination[limit]=${pageSize}`,
    );
  }

  getById(id: string) {
    return apiInstance.get(`${this.BASE_URL}?populate=*&filters[id]=${id}`);
  }

  getBySlug(slug: string) {
    return apiInstance.get(`${this.BASE_URL}?populate=*&filters[slug]=${slug}`);
  }

  getOther(id: string) {
    return apiInstance.get(
      `${this.BASE_URL}?populate=*&filters[id][$ne]=${id}`,
    );
  }
}
