import { JSX } from "react";

import { DecorationBottom } from "../../ui/Decorations";
import { Line } from "../../ui/Line";

import { Header } from "../Header";
import { ToTopButton } from "../ToTopButton";
import { Footer } from "../Footer";

import classes from "./Layout.module.css";

interface LayoutProps {
  children: JSX.Element | JSX.Element[];
}

export const Layout = ({ children }: LayoutProps) => {
  return (
    <div className={classes.Layout}>
      <Header />
      <main>{children}</main>
      <ToTopButton />
      <Line />
      <Footer />
      <DecorationBottom />
    </div>
  );
};
