export const RubleIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6.35294 22V1H13.0588C15.7647 1 17.7353 1.54902 18.9706 2.64706C20.2255 3.7451 20.8529 5.2549 20.8529 7.17647C20.8529 8.31373 20.598 9.38235 20.0882 10.3824C19.5784 11.3824 18.7255 12.1863 17.5294 12.7941C16.3529 13.402 14.7451 13.7059 12.7059 13.7059H10.7941V22H6.35294ZM4 18.8529V16H15.3235V18.8529H4ZM4 13.7059V10.0588H12.6176V13.7059H4ZM12.2647 10.0588C13.1078 10.0588 13.8333 9.97059 14.4412 9.79412C15.049 9.59804 15.5196 9.29412 15.8529 8.88235C16.1863 8.47059 16.3529 7.95098 16.3529 7.32353C16.3529 6.42157 16.0686 5.7549 15.5 5.32353C14.9314 4.87255 14.0392 4.64706 12.8235 4.64706H10.7941V10.0588H12.2647Z" />
  </svg>
);
