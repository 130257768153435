import { Layout } from "../../modules/Layout";
import { Line } from "../../ui/Line";
import { NewHero } from "../../components/NewHero";
import { Dealership } from "../../modules/Dealership";
import { useDealerGeography } from "../../modules/DealerGeography/hooks/useDealerGeography";

export const DealersMapPage = () => {
  const { geographyContent } = useDealerGeography();
  if (!geographyContent) return;
  const {
    attributes: { hero },
  } = geographyContent;

  return (
    <Layout>
      <NewHero
        title={hero.heroTitle.replace(/\\n/g, "\n")}
        imgSm={hero.heroBackgroundImage.data.attributes.url}
      />
      <Line />
      <Dealership title="Дилеры компании" isWithLine={false} />
    </Layout>
  );
};
