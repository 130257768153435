import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";

import classNames from "classnames";

import { IModel } from "../../interfaces/IModel";

import { getNumberWithSpaces } from "../../../../common/libs/getNumberWithSpaces";
import { renderMd } from "../../../../common/libs/renderMd";
import { useModal } from "../../../../common/modal";

import { FeedbackModal } from "../../../FeedbackModal";

import { RubleIcon } from "../../../../ui/_icons/RubleIcon";
import { NextIcon } from "../../../../ui/_icons/NextIcon";
import { PrevIcon } from "../../../../ui/_icons/PrevIcon";

import classes from "./Preview.module.css";

type PreviewProps = Pick<
  IModel["attributes"],
  "title" | "tileImage" | "amount" | "previewTag" | "detailText"
>;

export const Preview = ({
  title,
  tileImage,
  amount,
  previewTag,
  detailText,
}: PreviewProps) => {
  const [openModal] = useModal(FeedbackModal);

  const handleOpenModal = () => {
    openModal({});
  };

  const gallery = Array.isArray(tileImage.data)
    ? tileImage.data
    : [tileImage.data];

  return (
    <section className={classNames("section container", classes.Section)}>
      <h1 className={classNames("h1", classes.Title)}>{title}</h1>
      <div className={classes.Card}>
        <div className={classes.SwiperWrapper}>
          <Swiper
            className={classNames("slider", classes.Swiper)}
            pagination={{ clickable: true }}
            spaceBetween={20}
            slidesPerView={1}
            style={
              {
                "--swiper-pagination-bottom": "-25px",
              } as React.CSSProperties
            }
            modules={[Pagination, Navigation]}
            navigation={{
              prevEl: `.swiper-button-prev`,
              nextEl: `.swiper-button-next`,
            }}
          >
            {gallery.map((item) => (
              <SwiperSlide key={item?.id}>
                <picture className={classes.Image}>
                  <img
                    src={item?.attributes.url}
                    alt={item?.attributes.alternativeText}
                    className="img-cover"
                  />
                </picture>
              </SwiperSlide>
            ))}
            <div className="swiper-button-prev">
              <PrevIcon />
            </div>
            <div className="swiper-button-next">
              <NextIcon />
            </div>
          </Swiper>
        </div>
        <div className={classes.RightContent}>
          <div className={classes.Top}>
            <div className={classes.TopContent}>
              {amount && (
                <p className={classNames("h1", classes.Price)}>
                  {`${getNumberWithSpaces(amount)}`}&nbsp;
                  <RubleIcon />
                </p>
              )}
            </div>
            {previewTag && <p className={classes.Sale}>{previewTag}</p>}
          </div>

          {detailText && (
            <div>
              <p
                className={classNames("p2", classes.Properties)}
                dangerouslySetInnerHTML={{
                  __html: renderMd(detailText),
                }}
              />
            </div>
          )}

          <button
            className={classNames("btn block", classes.ModalButton)}
            onClick={handleOpenModal}
          >
            Оставить заявку
          </button>
        </div>
      </div>
    </section>
  );
};
