export const PrevIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.2295 16H7.77234"
      stroke="white"
      strokeWidth="1.4"
      strokeLinecap="square"
    />
    <path
      d="M14.2485 23.5427L6.70569 15.9999L14.2485 8.45703"
      stroke="white"
      strokeWidth="1.4"
      strokeLinecap="square"
    />
  </svg>
);
