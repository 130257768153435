import { useEffect, useState } from "react";

import { promiseErrorHandle } from "../../../common/libs/promiseErrorHandle";

import { MainService } from "../api/MainService";

import { TMainContent } from "../types";

export const useMain = () => {
  const [mainContent, setMainContent] = useState<TMainContent>();

  useEffect(() => {
    const mainContentResponse = new MainService();
    mainContentResponse
      .get()
      .then((res) => {
        setMainContent(res.data.data);
      })
      .catch((e) => {
        promiseErrorHandle(e);
      });
  }, []);

  return { mainContent };
};
