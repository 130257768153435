import classes from "./SpecialOfferContent.module.css";
import {
  useOtherSpecialOffers,
  useSpecialOffer,
} from "../../hooks/useSpecialOffer";
import classNames from "classnames";
import { OtherSpecialOffers } from "../OtherSpecialOffers";
import { TopContent } from "../../../../components/TopContent";
import { renderMd } from "../../../../common/libs/renderMd";
import { Navigate } from "react-router-dom";

interface SpecialOfferContentProps {
  id: string | undefined;
}

export const SpecialOfferContent = ({ id }: SpecialOfferContentProps) => {
  const offer = useSpecialOffer(id);
  const offers = useOtherSpecialOffers(id);

  if (offer === null) {
    return <Navigate to="/404" />;
  }

  return offer ? (
    <article className={classNames("container section", classes.Wrapper)}>
      <TopContent
        title={offer.attributes.title}
        publishedAt={offer.attributes.publishedAt}
      />
      <section className={classes.Section}>
        <div className={classes.Text}>
          <p className="p2">{offer.attributes.introText}</p>
          <picture>
            <img src={offer.attributes.coverImage.data.attributes.url} />
          </picture>
          {offer.attributes.description && (
            <div
              className={classNames("p2", classes.Content)}
              dangerouslySetInnerHTML={{
                __html: renderMd(offer.attributes.description),
              }}
            ></div>
          )}
        </div>
      </section>
      {!!offers.length && <OtherSpecialOffers offers={offers} />}
    </article>
  ) : null;
};
