/* 
* Automatically generated by cra-envs.
* If you wish to declare a new environment variable declare it in the .env file (prefixed by REACT_APP_)
* then run 'npx generate-env-getter' at the root of your project.
* This file will be updated.
*/
import { getEnvVarValue } from "cra-envs";

export const envNames = [
    "API_URL",
    "YMAPS_KEY"
] as const;

export type EnvNames = typeof envNames[number];

let env: Record<EnvNames, string> | undefined = undefined;

export function getEnv() {

    if (env === undefined) {
        env = {} as Record<EnvNames, string>;
        for (const envName of envNames) {
            env[envName] = getEnvVarValue(envName);
        }
    }

    return env;

}
