import {
  createContext,
  JSXElementConstructor,
  ReactNode,
  useContext,
  useState,
  useCallback,
} from "react";

import "./modalRoot.css";

import { useLockBodyScroll } from "../../hooks/UseLockBodyScroll";

import { GlobalModalSpawner } from "./globalModalSpawner";
import { throwError } from "../helper/throwError";

export const MODAL_ROOT_ID = "modal-root";

const modalSpawnerContext = createContext<ModalSpawnerContext | null>(null);

type OpenedModal = {
  activeModal: JSX.Element;
  modalScope: ModalScope;
};

type ClosedModal = {
  activeModal: null;
  modalScope: null;
};

export type ModalSpawnerContext = {
  useModal<E>(
    modal: JSXElementConstructor<WithClosableProps & E>,
    scope?: ModalScope,
  ): ModalControl<E>;
} & (ClosedModal | OpenedModal);

export type ModalScope = "global" | "portal";

export const useModalSpawner = () =>
  useContext(modalSpawnerContext) ??
  throwError("useModalSpawner can be used only inside ModalSpawnerProvider");

/**
 * Хук открытия модального окна.
 * @param modal модальное окно, которое необходимо открыть
 * @param scope позволяет управлять местом спавна элемента. Необходим в случае спавна модалки, использующей React.Context
 */
export function useModal<E>(
  modal: JSXElementConstructor<WithClosableProps & E>,
  scope: ModalScope = "global",
) {
  return (
    useContext(modalSpawnerContext)?.useModal(modal, scope) ??
    throwError("useModal can be used only inside ModalSpawnerProvider")
  );
}

export interface WithClosableProps {
  onClose(): void;
}

export type OpenModalType<E> = (props: E) => void;
export type CloseModalType = () => void;

export type ModalControl<E> = [OpenModalType<E>, CloseModalType];

export function ModalSpawnerProvider({ children }: { children: ReactNode }) {
  const [activeModal, setActiveModal] = useState<JSX.Element | null>(null);
  const [modalScope, setModalScope] = useState<ModalScope | null>(null);

  useLockBodyScroll(!!activeModal);

  function doClose() {
    setActiveModal(null);
    setModalScope(null);
  }

  const useModal = useCallback(
    <E,>(
      modal: JSXElementConstructor<WithClosableProps & E>,
      scope: ModalScope,
    ): ModalControl<E> => {
      const Element = modal;

      const open = (props: E) => {
        setModalScope(scope);
        setActiveModal(<Element {...props} onClose={doClose} />);
      };

      return [open, doClose];
    },
    [],
  );

  return (
    <modalSpawnerContext.Provider
      value={{ useModal, activeModal, modalScope } as ModalSpawnerContext}
    >
      {children}
      <div id={MODAL_ROOT_ID}>
        <GlobalModalSpawner />
      </div>
    </modalSpawnerContext.Provider>
  );
}
