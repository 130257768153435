export type TNavigation = {
  title: string;
  link: string;
};

export const NAVIGATION: {
  [key: string]: TNavigation;
} = {
  products: {
    title: "Продукция",
    link: "/products",
  },
  about: {
    title: "О нас",
    link: "/about",
  },
  services: {
    title: "Услуги",
    link: "/services",
  },
  news: {
    title: "Новости",
    link: "/news",
  },
  contacts: {
    title: "Контакты",
    link: "/contacts",
  },
  trucks: {
    title: "Грузовики",
    link: "/brands/faw-trucks",
  },
  buses: {
    title: "Автобусы",
    link: "/brands/asiastar",
  },
  tyagachiFaw: {
    title: "Тягачи",
    link: "/products/tyagachi-faw",
  },
  tippersFaw: {
    title: "Самосвалы",
    link: "/products/tippers-faw",
  },
  turisticheskieAvtobusy: {
    title: "Туристические автобусы",
    link: "/products/turisticheskie-avtobusy",
  },
  fawTelematics: {
    title: "Телематика",
    link: "/services/telematica",
  },
  driverTraining: {
    title: "Обучение водителей",
    link: "/driver-training",
  },
  tradeIn: {
    title: "Trade-in",
    link: "/trade-in",
  },
  testDrive: {
    title: "Тест-драйв",
    link: "/test-drive",
  },
  legal: {
    title: "Правовая информация",
    link: "/legal",
  },
  jobs: {
    title: "Работа в компании",
    link: "/jobs",
  },
  models: {
    title: "Техника в наличии",
    link: "/models",
  },
  dealerMap: {
    title: "География дилеров",
    link: "/dealers-map",
  },
  promotion: {
    title: "Акции",
    link: "/faw-j7",
  },
  specialOffers: {
    title: "Акции",
    link: "/special-offers",
  },
  guarantee: {
    title: "Гарантия",
    link: "/special-offers/guarantee",
  },
  service: {
    title: "Сервис",
    link: "/special-offers/service",
  },
  repairParts: {
    title: "Запасные части",
    link: "/special-offers/repair-parts",
  },
  serviceContracts: {
    title: "Сервисные контракты",
    link: "/special-offers/service-contracts",
  },
};
