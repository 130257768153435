export const ArrowDownIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 8L12.5 16L20 8"
      stroke="#FAFAFA"
      strokeWidth="2"
      strokeLinecap="square"
    />
  </svg>
);
