import { MouseEvent, ReactNode, useRef } from "react";

import classes from "./modalBase.module.css";

export type ModalBaseProps = {
  /**
   * вложенные элементы
   */
  children?: ReactNode;
  /**
   * слой с модалкой
   */
  layoutClassName?: string;
  /**
   * обертка контейнера с модалкой
   */
  wrapperClassName?: string;
  /**
   * контейнер модалки
   */
  containerClassName?: string;
  /**
   * закрывать ли модалку по клику вне её
   */
  closeOnBackClick?: boolean;
  /**
   * функция закрытия модалки
   */
  onClose(): void;
};

export function ModalBase(props: ModalBaseProps) {
  const layoutRef = useRef<HTMLDivElement>(null);

  const {
    children,
    containerClassName,
    wrapperClassName,
    layoutClassName,
    onClose,
    closeOnBackClick,
  } = props;

  function handleMouseDown(e: MouseEvent<HTMLDivElement>) {
    // проверяем, что тыкнули именно в layout
    if (e.target === layoutRef.current && closeOnBackClick) onClose();
  }

  console.log(containerClassName);

  return (
    // используется onMouseDown чтобы не сработало закрытие модалки, когда нажал внутри контейнера, но вывел поинтер за ее пределы
    <div
      className={`${classes["modal-base-layout"]} ${layoutClassName || ""}`}
      onMouseDown={handleMouseDown}
      ref={layoutRef}
    >
      <div className={`${classes["modal-base-wrapper"]} ${wrapperClassName}`}>
        <section
          className={`${classes["modal-base-container"]} ${containerClassName}`}
          role={"dialog"}
        >
          {children}
        </section>
      </div>
    </div>
  );
}
