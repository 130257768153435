import classes from "./Pagination.module.css";
import { IPagination } from "../../../../common/interfaces/IPagination";
import classNames from "classnames";
import { ChevronRightIcon } from "../../../../ui/_icons";

interface PaginationProps {
  pagination: IPagination;
  page: number;
  setPage: (v: number) => void;
}

export const Pagination = ({ pagination, page, setPage }: PaginationProps) => {
  return (
    <div className={classes.Pagination}>
      {page !== 1 && (
        <button
          className={classNames(classes.Btn, classes.Arrow)}
          onClick={() => setPage(page - 1)}
        >
          <ChevronRightIcon />
        </button>
      )}
      {[...Array(pagination.pageCount).keys()].map((item) => (
        <button
          key={item}
          className={classNames(
            classes.Btn,
            item + 1 === page ? classes.Active : "",
          )}
          onClick={() => setPage(item + 1)}
        >
          {item + 1}
        </button>
      ))}
      {page !== pagination.pageCount && (
        <button
          className={classNames(classes.Btn, classes.Arrow)}
          onClick={() => setPage(page + 1)}
        >
          <ChevronRightIcon />
        </button>
      )}
    </div>
  );
};
