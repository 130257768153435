import { useNavigate } from "react-router-dom";

import { NAVIGATION } from "../../../../common/constants/navigation";

import { Line } from "../../../../ui/Line";

import { Layout } from "../../../Layout";

import { AutoSection } from "../../../Models";
import { BottomLinks } from "../../../BottomLinks";

import { TernaryProductMainSection } from "../TernaryProductMainSection";

import { useProduct } from "../../hooks/useProducts";

export const BOTTOM_LINKS = [
  NAVIGATION.contacts,
  {
    title: "Автомобили в наличии",
    link: NAVIGATION.models.link,
  },
];

type FawPageContentProps = {
  slug: string;
};

export const FawPageContent = ({ slug }: FawPageContentProps) => {
  const product = useProduct(slug);
  const navigation = useNavigate();

  if (product === null) {
    navigation("/404");
  }

  const productTitle = product?.attributes.title || "";

  return product ? (
    <Layout>
      <TernaryProductMainSection
        title={productTitle || ""}
        description={product.attributes.description}
        image={product.attributes.smallImage?.data?.attributes.url}
      />

      <Line isWithMargin />
      <AutoSection title={""} models={product.attributes.models.data} />

      <BottomLinks list={BOTTOM_LINKS} />
    </Layout>
  ) : null;
};
