import { NewHero } from "../../components/NewHero";
import { BottomLinks } from "../../modules/BottomLinks";
import { Layout } from "../../modules/Layout";

import { MainInfo } from "./components/MainInfo";
import { Benefits } from "./components/Benefits";
import { LearnMore } from "./components/LearnMore";

import { BOTTOM_LINKS } from "./constants/bottomLinks";

import imgSm from "./assets/hero-320.jpg";
import imgMd from "./assets/hero-768.jpg";
import imgLg from "./assets/hero-1024.jpg";
import imgXl from "./assets/hero.jpg";

export const ServiceContractsPage = () => {
  return (
    <Layout>
      <NewHero
        title={
          <>
            Сервисные
            <br /> контракты
          </>
        }
        imgSm={imgSm}
        imgMd={imgMd}
        imgLg={imgLg}
        imgXl={imgXl}
      />
      <MainInfo />
      <Benefits />
      <LearnMore />
      <BottomLinks list={BOTTOM_LINKS} />
    </Layout>
  );
};
