export const PhoneIcon = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.8881 15.956V18.956C20.8892 19.2345 20.8322 19.5102 20.7206 19.7653C20.6091 20.0205 20.4454 20.2496 20.2402 20.4379C20.035 20.6261 19.7927 20.7695 19.5289 20.8587C19.265 20.9479 18.9855 20.9811 18.7081 20.956C15.631 20.6216 12.6751 19.5701 10.0781 17.886C7.66194 16.3507 5.61345 14.3022 4.07812 11.886C2.38809 9.27719 1.33636 6.30698 1.00812 3.21599C0.983127 2.93945 1.01599 2.66075 1.10462 2.39761C1.19324 2.13447 1.33569 1.89267 1.52288 1.68761C1.71008 1.48254 1.93792 1.31869 2.19191 1.20651C2.44589 1.09432 2.72046 1.03625 2.99812 1.03599H5.99812C6.48342 1.03121 6.95391 1.20306 7.32188 1.51952C7.68985 1.83597 7.93019 2.27543 7.99812 2.75599C8.12474 3.71605 8.35957 4.65871 8.69812 5.56599C8.83266 5.92391 8.86178 6.3129 8.78202 6.68687C8.70227 7.06083 8.51698 7.4041 8.24812 7.67599L6.97812 8.94599C8.40167 11.4495 10.4746 13.5224 12.9781 14.946L14.2481 13.676C14.52 13.4071 14.8633 13.2218 15.2372 13.1421C15.6112 13.0623 16.0002 13.0914 16.3581 13.226C17.2654 13.5645 18.2081 13.7994 19.1681 13.926C19.6539 13.9945 20.0975 14.2392 20.4146 14.6135C20.7318 14.9878 20.9003 15.4656 20.8881 15.956Z"
      stroke="#CFD04C"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
