import { useEffect, useRef } from "react";
import { Map, Placemark, YMaps } from "@pbe/react-yandex-maps";

import classNames from "classnames";

import { env } from "../../../../core/env";

import selectedPin from "../../assets/selected-pin.png";
import pin from "../../assets/pin.png";

import classes from "./YMap.module.css";
import { TOffice } from "../../types";
import { useBreakpoint } from "../../../../common/hooks/useBreakpoint";
import React from "react";

interface YMapProps {
  selectedOffice?: TOffice;
  offices: TOffice[];
  onClick: (value: TOffice) => void;
}

const _YMap = ({ offices, selectedOffice, onClick }: YMapProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const map = useRef<any>(null);
  const breakpoint = useBreakpoint();

  useEffect(() => {
    if (map.current) {
      if (selectedOffice) {
        map.current.setCenter([
          selectedOffice.latitude,
          selectedOffice.longitude,
        ]);
      }
      map.current.setZoom(selectedOffice ? 16 : 5, { duration: 300 });
    }
  }, [selectedOffice]);

  return (
    <div className={classNames(classes.Map)}>
      <YMaps query={{ lang: "ru_RU", apikey: env.YMAPS_KEY }}>
        <Map
          key={breakpoint}
          width="100%"
          height="100%"
          state={{ center: [54.73574467210817, 55.94280367607394], zoom: 4 }}
          options={{ suppressMapOpenBlock: true }}
          instanceRef={map}
        >
          {offices.map((item) => (
            <Placemark
              onClick={() => onClick(item)}
              key={item.title}
              geometry={[item.latitude, item.longitude]}
              options={{
                iconLayout: "default#image",
                iconImageSize: [48, 48],
                iconImageOffset: [-24, -48],
                iconImageHref:
                  selectedOffice?.title === item.title ? selectedPin : pin,
              }}
            />
          ))}
        </Map>
      </YMaps>
    </div>
  );
};

export const YMap = React.memo(_YMap);
