import classNames from "classnames";

import {
  PHONE,
  SERVICE_CONTRACTS_EMAIL,
} from "../../../../common/constants/contacts";
import { TITLE, SUB_TITLE } from "../../constants/learnMore";

import classes from "./LearnMore.module.css";

export const LearnMore = () => {
  return (
    <section className={classNames(classes.Section, "section container")}>
      <h3 className="h3">{TITLE}</h3>
      <p className="p1">{SUB_TITLE}</p>

      <p className={classNames(classes.Links)}>
        <a className={classNames(classes.Link, "h3")} href={`tel:${PHONE}`}>
          {PHONE}
        </a>
        <a
          className={classNames(classes.Link, "h3")}
          href={`mailto:${SERVICE_CONTRACTS_EMAIL}`}
        >
          {SERVICE_CONTRACTS_EMAIL}
        </a>
      </p>
    </section>
  );
};
