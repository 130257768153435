import classNames from "classnames";

import { ModalWindow, ModalWindowProps } from "../../common/modal";

import classes from "./Modal.module.css";

export type ModalProps = Omit<ModalWindowProps, "closeButton">;

export function Modal({
  children,
  closeOnBackClick,
  title,
  containerClassName,
  layoutClassName,
  ...rest
}: ModalProps) {
  return (
    <ModalWindow
      {...rest}
      title={title}
      layoutClassName={classNames(classes.Layout, layoutClassName)}
      containerClassName={classNames(classes.Container, containerClassName)}
      closeOnBackClick={closeOnBackClick ?? true}
    >
      {children}
    </ModalWindow>
  );
}
