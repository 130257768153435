import { ReactNode } from "react";

import classNames from "classnames";

import { ModalBase, ModalBaseProps } from "../modalBase/modalBase";

import classes from "./modalWindow.module.css";
import { BoldCrossIcon } from "../../../ui/_icons";

export interface ModalWindowProps extends ModalBaseProps {
  /**
   * заголовок
   */
  title?: ReactNode;
  /**
   * футер модалки
   */
  footer?: ReactNode;
  /**
   * класс иконки закрытия
   */
  closeClassName?: string;
}

export function ModalWindow(props: ModalWindowProps) {
  const {
    children,
    title,
    containerClassName,
    layoutClassName,
    closeClassName,
    onClose,
    ...rest
  } = props;

  const handleCloseModal = () => {
    onClose();
  };

  return (
    <ModalBase
      {...rest}
      onClose={handleCloseModal}
      layoutClassName={classNames(classes["Container"], layoutClassName)}
      containerClassName={classNames(classes["Wrapper"], containerClassName)}
    >
      <header className={classes["Header"]}>
        {title}
        <button
          className={classNames(classes["Close"], closeClassName)}
          onClick={handleCloseModal}
        >
          <BoldCrossIcon />
        </button>
      </header>
      <article className={classes["Content"]}>{children}</article>
      {props.footer && <footer>{props.footer}</footer>}
    </ModalBase>
  );
}
