import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm"; // Плагин для зачеркнутого текста
import rehypeRaw from "rehype-raw"; // Плагин для подчеркнутого текста
import classes from "./MarkdownRenderer.module.css";
import classNames from "classnames";

export type MarkdownRendererProps = {
  children: string;
  className?: string;
};

const _MarkdownRenderer: React.FC<MarkdownRendererProps> = (props) => {
  const { children, className } = props;

  return (
    <div className={classNames(classes.Container, className)}>
      <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}>
        {children}
      </ReactMarkdown>
    </div>
  );
};
export const MarkdownRenderer = React.memo(_MarkdownRenderer);
