import classes from "./LegalPageContent.module.css";
import { LegalSidebar } from "../LegalSidebar";
import classNames from "classnames";
import { LegalList } from "../LegalList";
import { TLegalContent } from "../../../../modules/LegalPage/types";
import Markdown from "react-markdown";

export const LegalPageContent = (props: TLegalContent) => {
  const legalData = props.attributes;
  const titles = legalData.sections?.map((it) => it.title);
  return (
    <div className={classNames("container section", classes.Wrapper)}>
      <LegalSidebar titles={titles} />
      <div className={classes.Content}>
        <h2 className="h2">{legalData.hero.heroTitle}</h2>
        <LegalList titles={titles} />
        {!!legalData.sections &&
          legalData.sections.map((section, index) => (
            <>
              <h3 className="h3" id={`${index}`}>
                <span>{`${index + 1}. `}</span>
                {section.title}
              </h3>
              <Markdown>{section.text}</Markdown>
            </>
          ))}
      </div>
    </div>
  );
};
