import classes from "./PageContent.module.css";
import { ProgramsBlock } from "../ProgramsBlock";
import { BenefitsBlock } from "../BenefitsBlock";
import { DescriptionBlock } from "../DescriptionBlock";

export const PageContent = () => {
  return (
    <div className={`${classes.Wrapper}`}>
      <DescriptionBlock />
      <ProgramsBlock />
      <BenefitsBlock />
    </div>
  );
};
