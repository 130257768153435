import { ErrorBlock } from "../components/ErrorBlock";
import { Layout } from "../modules/Layout";

export const ErrorPage = () => {
  return (
    <Layout>
      <ErrorBlock text="Сбой работы сервера" code={502} />
    </Layout>
  );
};
