import classNames from "classnames";

import { bottomLinks } from "../constants/bottom-links";
import { LinkCard } from "./LinkCard";

import { TNavigation } from "../../../common/constants/navigation";

import classes from "./BottomLinks.module.css";

interface BottomLinkProps {
  list?: TNavigation[];
}

export const BottomLinks = ({ list }: BottomLinkProps) => {
  return (
    <div className={classNames("container section", classes.Wrapper)}>
      {(list ? list : bottomLinks).map((item) => (
        <LinkCard
          key={item.title}
          title={item.title}
          component="link"
          to={item.link}
        />
      ))}
    </div>
  );
};
