import classes from "./Filters.module.css";
import { CheckboxesGroup } from "../CheckboxesGroup";
import { useBrands } from "../../../Brands";
import { ISelectOption } from "../../../../common/interfaces/ISelectOption";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { useBreakpoint } from "../../../../common/hooks/useBreakpoint";
import { bodyModalOpenHandle } from "../../../../common/libs/bodyModalOpenHandle";
import { CrossIcon, FilterIcon } from "../../../../ui/_icons";
import { useModifications } from "../../hooks/useModifications";
import { useAutoTypes } from "../../hooks/useAutoTypes";

interface FiltersProps {
  selectedFilter1: ISelectOption[];
  selectedFilter2: ISelectOption[];
  selectedFilter3: ISelectOption[];
  setSelectedFilter1: (v: ISelectOption[]) => void;
  setSelectedFilter2: (v: ISelectOption[]) => void;
  setSelectedFilter3: (v: ISelectOption[]) => void;
}

export const Filters = (filters: FiltersProps) => {
  const [open, setOpen] = useState(false);
  const breakpoint = useBreakpoint();

  const {
    selectedFilter2,
    selectedFilter3,
    selectedFilter1,
    setSelectedFilter3,
    setSelectedFilter2,
    setSelectedFilter1,
  } = filters;

  const { brands } = useBrands();
  const autoTypes = useAutoTypes();
  const modifications = useModifications();

  const isMobile = breakpoint === "mobile" || breakpoint === "tablet";

  useEffect(() => {
    setOpen(!isMobile);
  }, [isMobile]);

  bodyModalOpenHandle(isMobile && open);

  return (
    <div>
      {isMobile && (
        <button
          className={classNames("p4", classes.MobileBtn)}
          onClick={() => setOpen(true)}
        >
          Фильтры
          <FilterIcon />
        </button>
      )}
      {open && (
        <div className={classes.Filters}>
          {isMobile && (
            <header className={classes.MobileHeader}>
              <h3 className="h3">Фильтры</h3>
              <button className={classes.Close} onClick={() => setOpen(false)}>
                <CrossIcon />
              </button>
            </header>
          )}
          <CheckboxesGroup
            type="Бренд"
            checkboxes={brands.map((brand) => ({
              name: brand.attributes.brandName,
              value: brand.id.toString(),
              count: brand.attributes.models?.data?.length,
            }))}
            selected={selectedFilter1}
            setSelected={setSelectedFilter1}
          />
          <CheckboxesGroup
            type="Тип"
            checkboxes={autoTypes.map((autoType) => ({
              name: autoType.attributes.title,
              value: autoType.id.toString(),
              count: autoType.attributes.models?.data?.length,
            }))}
            selected={selectedFilter2}
            setSelected={setSelectedFilter2}
          />
          <CheckboxesGroup
            type="Модификация"
            checkboxes={modifications.map((modification) => ({
              name: modification.attributes.title,
              value: modification.id.toString(),
              count: modification.attributes.models?.data?.length,
            }))}
            selected={selectedFilter3}
            setSelected={setSelectedFilter3}
          />
          {isMobile && (
            <button
              className={classNames("btn block", classes.Apply)}
              onClick={() => setOpen(false)}
            >
              Применить
            </button>
          )}
        </div>
      )}
    </div>
  );
};
