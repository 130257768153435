import classNames from "classnames";
import { PressRelease } from "../PressRelease";
import classes from "./PressReleaseContent.module.css";
import { useNewsRelease } from "../../hooks/useNews";
import { TopContent } from "../../../../components/TopContent";
import { Navigate } from "react-router-dom";

export const PressReleaseContent = ({ id }: { id?: string | undefined }) => {
  const content = useNewsRelease(id);

  if (content === null) {
    return <Navigate to="/404" />;
  }

  return content ? (
    <section className={classNames(classes.Wrapper, "section container")}>
      <TopContent
        title={content.attributes.title}
        publishedAt={content.attributes.date}
      />
      <PressRelease content={content} />
    </section>
  ) : null;
};
