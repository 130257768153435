import classes from "./DoubleImageBlock.module.css";

interface DoubleImageBlockProps {
  children: string | JSX.Element | JSX.Element[];
  firstImg: string | undefined;
  secondImg?: string | undefined;
  firstImgXl?: string;
  firstImgLg?: string;
  firstImgMd?: string;
}

export const DoubleImageBlock = ({
  children,
  firstImg,
  secondImg,
  firstImgXl,
  firstImgLg,
  firstImgMd,
}: DoubleImageBlockProps) => {
  return (
    <div className={`${classes.Section} section container`}>
      <div className={classes.Content}>{children}</div>
      <div className={classes.FirstImage}>
        <picture>
          <source media="(min-width: 1440px)" srcSet={firstImgXl} />
          <source media="(min-width: 1024px)" srcSet={firstImgLg} />
          <source media="(min-width: 768px)" srcSet={firstImgMd} />
          <img src={firstImg} className="img-cover" />
        </picture>
      </div>
      {secondImg && (
        <div className={classes.SecondImage}>
          <img src={secondImg} />
        </div>
      )}
    </div>
  );
};
