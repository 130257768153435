import { useEffect, useState } from "react";
import { ModelsService } from "../api/ModelsService";
import { IAutoType } from "../interfaces/IAutoType";

export const useAutoTypes = () => {
  const [parameters, setParameters] = useState<IAutoType[]>([]);

  useEffect(() => {
    const models = new ModelsService();

    models.getAutoTypes().then((res) => {
      setParameters(res.data.data);
    });
  }, []);

  return parameters;
};
