import { useEffect, useState } from "react";

import { promiseErrorHandle } from "../../../common/libs/promiseErrorHandle";

import { FooterService } from "../api/FooterService";

import { TFooterContent } from "../types";

export const useFooter = () => {
  const [footerContent, setFooterContent] = useState<TFooterContent>();

  useEffect(() => {
    const footerContentResponse = new FooterService();
    footerContentResponse
      .get()
      .then((res) => {
        setFooterContent(res.data.data);
      })
      .catch((e) => {
        promiseErrorHandle(e);
      });
  }, []);

  return { footerContent };
};
