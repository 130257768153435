import classes from "./ProductCard.module.css";
import { DiagonalArrowIcon } from "../../../../ui/_icons";
import { Link } from "react-router-dom";

interface ProductProps {
  link: string;
  image: string | undefined;
  title: string;
}

export const ProductCard = ({ title, image, link }: ProductProps) => {
  return (
    <Link className={classes.Card} to={link}>
      {image && (
        <picture className={classes.Image}>
          <img src={image} className="img-cover" />
        </picture>
      )}
      <div className={classes.Content}>
        <p className="h2 uppercase">{title}</p>
        <div className={classes.IconBlock}>
          <DiagonalArrowIcon />
        </div>
      </div>
    </Link>
  );
};
