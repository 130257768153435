import { Layout } from "../../modules/Layout";
import { Line } from "../../ui/Line";
import { Dealership } from "../../modules/Dealership";
import { AutoWithFilters } from "../../modules/Models";

export const ModelsPage = () => {
  return (
    <Layout>
      <AutoWithFilters />
      <Line />
      <Dealership title={`Дилеры FAW`} isWithLine={false} />
    </Layout>
  );
};
