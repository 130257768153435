import classNames from "classnames";

import classes from "./SecondaryProductMainSection.module.css";

type ProductMainSectionProps = {
  title: string;
  description: string;
  image?: string;
  mainImage: string;
  leftText: string;
  rightText: string;
};

export const SecondaryProductMainSection = ({
  title,
  description,
  image,
  mainImage,
  leftText,
  rightText,
}: ProductMainSectionProps) => {
  return (
    <div className="section container">
      <p className={classNames("p1 uppercase", classes.Title)}>{title}</p>
      <div className={classes.FirstBlock}>
        <p className={classNames("p1", classes.Text)}>{description}</p>
        <img src={image} className={classNames("image-cover", classes.Image)} />
      </div>

      <div className={classes.SecondBlock}>
        <img src={mainImage} className={classes.MainImage} />
        <p className={classNames("p1", classes.Text)}>{leftText}</p>
        <p className={classNames("p1", classes.Text)}>{rightText}</p>
      </div>
    </div>
  );
};
