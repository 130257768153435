import React, { Dispatch, SetStateAction } from "react";

import { TMenuItem } from "../modules/Header";

export const INITIAL_COMMON_STATE: ICommonContextValue = {
  menu: [],
};

export interface ICommonContextValue {
  menu: TMenuItem[];
}

type TCommonContext = [
  commonValue: ICommonContextValue,
  setCommonValue: Dispatch<SetStateAction<ICommonContextValue>>,
];

export const CommonContext = React.createContext<TCommonContext>([
  {} as ICommonContextValue,
  () => ({}),
]);
