import classes from "./Products.module.css";
import { ProductCard } from "../ProductCard";
import { useProducts } from "../../hooks/useProducts";
import { NAVIGATION } from "../../../../common/constants/navigation";
import { TProduct } from "../../constants/products";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { CarouselNavigation } from "../../../../components/CarouselNavigation";

interface ProductsLinksBlockProps {
  title?: string;
  additionalItems?: TProduct[];
  brandSlug?: string | undefined;
}

export const ProductsLinksBlock = ({
  title,
  additionalItems = [],
  brandSlug,
}: ProductsLinksBlockProps) => {
  const products = useProducts(brandSlug);

  const isPrimary = brandSlug !== "asiastar";
  const isCentered =
    (products.length || 0) + (additionalItems.length || 0) === 1;

  return (
    <div className={`${classes.Section} section container products`}>
      <h2 className="h2 uppercase center">Продукция {title || ""}</h2>
      <Swiper
        spaceBetween={20}
        slidesPerView={1}
        centeredSlides={isCentered}
        modules={[Navigation]}
        navigation={{
          prevEl: `.products .prev`,
          nextEl: `.products .next`,
        }}
        breakpoints={{
          768: {
            slidesPerView: 2,
          },
          1440: {
            slidesPerView: isPrimary ? 4 : 3,
          },
        }}
        className={classes.Cards}
      >
        {products.map((item) => (
          <SwiperSlide key={item.attributes.title}>
            <ProductCard
              link={`${NAVIGATION.products.link}/${item.attributes.slug}`}
              image={item.attributes.coverImage?.data?.attributes.url}
              title={item.attributes.categoryName}
            />
          </SwiperSlide>
        ))}
        {additionalItems.length ? (
          additionalItems.map((item) => (
            <SwiperSlide key={item.title}>
              <ProductCard
                link={item.link}
                image={item.image}
                title={item.title}
              />
            </SwiperSlide>
          ))
        ) : (
          <></>
        )}
      </Swiper>
      <CarouselNavigation />
    </div>
  );
};
