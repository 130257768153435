import classes from "./ServiceType.module.css";
import { LIST } from "../../constants/list";

export const ServiceType = ({
  title,
  text,
  list,
}: {
  title: string;
  text: string;
  list?: boolean;
}) => {
  return (
    <div className={classes.ServiceType}>
      <h4 className="h4">{title}</h4>
      {list ? (
        <ul className="p2">
          {LIST.map((item) => (
            <li key={item}>{item}</li>
          ))}
        </ul>
      ) : (
        <p className="p2">{text}</p>
      )}
    </div>
  );
};
