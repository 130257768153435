export const LIST = [
  "Правка рам и кабин на стапеле.",
  "Восстановление геометрии кабин и их окраска.",
  "Замена кабин с переборкой и замена отдельных элементов кабины.",
  "Всегда в наличии кабины для всех моделей автомобилей MAN (TGA, TGL, TGM, TGX, TGS).",
  "Кузовной ремонт любой сложности автобусов MAN и Neoplan",
  "Оперативная работа со страховыми компаниями.",
  "Работа собственного эксперта по проведению независимой экспертизы в начальный период и согласование в период ремонта.",
  "Оригинальные запчасти – постоянное наличие на складе (96% ассортимента), оперативная доставка в случае отсутствия на центральном складе.",
  "Прямые договоры со страховыми компаниями.",
];
