import { useState } from "react";
import { ISelectOption } from "../../../common/interfaces/ISelectOption";

export const useFilters = () => {
  const [selectedFilter1, setSelectedFilter1] = useState<ISelectOption[]>([]);
  const [selectedFilter2, setSelectedFilter2] = useState<ISelectOption[]>([]);
  const [selectedFilter3, setSelectedFilter3] = useState<ISelectOption[]>([]);

  return {
    selectedFilter1,
    selectedFilter3,
    selectedFilter2,
    setSelectedFilter1,
    setSelectedFilter3,
    setSelectedFilter2,
  };
};
