import { PropsWithChildren, useEffect, useState } from "react";

import { ModalSpawnerProvider } from "../../common/modal";
import { TMenuItemResponse, useMenu } from "../../common/hooks/useMenu";

import {
  CommonContext,
  INITIAL_COMMON_STATE,
} from "../../contexts/CommonContext";

import { TMenuItem } from "../Header";

export const AppProvider = ({ children }: PropsWithChildren) => {
  const [commonValue, setCommonValue] = useState(INITIAL_COMMON_STATE);

  const { menu } = useMenu();
  useEffect(() => {
    if (!menu || commonValue.menu.length) return;

    const mapSubList = (list: TMenuItemResponse[]): TMenuItem[] =>
      list.map((subList) => {
        if (subList.attributes.children.data.length) {
          return {
            title: subList.attributes.title,
            items: mapSubList(subList.attributes.children.data),
          };
        }

        return {
          title: subList.attributes.title,
          url: subList.attributes.url,
        };
      });

    const mappedMenu: TMenuItem[] = menu.map((list) => {
      const subList = mapSubList(list.attributes.children.data);

      if (subList.length) {
        return {
          title: list.attributes.title,
          items: subList,
        };
      }

      return {
        title: list.attributes.title,
        url: list.attributes.url,
      };
    });

    setCommonValue({ menu: mappedMenu });
  }, [menu]);

  return (
    <>
      <CommonContext.Provider value={[commonValue, setCommonValue]}>
        <ModalSpawnerProvider>{children}</ModalSpawnerProvider>
      </CommonContext.Provider>
    </>
  );
};
