export const PlusIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 26L16 6"
      stroke="white"
      strokeWidth="1.4"
      strokeLinecap="square"
    />
    <path
      d="M26 16L6 16"
      stroke="white"
      strokeWidth="1.4"
      strokeLinecap="square"
    />
  </svg>
);
