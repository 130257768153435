export const ListStar = () => (
  <svg
    width="14"
    height="16"
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 0L10.448 2.02789L13.9282 4L13.896 8L13.9282 12L10.448 13.9721L7 16L3.552 13.9721L0.0717969 12L0.104 8L0.0717969 4L3.552 2.02789L7 0Z"
      fill="#CFD04C"
    />
  </svg>
);
