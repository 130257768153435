import classes from "./CheckboxesGroup.module.css";
import { Checkbox } from "../../../../ui/Checkbox";
import { ChevronRightIcon } from "../../../../ui/_icons";
import { useState } from "react";
import classNames from "classnames";
import { ISelectOption } from "../../../../common/interfaces/ISelectOption";

interface CheckboxesGroupProps {
  type: string;
  checkboxes: ISelectOption[];
  selected: ISelectOption[];
  setSelected: (v: ISelectOption[]) => void;
}

export const CheckboxesGroup = ({
  type,
  selected,
  setSelected,
  checkboxes,
}: CheckboxesGroupProps) => {
  const [open, setOpen] = useState(false);

  return (
    <div className={classes.Group}>
      <h6
        className={classNames(classes.Title, open ? classes.TitleOpened : "")}
        onClick={() => setOpen(!open)}
      >
        {type}
        <ChevronRightIcon />
      </h6>
      {open && (
        <ul className={classes.Ul}>
          {checkboxes.map((item) => (
            <li key={item.name}>
              <Checkbox text={item.name} p2>
                <input
                  type="checkbox"
                  checked={!!selected.find((s) => s.value === item.value)}
                  onChange={(e) => {
                    setSelected(
                      e.target.checked
                        ? [...selected, item]
                        : selected.filter((s) => s.value !== item.value),
                    );
                  }}
                />
              </Checkbox>
              <span>{item.count}</span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
