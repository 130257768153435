import { Layout } from "../../modules/Layout";
import img from "./assets/promotion-hero.jpeg";
import { PromotionContent } from "./components/PromotionContent";
import { BottomLinks } from "../../modules/BottomLinks";

export const FawJ7Page = () => {
  return (
    <Layout>
      <picture className="section-m">
        <img src={img} />
      </picture>
      <PromotionContent />
      <BottomLinks />
    </Layout>
  );
};
