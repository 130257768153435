import classes from "./ContactCards.module.css";
import classNames from "classnames";
import { ContactIcon } from "../../../../ui/_icons";

export const ContactCards = () => {
  return (
    <div className={classNames(classes.Section, "container")}>
      <div className={classes.ContactCard}>
        <div className={classes.TopCard}>
          <p className="h3">Сервисный партнер в Москве</p>
          <ContactIcon />
        </div>
        <div className={classes.ContactInfos}>
          <p>Общий телефон: +7 (495) 969-25-14</p>
          <p>E-mail: pod.ru@man-rus.ru</p>
          <p>
            Адрес: 108831, г. Москва, вн. тер. г. поселение Воскресенское
            Квартал 110, д. 2/1, стр. 1
          </p>
        </div>
      </div>
      <div className={classes.ContactCard}>
        <div className={classes.TopCard}>
          <p className="h3">Сервисный партнер в Санкт-Петербурге</p>
          <ContactIcon />
        </div>
        <div className={classes.ContactInfos}>
          <p>Общий телефон: +7 (812) 449 52 52</p>
          <p>E-mail: info@man-spb.ru</p>
          <p>
            Адрес: 196627, Санкт-Петербург, поселок Шушары, Московское шоссе, д.
            181, кор. 2, стр. 1
          </p>
        </div>
      </div>
    </div>
  );
};
