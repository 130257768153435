export const BoldCrossIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.5563 5.41421L18.1421 4L11.7782 10.364L5.41426 4.00009L4.00005 5.41431L10.3639 11.7782L4 18.1421L5.41421 19.5563L11.7782 13.1924L18.1422 19.5564L19.5564 18.1422L13.1924 11.7782L19.5563 5.41421Z"
      fill="#FAFAFA"
    />
  </svg>
);
