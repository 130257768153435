import { useState, useEffect } from "react";
import { INew } from "../interfaces/INew";
import { NewsService } from "../api/NewsService";
import { promiseErrorHandle } from "../../../common/libs/promiseErrorHandle";
import { IPagination } from "../../../common/interfaces/IPagination";

export const useNews = (page: number, pageSize?: number) => {
  const [data, setData] = useState<{
    news: INew[];
    pagination: IPagination | undefined;
  }>({ news: [], pagination: undefined });

  useEffect(() => {
    if (pageSize) {
      const news = new NewsService();
      news
        .getNews(page, pageSize)
        .then((res) => {
          setData({
            news: res.data.data,
            pagination: res.data.meta.pagination,
          });
        })
        .catch((e) => {
          promiseErrorHandle(e);
        });
    }
  }, [page, pageSize]);

  return data;
};

export const useNewsRelease = (slug?: string) => {
  const [content, setContent] = useState<INew | undefined | null>();

  useEffect(() => {
    if (slug) {
      const news = new NewsService();
      news
        .getBySlug(slug)
        .then((res) => {
          setContent(res.data.data[0] || null);
        })
        .catch((e) => {
          setContent(null);
          promiseErrorHandle(e);
        });
    }
  }, [slug]);

  return content;
};

export const useOtherNews = (id: string | undefined) => {
  const [news, setNews] = useState<INew[]>([]);

  useEffect(() => {
    if (id) {
      const news = new NewsService();
      news.getOther(id).then((res) => {
        setNews(res.data.data);
      });
    }
  }, [id]);

  return news;
};
