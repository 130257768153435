import { useEffect, useState } from "react";
import { Products } from "../api/Products";
import { IProduct } from "../interfaces/IProduct";
import { promiseErrorHandle } from "../../../common/libs/promiseErrorHandle";

export const useProducts = (brandSlug?: string | undefined) => {
  const [products, setProducts] = useState<IProduct[]>([]);

  useEffect(() => {
    const products = new Products();
    products.getProducts(brandSlug, 0, 4).then((res) => {
      setProducts(res.data.data);
    });
  }, [brandSlug]);

  return products;
};

export const useProduct = (id?: string | undefined) => {
  const [product, setProduct] = useState<IProduct | undefined | null>();

  useEffect(() => {
    if (id) {
      const product = new Products();
      product
        .getBySlug(id)
        .then((res) => {
          setProduct(res?.data?.data[0] || null);
        })
        .catch((e) => {
          promiseErrorHandle(e);
          setProduct(null);
        });
    }
  }, [id]);

  return product;
};
