export const TITLE =
  "Сервисные контракты от\xA0компании ООО «Лидер Трак энд Бас» имеют несколько важных преимуществ для\xA0эффективного владения транспортным бизнесом:";

export const LIST_SUB_TITLE = "Четкое планирование расходов";

export const LIST = [
  "Стабильные интервалы технического обслуживания надежно предотвращают непредвиденный ремонт.",
  "Решения, включенные в сервисные контракты, точно соответствующие требованиям именно ваших транспортных задач.",
  "Объем услуг гарантирует надежную защиту и подлежит корректировке в\xA0любое время.",
];

export const FIRST_SUB_TITLE = "Сокращение времени простоя техники";

export const FIRST_TEXT =
  "Заблаговременное планирование и\xA0профилактический ремонт способствуют достижению цели: существенно повысить эксплуатационную готовность автомобилей вашего автопарка. Оптимальная эксплуатационная готовность помогает сократить расходы и повысить эффективность: чем дольше автомобиль в\xA0эксплуатации, тем выше ваша прибыль.";

export const SECOND_SUB_TITLE = "Разгрузка административных работников";

export const SECOND_TEXT =
  "Все виды работ по сервисным контрактам проводятся в рамках одного договора.Вам больше не нужно тратить время вашего персонала на\xA0заключение приложений и\xA0проведения большого количество счетов. Вы можете оплатить все работы в рамках одного счета в\xA0месяц. Кроме того, решение всех вопросов по сервисным контрактам происходитв режиме «одного окна» и\xA0с\xA0одним контактным лицом – вашим менеджером - включая работы по\xA0поддержанию исправного технического состояния, контроль счетов и\xA0реализацию гарантийных обязательств.";
