import classNames from "classnames";
import classes from "./SecondaryBrandInfo.module.css";
import { IBrand } from "../../interfaces/IBrand";

interface SecondaryBrandInfoProps {
  brand: IBrand;
}

export const SecondaryBrandInfo = ({ brand }: SecondaryBrandInfoProps) => {
  return (
    <section className="section container">
      <p className={classNames(classes.Title, "p1 uppercase")}>
        {brand.attributes.mainText}
      </p>
      <div className={classes.FirstBlock}>
        <p className={classNames(classes.Text, "p1")}>
          {brand.attributes.smallText1}
        </p>
        <img
          className={classes.FirstBlockImage}
          src={brand.attributes.smallImage1.data?.attributes.url}
        />
      </div>

      <iframe
        className={classes.Video}
        src="https://www.youtube.com/embed/1TbnQbyeYyw"
      ></iframe>

      <div className={classes.SecondBlock}>
        <img
          className={classes.SecondBlockImage}
          src={brand.attributes.smallImage2.data?.attributes.url}
        />
        <p className={classNames(classes.Text, "p1")}>
          {brand.attributes.smallText2}
        </p>
      </div>
    </section>
  );
};
