import classes from "./AboutSection.module.css";
import img from "../../assets/telematics.jpg";
import { TextWithImage } from "../../../../components/TextWithImage";
import classNames from "classnames";

export const AboutSection = () => {
  return (
    <TextWithImage img={img} isCustomMargin center>
      <p className={classNames("h3", classes.SubTitle)}>
        Что такое Фирменная телематика <br />
        от ООО «Лидер Трак энд Бас»?
      </p>
      <div className={classes.TextBlock}>
        <p className="p2">
          Телематический сервис, предназначенный для управления парком
          транспортных средств.
        </p>
        <p className="p2">
          В основе сервиса лежит сбор данных в режиме реального времени с
          техники, подключенной к сервису, обработка этих данных и отображение в
          удобном для дальнейшего использования виде в веб-приложении
          Телематический сервис.
        </p>
        <p className="p2">
          Сервис содержит встроенные инструменты управленческой аналитики и
          предназначен для оперативного управления парком транспортных средств в
          режиме реального времени.
        </p>
        <p className="p2">
          Элементы оперативного управления включают в себя средства отображения
          основных параметров транспортных средств в различных видах – на карте,
          в виде таблиц, графиков, отборов по отдельным параметрам и другие.
        </p>
      </div>
    </TextWithImage>
  );
};
