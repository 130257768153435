import { FawPageContent } from "../../modules/Products/components/FawPageContent/FawPageContent";

import { NAVIGATION } from "../../common/constants/navigation";

export const TyagachiFawPage = () => {
  return (
    <FawPageContent
      slug={NAVIGATION.tyagachiFaw.link.replace("/products/", "")}
    />
  );
};
