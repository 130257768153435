import { Hexagons } from "../Hexagons";

import { NewHero } from "../../../components/NewHero";

// import imgSm from "../../assets/mainHero/hero-320.png";
// import imgMd from "../../assets/mainHero/hero-768.png";
// import imgLg from "../../assets/mainHero/hero-1024.png";
// import imgXl from "../../assets/mainHero/hero.png";

import { THexagon } from "../Hexagons/Hexagons";

import classes from "./MainHero.module.css";

type MainHeroProps = {
  title: string;
  image: string;
  hexagons: THexagon[];
};

export const MainHero = ({ title, image, hexagons }: MainHeroProps) => {
  return (
    <div className={classes.Hero}>
      <NewHero
        imgSm={image}
        imgMd={image}
        imgLg={image}
        imgXl={image}
        title={title}
      />
      <section className={classes.Hexagons}>
        <Hexagons hexagons={hexagons} />
      </section>
    </div>
  );
};
