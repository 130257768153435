import { NewHero } from "../../components/NewHero";
import { BottomLinks } from "../../modules/BottomLinks";
import { Layout } from "../../modules/Layout";
import { Service } from "./components/Service";
import { Line } from "../../ui/Line";
import { LINKS } from "./constants/links";
import { ContactCards } from "./components/ContactCards";
import img from "./assets/service-hero.jpg";

export const ServicePage = () => {
  return (
    <Layout>
      <NewHero title={"сервис"} imgSm={img} />
      <Line />
      <Service />
      <ContactCards />
      <BottomLinks list={LINKS} />
    </Layout>
  );
};
