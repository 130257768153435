import { apiInstance } from "../../core/apiInstance";

export class MenuService {
  private BASE_URL = "/menus";

  async get() {
    return apiInstance.get(
      `${this.BASE_URL}?populate=*&nested=true&filters[slug][$eq]=menu`,
    );
  }
}
