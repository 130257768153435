export const CrossIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.14645 9.14645C8.95118 8.95118 8.6346 8.95118 8.43934 9.14645C8.24408 9.34171 8.24408 9.65829 8.43934 9.85355L18.8995 20.3137L8.14645 31.0668C7.95118 31.262 7.95118 31.5786 8.14645 31.7739C8.34171 31.9691 8.65829 31.9691 8.85355 31.7739L19.6066 21.0208L30.3596 31.7739C30.5549 31.9691 30.8715 31.9691 31.0668 31.7739C31.262 31.5786 31.262 31.262 31.0668 31.0668L20.3137 20.3137L30.7739 9.85358C30.9691 9.65832 30.9691 9.34173 30.7739 9.14647C30.5786 8.95121 30.262 8.95121 30.0668 9.14647L19.6066 19.6066L9.14645 9.14645Z"
      fill="white"
    />
  </svg>
);
