import { BrowserRouter, Routes, Route } from "react-router-dom";

import { NAVIGATION } from "./common/constants/navigation";

import { AppProvider } from "./modules/AppProvider";

import { ScrollToTopHelper } from "./scroll-to-top-helper";
import { HomePage } from "./pages";
import { ContactsPage } from "./pages/contacts";
import { AboutPage } from "./pages/about";
import { NotFoundPage } from "./pages/404";
import { ErrorPage } from "./pages/502";
import { FawJ7Page } from "./pages/faw-j7";

import { ServicesPage } from "./pages/services";
import { GuaranteePage } from "./pages/special-offers/guarantee";
import { LegalPage } from "./pages/legal";
import { DriverTrainingPage } from "./pages/driver-training";
import { TelematicsPage } from "./pages/telematics";
import { TestDrivePage } from "./pages/test-drive";
import { JobPage } from "./pages/job";
import { DealersMapPage } from "./pages/dealers-map";

import { NewsPage } from "./pages/news";
import { RepairPartsPage } from "./pages/repair-parts";
import { ServiceContractsPage } from "./pages/service-contracts";
import { NewPage } from "./pages/news/[id]";
import { ServicePage } from "./pages/special-offers/service";
import { BrandPage } from "./pages/brands/[slug]";
import { SpecialOffersPage } from "./pages/special-offers";
import { SpecialOfferPage } from "./pages/special-offers/[id]";
import { ModelsPage } from "./pages/models";
import { ModelPage } from "./pages/models/[id]";
import { ProductsPage } from "./pages/products";
import { ProductPage } from "./pages/products/[id]";
import { TippersFawPage } from "./pages/products/tippers-faw";
import { TyagachiFawPage } from "./pages/products/tyagachi-faw";
import { TuristicheskieAvtobusyPage } from "./pages/products/turisticheskie-avtobusy";
import { AsiastarPage } from "./pages/brands/asiastar";
import { FawTrucksPage } from "./pages/brands/faw-trucks";

export const App = () => {
  return (
    <BrowserRouter>
      <AppProvider>
        <ScrollToTopHelper />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path={NAVIGATION.about.link} element={<AboutPage />} />
          <Route path={NAVIGATION.contacts.link} element={<ContactsPage />} />
          <Route path={NAVIGATION.promotion.link} element={<FawJ7Page />} />

          <Route path={NAVIGATION.jobs.link} element={<JobPage />} />
          <Route path={NAVIGATION.services.link} element={<ServicesPage />} />
          <Route
            path={NAVIGATION.fawTelematics.link}
            element={<TelematicsPage />}
          />
          <Route path={NAVIGATION.testDrive.link} element={<TestDrivePage />} />
          <Route path={NAVIGATION.guarantee.link} element={<GuaranteePage />} />
          <Route path={NAVIGATION.legal.link} element={<LegalPage />} />
          <Route
            path={NAVIGATION.driverTraining.link}
            element={<DriverTrainingPage />}
          />

          <Route
            path={NAVIGATION.dealerMap.link}
            element={<DealersMapPage />}
          />
          <Route path={NAVIGATION.news.link} element={<NewsPage />} />
          <Route path={`${NAVIGATION.news.link}/:id`} element={<NewPage />} />
          <Route path={NAVIGATION.service.link} element={<ServicePage />} />
          <Route
            path={NAVIGATION.repairParts.link}
            element={<RepairPartsPage />}
          />

          <Route
            path={NAVIGATION.serviceContracts.link}
            element={<ServiceContractsPage />}
          />

          <Route path={NAVIGATION.trucks.link} element={<FawTrucksPage />} />
          <Route path={NAVIGATION.buses.link} element={<AsiastarPage />} />
          <Route path="/brands/:slug" element={<BrandPage />} />

          <Route path={NAVIGATION.products.link} element={<ProductsPage />} />
          <Route
            path={`${NAVIGATION.products.link}/:id`}
            element={<ProductPage />}
          />
          <Route
            path={NAVIGATION.tippersFaw.link}
            element={<TippersFawPage />}
          />
          <Route
            path={NAVIGATION.tyagachiFaw.link}
            element={<TyagachiFawPage />}
          />
          <Route
            path={NAVIGATION.turisticheskieAvtobusy.link}
            element={<TuristicheskieAvtobusyPage />}
          />
          <Route
            path={NAVIGATION.specialOffers.link}
            element={<SpecialOffersPage />}
          />
          <Route
            path={`${NAVIGATION.specialOffers.link}/:id`}
            element={<SpecialOfferPage />}
          />
          <Route path={NAVIGATION.models.link} element={<ModelsPage />} />
          <Route
            path={`${NAVIGATION.models.link}/:id`}
            element={<ModelPage />}
          />
          {/* NO PROD */}

          <Route path="/502" element={<ErrorPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </AppProvider>
    </BrowserRouter>
  );
};
