import { useState, useEffect } from "react";
import { SpecialOffersService } from "../api/SpecialOffersService";
import { ISpecialOffer } from "../interfaces/ISpecialOffer";

export const useSpecialOffers = () => {
  const [serviceOffers, setServiceOffers] = useState<ISpecialOffer[]>([]);
  const [guaranteeOffers, setGuaranteeOffers] = useState<ISpecialOffer[]>([]);
  const [sparePartOffers, setSparePartOffers] = useState<ISpecialOffer[]>([]);

  useEffect(() => {
    (async () => {
      const specialOffers = new SpecialOffersService();

      const serviceOffers = await specialOffers.get("Сервис", 3);
      const guaranteeOffers = await specialOffers.get("Гарантия", 6);
      const sparePartOffers = await specialOffers.get("Запчасти", 3);

      const guaranteeData = guaranteeOffers.data.data;

      setServiceOffers(serviceOffers.data.data);
      setGuaranteeOffers(
        guaranteeData.length % 2 === 0
          ? guaranteeData
          : guaranteeData.splice(0, guaranteeData.length - 1),
      );
      setSparePartOffers(sparePartOffers.data.data);
    })();
  }, []);

  return { serviceOffers, guaranteeOffers, sparePartOffers };
};

export const useSpecialOffer = (id: string | undefined) => {
  const [specialOffer, setSpecialOffer] = useState<
    ISpecialOffer | undefined | null
  >();
  useEffect(() => {
    if (id) {
      const specialOffers = new SpecialOffersService();
      specialOffers
        .getBySlug(id)
        .then((res) => {
          setSpecialOffer(res.data.data[0]);
        })
        .catch(() => {
          setSpecialOffer(null);
        });
    }
  }, [id]);

  return specialOffer;
};

export const useOtherSpecialOffers = (id: string | undefined) => {
  const [specialOffers, setSpecialOffers] = useState<ISpecialOffer[]>([]);

  useEffect(() => {
    if (id) {
      const specialOffers = new SpecialOffersService();
      specialOffers
        .getOther(id)
        .then((res) => {
          setSpecialOffers(res.data.data);
        })
        .catch(() => {
          setSpecialOffers([]);
        });
    }
  }, [id]);

  return specialOffers;
};
