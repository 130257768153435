import { Layout } from "../../modules/Layout";
import { LegalPageContent } from "./components/LegalPageContent";
import { useLegal } from "../../modules/LegalPage/hooks/useLegal";

export const LegalPage = () => {
  const response = useLegal();
  const { legalContent } = response;
  if (!legalContent) return;

  return (
    <Layout>
      <LegalPageContent {...legalContent} />
    </Layout>
  );
};
