import classNames from "classnames";

import { List } from "../../../../ui/List";

import {
  TITLE,
  LIST_SUB_TITLE,
  LIST,
  FIRST_SUB_TITLE,
  FIRST_TEXT,
  SECOND_SUB_TITLE,
  SECOND_TEXT,
} from "../../constants/benefits";

import classes from "./Benefits.module.css";

export const Benefits = () => {
  return (
    <section className={classNames(classes.Section, "container")}>
      <h3 className={classNames(classes.Title, "h3")}>{TITLE}</h3>

      <div className={classes.Text}>
        <div>
          <h3 className={classNames(classes.SubTitle, "h3")}>
            {LIST_SUB_TITLE}
          </h3>
          <List list={LIST} tagClassName="p1" />
        </div>
        <div>
          <h3 className={classNames(classes.SubTitle, "h3")}>
            {FIRST_SUB_TITLE}
          </h3>
          <p className="p1">{FIRST_TEXT}</p>
        </div>
        <div>
          <h3 className={classNames(classes.SubTitle, "h3")}>
            {SECOND_SUB_TITLE}
          </h3>
          <p className="p1">{SECOND_TEXT}</p>
        </div>
      </div>
    </section>
  );
};
