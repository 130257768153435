import { apiInstance } from "../../../core/apiInstance";

export class Products {
  private BASE_URL = "/products";

  async getById(id?: string) {
    return apiInstance.get(`${this.BASE_URL}/${id}?populate=*`);
  }

  async getBySlug(slug: string) {
    return apiInstance.get(
      `${this.BASE_URL}?populate=deep,3&filters[slug]=${slug}`,
    );
  }

  async getProducts(
    brandSlug: string | undefined,
    page: number,
    pageSize: number,
  ) {
    return apiInstance.get(
      `${this.BASE_URL}?${
        brandSlug ? `filters[brands][slug]=${brandSlug}` : ""
      }&pagination[pageSize]=${pageSize}&pagination[page]=${page}&populate=*`,
    );
  }
}
