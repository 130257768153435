import { useParams } from "react-router-dom";
import { Layout } from "../../modules/Layout";
import { PressReleaseContent } from "../../modules/News/components/PressReleaseContent";

export const NewPage = () => {
  const { id } = useParams();
  return (
    <Layout>
      <PressReleaseContent id={id} />
    </Layout>
  );
};
