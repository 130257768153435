import { useSpecialOffers } from "../../hooks/useSpecialOffer";
import { Section } from "../Section";
import { Line } from "../../../../ui/Line";
import { NAVIGATION } from "../../../../common/constants/navigation";
import serviceImg from "../../assets/service-hero.jpg";
import guaranteeImg from "../../assets/guarantee-hero.png";
import sparePartsImg from "../../assets/spare-parts-hero.jpg";

export const SpecialOffersSections = () => {
  const { serviceOffers, guaranteeOffers, sparePartOffers } =
    useSpecialOffers();

  return (
    <>
      {!!serviceOffers.length && (
        <>
          <Section
            specialOffers={serviceOffers}
            title="Сервис"
            to={NAVIGATION.service.link}
            img={serviceImg}
          />
          <Line />
        </>
      )}
      {!!guaranteeOffers.length && (
        <>
          <Section
            specialOffers={guaranteeOffers}
            title="Гарантия"
            to={NAVIGATION.guarantee.link}
            img={guaranteeImg}
            guarantee
          />
          <Line />
        </>
      )}
      {!!sparePartOffers.length && (
        <Section
          specialOffers={sparePartOffers}
          title="Запасные части"
          to={NAVIGATION.repairParts.link}
          img={sparePartsImg}
        />
      )}
    </>
  );
};
