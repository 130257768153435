export const CheckCheckIcon = () => (
  <svg
    width="130"
    height="130"
    viewBox="0 0 130 130"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M98.3327 32.5L38.7493 92.0833L11.666 65"
      stroke="#CFD04C"
      strokeWidth="3.25"
      strokeLinecap="square"
    />
    <path
      d="M120 54.1667L79.375 94.7917L71.25 86.6667"
      stroke="#CFD04C"
      strokeWidth="3.25"
      strokeLinecap="square"
    />
  </svg>
);
