import classNames from "classnames";

import imgXl from "../../assets/main-image.jpg";
import imgLg from "../../assets/main-image-1024.jpg";
import imgMd from "../../assets/main-image-768.jpg";
import imgSm from "../../assets/main-image-320.jpg";

import { TITLE, SUB_TITLE } from "../../constants/mainInfo";

import classes from "./MainInfo.module.css";

export const MainInfo = () => {
  return (
    <section className={classNames(classes.Section, "container")}>
      <div className={classes.Text}>
        <h3 className="h3">{TITLE}</h3>
        <p className="p1">{SUB_TITLE}</p>
      </div>

      <picture className={classes.Picture}>
        <source media="(min-width: 1440px)" srcSet={imgXl} />
        <source media="(min-width: 1024px)" srcSet={imgLg} />
        <source media="(min-width: 768px)" srcSet={imgMd} />
        <img src={imgSm} />
      </picture>
    </section>
  );
};
