export type Breakpoints = "desktop" | "laptop" | "tablet" | "mobile";

export const MediaBreakpoints: {
  [key in Breakpoints]: number;
} = {
  mobile: 320,
  tablet: 768,
  laptop: 1024,
  desktop: 1440,
} as const;
