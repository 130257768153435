import { Layout } from "../../modules/Layout";
import { NewHero } from "../../components/NewHero";
import { ServicesSection } from "./components/ServicesSection";
import { BottomLinks } from "../../modules/BottomLinks";
import { useService } from "../../modules/Services/hooks/useService";

export const ServicesPage = () => {
  const serviceResponse = useService();
  const { serviceContent } = serviceResponse;
  if (!serviceContent) return;

  return (
    <Layout>
      <NewHero
        imgSm={
          serviceContent?.attributes.hero.heroBackgroundImage.data.attributes
            .url
        }
        title={
          serviceContent?.attributes.hero.heroTitle ||
          "Комплексные транспортные решения"
        }
      />
      <ServicesSection {...serviceContent?.attributes.content} />
      <BottomLinks />
    </Layout>
  );
};
