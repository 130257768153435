import { Layout } from "../../modules/Layout";
import { BottomLinks } from "../../modules/BottomLinks";
import { NewsSection } from "../../modules/News";

export const NewsPage = () => {
  return (
    <Layout>
      <NewsSection />
      <BottomLinks />
    </Layout>
  );
};
