import img320 from "./assets/telematics-bg-320.png";
import img768 from "./assets/telematics-bg-768.png";
import img1024 from "./assets/telematics-bg-1024.png";
import img1440 from "./assets/telematics-bg-1440.png";
import { Layout } from "../../modules/Layout";
import { NewHero } from "../../components/NewHero";
import { BottomLinks } from "../../modules/BottomLinks";
import { AboutSection } from "./components/AboutSection";
import { ManSystemSection } from "./components/ManSystemSection";
import { PrideBenefits } from "./components/PrideBenefits";

export const TelematicsPage = () => {
  return (
    <Layout>
      <NewHero
        title={
          <>
            ФИРМЕННАЯ ТЕЛЕМАТИКА
            <br /> ОТ ООО «ЛИДЕР ТРАК ЭНД БАС»
          </>
        }
        imgSm={img320}
        imgMd={img768}
        imgLg={img1024}
        imgXl={img1440}
      />
      <AboutSection />
      <ManSystemSection />
      <PrideBenefits />
      <BottomLinks />
    </Layout>
  );
};
