import classes from "./NewsPreview.module.css";
import { useNews } from "../../hooks/useNews";
import classNames from "classnames";
import { NewsPreviewCard } from "../NewsPreviewCard";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { NAVIGATION } from "../../../../common/constants/navigation";

type NewsPreview = {
  title?: string;
  buttonText?: string;
};

export const NewsPreview = ({ title, buttonText }: NewsPreview) => {
  const { news } = useNews(1, 3);

  return news.length ? (
    <section className={classNames("container section", classes.Section)}>
      <header className={classes.Header}>
        {title ? <h2 className="h2">{title}</h2> : <></>}
        <Link to={NAVIGATION.news.link} className="link uppercase">
          {buttonText}
        </Link>
      </header>
      <div className={classes.Swiper}>
        <Swiper
          spaceBetween={20}
          slidesPerView={2}
          breakpoints={{
            320: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            1440: {
              slidesPerView: 3,
            },
          }}
        >
          {news.map((item) => (
            <SwiperSlide className={classes.Slide} key={item.id}>
              <NewsPreviewCard newItem={item} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  ) : null;
};
