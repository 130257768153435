import classes from "./Brands.module.css";
import classNames from "classnames";
import { Brand } from "./Brand";
import { useBrands } from "../hooks/useBrands";

interface BrandsProps {
  title?: string;
  showBrandTitle?: boolean;
  isSmallCard?: boolean;
}

export const Brands = ({
  title,
  isSmallCard = false,
  showBrandTitle = true,
}: BrandsProps) => {
  const { brands } = useBrands();

  return brands ? (
    <section className={classNames("container", classes.Wrapper)}>
      {title && <h2 className={classNames("h2", classes.Title)}>{title}</h2>}
      <div
        className={classNames(
          classes.Brands,
          isSmallCard && classes.BrandsSmall,
        )}
      >
        {brands.map((item, index) => (
          <div key={index}>
            {showBrandTitle && (
              <h2 className={classNames("h2", classes.BrandTitle)}>
                {item.attributes.brandName.toLowerCase().includes("faw")
                  ? "Грузовики"
                  : "Автобусы"}
              </h2>
            )}
            <Brand
              title={item.attributes.brandName}
              link={`/brands/${item.attributes.slug}`}
              img={item.attributes.tileImage.data?.attributes.url}
            />
          </div>
        ))}
      </div>
    </section>
  ) : (
    <></>
  );
};
