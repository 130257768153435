import { useEffect, useState } from "react";

import classNames from "classnames";

import { UpArrowIcon } from "../../ui/_icons/UpArrowIcon/UpArrowIcon";

import classes from "./ToTopButton.module.css";

export const ToTopButton = () => {
  const [isButtonShowed, setIsButtonShowed] = useState(false);

  const handleButtonClick = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const onscroll = () => {
      setIsButtonShowed(scrollY > innerHeight);
    };
    addEventListener("scroll", onscroll);

    return () => {
      removeEventListener("scroll", onscroll);
    };
  }, []);

  return (
    <div
      className={classNames(classes.Wrapper, "container")}
      data-sticky={isButtonShowed}
    >
      <button className={classes.Button} onClick={handleButtonClick}>
        <UpArrowIcon />
      </button>
    </div>
  );
};
