import imgSm from "./assets/training-hero-320.png";
import imgMd from "./assets/training-hero-768.png";
import imgLg from "./assets/training-hero-1024.png";
import imgXl from "./assets/training-hero-1440.png";

import { NewHero } from "../../components/NewHero";
import { Layout } from "../../modules/Layout";
import { BottomLinks } from "../../modules/BottomLinks";
import { PageContent } from "./components/PageContent";

export const DriverTrainingPage = () => {
  return (
    <Layout>
      <NewHero
        imgSm={imgSm}
        imgMd={imgMd}
        imgLg={imgLg}
        imgXl={imgXl}
        title={
          <span>
            ОБУЧЕНИЕ <br />
            ВОДИТЕЛЕЙ <br />
            PROFIDRIVE
          </span>
        }
      />
      <PageContent />
      <BottomLinks />
    </Layout>
  );
};
