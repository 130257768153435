import classes from "./ServicesSection.module.css";

import { ServiceList } from "../../../../modules/Services";
import { IContent } from "../../../../modules/Services/types";

export const ServicesSection = (props: IContent) => {
  return (
    <section className="container section">
      <header className={classes.Header}>
        <h2 className="h2">{props.title}</h2>
      </header>
      <ServiceList data={props.services} />
    </section>
  );
};
