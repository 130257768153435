import { useNavigate } from "react-router-dom";

import { Layout } from "../../modules/Layout";
import { AutoSection } from "../../modules/Models";
import { SecondaryProductMainSection } from "../../modules/Products/components/SecondaryProductMainSection";
import { useProduct } from "../../modules/Products";

import { NAVIGATION } from "../../common/constants/navigation";
import { NewHero } from "../../components/NewHero";

import { Line } from "../../ui/Line";

import busImage from "./assets/bus.jpg";

export const TuristicheskieAvtobusyPage = () => {
  const product = useProduct(
    NAVIGATION.turisticheskieAvtobusy.link.replace("/products/", ""),
  );
  const navigation = useNavigate();

  if (product === null) {
    navigation("/404");
  }

  const productTitle = product?.attributes.title || "";

  return product ? (
    <Layout>
      <NewHero
        title={`${productTitle ?? ""}`}
        imgSm={product.attributes.coverImage.data?.attributes.url}
      />

      <SecondaryProductMainSection
        title="Дизайн от итальянского ателье Pininfarina"
        description={product.attributes.description}
        image={product.attributes.smallImage?.data?.attributes.url}
        mainImage={busImage}
        leftText="Pininfarina — всемирно признанная икона итальянского стиля, завоевавшая славу благодаря непревзойденному таланту создавать неподвластную времени красоту, почитая ценности элегантности, чистоты и инновационности. В штате компании трудятся 500 человек, офисы расположены в Италии, Германии, Китае и США. Pininfarina стала первым итальянским дизайн-ателье, которое с 1996 года и по настоящее время оказывает услуги разработки дизайна и проектирования китайским автопроизводителям. В 2010 году был открыт офис Pininfarina Shanghai, чтобы разместить студию разработки дизайна в Шанхае — стратегической автомобильной точке на карте Азии."
        rightText="Сегодня Pininfarina Shanghai — это дизайн‐ателье, способное предложить китайскому рынку весь спектр профильных услуг: от автомобильного или архитектурного дизайна до решений в сфере промышленности или пользовательского интерфейса. Под руководством генерального директора Симоне Тасси (Simone Tassi) Pininfarina Shanghai недавно открыла офисы в Шэньчжэне и Нинбо."
      />

      <Line isWithMargin />
      <AutoSection title="" models={product.attributes.models.data} />
    </Layout>
  ) : null;
};
