import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

import { useBreakpoint } from "../../../common/hooks/useBreakpoint";
import { useModal } from "../../../common/modal";

import { FeedbackModal } from "../../FeedbackModal";

import { BurgerIcon, CrossIcon } from "../../../ui/_icons";
import { Logo } from "../../../ui/Logo";

import { Contacts } from "./Contacts";
import { DesktopMenu } from "./DesktopMenu";
import { MobileMenu } from "./MobileMenu";

import { useOnClickOutside } from "../../../common/hooks/useClickOutside";

import classes from "./Header.module.css";

export const Header = () => {
  const [openModal] = useModal(FeedbackModal);
  const [open, setOpen] = useState(false);
  const bp = useBreakpoint();

  const ref = useRef<HTMLDivElement | null>(null);
  const { isShowing, setIsShowing } = useOnClickOutside(ref);

  const [isHeaderHidden, setIsHeaderHidden] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);

  const handleOpenModal = () => {
    openModal({});
  };

  const handleBurgerClick = () => {
    setOpen(!open);
    if (!open) setIsShowing(true);
  };

  useEffect(() => {
    setOpen(bp === "desktop");
  }, [bp]);

  useEffect(() => {
    if (!isShowing) setOpen(bp === "desktop");
  }, [isShowing]);

  useEffect(() => {
    if (isHeaderHidden && bp === "laptop") setOpen(false);
  }, [isHeaderHidden]);

  useEffect(() => {
    const onscroll = () => {
      setLastScrollY(scrollY);
      setIsHeaderHidden(scrollY > lastScrollY);
    };

    addEventListener("scroll", onscroll);

    return () => {
      removeEventListener("scroll", onscroll);
    };
  }, [lastScrollY]);

  return (
    <header ref={ref} className={classes.Wrapper} data-hidden={isHeaderHidden}>
      <div className={classNames("container", classes.Container)}>
        <div className={classes.Header}>
          <Link to="/" className={classes.Logo}>
            <Logo />
          </Link>
          {open && <DesktopMenu isHeaderHidden={isHeaderHidden} />}
          {open && <MobileMenu onButtonClick={handleOpenModal} />}
          <div className={classes.Contacts}>
            <Contacts />
          </div>
          <button
            className={classNames("btn", classes.ModalButton)}
            onClick={handleOpenModal}
          >
            Связаться с нами
          </button>
          <button className={classes.Burger} onClick={handleBurgerClick}>
            {open ? <CrossIcon /> : <BurgerIcon />}
          </button>
        </div>
      </div>
    </header>
  );
};
