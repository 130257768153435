import classes from "./Decorations.module.css";

export const DecorationTop = () => {
  return (
    <div className={classes.TopWrapper}>
      <div className={classes.Top}>
        <svg
          width="1440"
          height="789"
          viewBox="0 0 1440 789"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.2"
            d="M1807 344.818C1737.98 448.796 1570.32 589.513 1277.9 570.15C1141.95 561.148 893.51 430.145 873.408 241.886C841.028 -61.3628 1323.8 -102.488 1277.9 258.352C1262.47 379.693 1123.35 616.329 978.476 695.084C609.438 895.699 222.202 747.706 -29 477.44"
            stroke="#FAFAFA"
            strokeWidth="2"
            strokeDasharray="18 18"
          />
          <circle
            cx="1186"
            cy="478"
            r="10"
            transform="rotate(-180 1186 478)"
            fill="#CFD04C"
          />
        </svg>
      </div>
    </div>
  );
};

export const DecorationBottom = () => (
  <div className={classes.BottomWrapper}>
    <div className={classes.Bottom}>
      <svg
        width="2324"
        height="999"
        viewBox="0 0 2324 999"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.2"
          d="M0.999951 436.486C88.2929 568.186 300.33 746.421 670.153 721.895C842.097 710.493 1156.3 544.562 1181.72 306.11C1222.67 -77.9897 612.103 -130.079 670.153 326.967C689.674 480.659 865.619 780.387 1048.84 880.139C1515.56 1134.24 2005.3 946.791 2323 604.467"
          stroke="#FAFAFA"
          strokeWidth="1.81406"
          strokeDasharray="16.33 16.33"
        />
        <circle
          cx="9.07031"
          cy="9.07031"
          r="9.07031"
          transform="matrix(1 -8.74228e-08 -8.74228e-08 -1 675.612 402.603)"
          fill="#CFD04C"
        />
      </svg>
    </div>
  </div>
);
