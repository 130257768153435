/** 100000 -> 100 000 */
export const getNumberWithSpaces = (
  value: string | number,
  isReturnedZero?: boolean,
): string => {
  if (!value || isNaN(+value)) {
    return isReturnedZero ? "0" : "";
  }
  const result = new Intl.NumberFormat("ru-RU").format(+value);
  return result.replace(",", ".");
};
