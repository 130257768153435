export const LeftArrowIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.172 12H5.8291"
      stroke="#fafafa"
      strokeWidth="1.4"
      strokeLinecap="square"
    />
    <path
      d="M10.6864 17.6571L5.0293 11.9999L10.6864 6.34277"
      stroke="#fafafa"
      strokeWidth="1.4"
      strokeLinecap="square"
    />
  </svg>
);
