import { Link } from "react-router-dom";

import { DiagonalArrowIcon } from "../../../../ui/_icons";

import classes from "./BigProductCard.module.css";

interface BigProductCardProps {
  link: string;
  image: string | undefined;
  title: string;
}

export const BigProductCard = ({ title, image, link }: BigProductCardProps) => {
  return (
    <Link className={classes.Card} to={link}>
      {image && (
        <picture className={classes.Image}>
          <img src={image} className="img-cover" />
        </picture>
      )}
      <div className={classes.Content}>
        <p className="h2 uppercase">{title}</p>
        <DiagonalArrowIcon />
      </div>
    </Link>
  );
};
