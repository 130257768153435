import { useNavigate, useParams } from "react-router-dom";

import { Line } from "../../ui/Line";

import { Layout } from "../../modules/Layout";

import { AutoSection } from "../../modules/Models";
import { BottomLinks } from "../../modules/BottomLinks";

import { TernaryProductMainSection } from "../../modules/Products/components/TernaryProductMainSection";

import { useProduct } from "../../modules/Products";

export const ProductPage = () => {
  const { id } = useParams();

  const product = useProduct(id);

  const navigation = useNavigate();

  if (product === null) {
    navigation("/404");
  }

  const productTitle = product?.attributes.title || "";

  return product ? (
    <Layout>
      <TernaryProductMainSection
        title={productTitle || ""}
        description={product.attributes.description}
        image={product.attributes.smallImage?.data?.attributes.url}
      />

      <Line isWithMargin />

      <AutoSection title={""} models={product.attributes.models.data} />

      <BottomLinks />
    </Layout>
  ) : null;
};
