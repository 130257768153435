import classNames from "classnames";

import { IBrand } from "../../interfaces/IBrand";

import classes from "./TernaryBrandInfo.module.css";

type TernaryBrandInfoProps = Pick<
  IBrand["attributes"],
  "smallImage1" | "smallImage2" | "mainText" | "smallText1" | "smallText2"
>;

export const TernaryBrandInfo = ({
  smallImage1,
  smallImage2,
  mainText,
  smallText1,
  smallText2,
}: TernaryBrandInfoProps) => {
  return (
    <section className={classNames(classes.Section, "section container")}>
      <h2 className={"h1"}>О бренде</h2>
      <div className={classes.Text}>
        {mainText && <p className="p2">{mainText}</p>}
        {smallText1 && <p className="p2">{smallText1}</p>}
        {smallText2 && <p className="p2">{smallText2}</p>}
      </div>
      <div className={classes.Images}>
        <div>
          <img src={smallImage1.data?.attributes.url} />
        </div>
        <div className={classes.SecondImage}>
          <img src={smallImage2.data?.attributes.url} />
        </div>
      </div>
    </section>
  );
};
