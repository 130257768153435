import { Link } from "react-router-dom";

import { TMenuItem } from "../../types";

import classes from "./MobileItems.module.css";

type MobileItemsProps = {
  items: TMenuItem[];
  onButtonClick: (item: TMenuItem) => void;
};

export const MobileItems = ({ items, onButtonClick }: MobileItemsProps) => {
  return (
    <ul className={classes.List}>
      {items?.length ? (
        items?.map((subItem) => (
          <li key={subItem.title}>
            {subItem.url ? (
              <Link to={subItem.url} className={classes.Item}>
                {subItem.title}
              </Link>
            ) : (
              <button
                className={classes.Item}
                onClick={() => onButtonClick(subItem)}
              >
                {subItem.title}
              </button>
            )}
          </li>
        ))
      ) : (
        <></>
      )}
    </ul>
  );
};
