import classes from "./ManSystemSection.module.css";
import mainImg from "../../assets/telematics-2.jpg";
import subImg from "../../assets/telematics-3.jpg";
import { List } from "../../../../ui/List";
import { ANALITICS_LIST } from "../../constants/textContent";

export const ManSystemSection = () => {
  return (
    <div className="section-m container">
      <div className={classes.Content}>
        <div className={classes.MainPicture}>
          <picture>
            <img src={mainImg} />
          </picture>
        </div>
        <div className={classes.ListBlock}>
          <p className="p2">
            Аналитическая подсистема содержит ряд шаблонных представлений и
            отчетов, разработанных на основе многолетнего опыта:
          </p>
          <List list={ANALITICS_LIST} />
        </div>
        <div className={classes.SubPicture}>
          <picture>
            <img src={subImg} />
          </picture>
        </div>
        <div className={classes.TextBlock}>
          <p className="p2">
            Одной из важнейших функций системы Фирменной телематики от ООО
            «Лидер Трак энд Бас» является возможность моментального оповещения
            ответственных сотрудников в случае выхода контролируемых параметров
            из заданного коридора. К системе может подключаться техника
            различных брендов, что крайне важно в реальных парках, обычно
            имеющих смешанный состав. Для подключения дополнительных
            транспортных средств необходимо связаться с оператором, и получить
            исчерпывающую инструкцию. В итоге весь автотранспорт, подключенный к
            сервису, находится под полным контролем! контролем!
          </p>
        </div>
      </div>
    </div>
  );
};
