import { NewHero } from "../../components/NewHero";
import { Layout } from "../../modules/Layout";
// import { BottomLinks } from "../../modules/BottomLinks";
// import bg320 from "./assets/bg-1440.png";
// import bg768 from "./assets/bg-1440.png";
// import bg1024 from "./assets/bg-1440.png";
// import bg1440 from "./assets/bg-1440.png";
// import { BackgroundWithTitle } from "../../components/BackgroundWithTitle";
// import { ArrowRightIcon } from "../../ui/_icons";
// import { Link } from "react-router-dom";
import { About } from "./components/About";
import { useAbout } from "../../modules/About/hooks/useAbout";
// import { NAVIGATION } from "../../common/constants/navigation";

export const AboutPage = () => {
  const aboutResponse = useAbout();
  const { aboutContent } = aboutResponse;
  if (!aboutContent) return;

  return (
    <Layout>
      <NewHero
        isFullText
        imgSm={
          aboutContent?.attributes.hero.heroBackgroundImage.data.attributes
            .url || ""
        }
        imgMd={
          aboutContent?.attributes.hero.heroBackgroundImage.data.attributes
            .url || ""
        }
        imgLg={
          aboutContent?.attributes.hero.heroBackgroundImage.data.attributes
            .url || ""
        }
        imgXl={
          aboutContent?.attributes.hero.heroBackgroundImage.data.attributes
            .url || ""
        }
        title={<>{aboutContent?.attributes.hero.heroTitle}</>}
      />
      <About
        {...aboutContent?.attributes.content}
        primaryImage={
          aboutContent?.attributes.content.primaryImage?.data.attributes.url
        }
        secondaryImage={
          aboutContent?.attributes.content.secondaryImage?.data.attributes.url
        }
      />
      {/* <BackgroundWithTitle
        isSection
        imgSm={bg320}
        imgMd={bg768}
        imgLg={bg1024}
        imgXl={bg1440}
        title={
          <Link to={NAVIGATION.products.link}>
            Продукция в наличии
            <ArrowRightIcon />
          </Link>
        }
      /> */}
      {/*<BottomLinks />*/}
    </Layout>
  );
};
