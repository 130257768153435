import classes from "./PressRelease.module.css";
import { PressReleaseLinks } from "../PressReleaseLinks";
import { INew } from "../../interfaces/INew";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import classNames from "classnames";
import { renderMd } from "../../../../common/libs/renderMd";

export const PressRelease = ({ content }: { content: INew }) => {
  return (
    <div className={classes.PressRelease}>
      <div className={classes.Main}>
        {content.attributes.coverImage.data && (
          <div className={classes.Image}>
            <picture>
              <img src={content.attributes.coverImage.data.attributes.url} />
            </picture>
          </div>
        )}
        {content.attributes.description && (
          <div className={classes.TextContent}>
            <div
              className={classNames(classes.Text, "p2")}
              dangerouslySetInnerHTML={{
                __html: renderMd(content.attributes.description),
              }}
            />
          </div>
        )}
        {content.attributes.gallery?.data && (
          <div className={classNames("gallery", classes.SwiperWrap)}>
            <Swiper
              className={classes.Swiper}
              pagination={{ clickable: true }}
              spaceBetween={0}
              slidesPerView={1}
              modules={[Navigation, Pagination]}
              navigation={{
                prevEl: `.gallery-swiper .prev`,
                nextEl: `.gallery-swiper .next`,
              }}
            >
              {content.attributes.gallery?.data.map((item) => (
                <SwiperSlide key={item.id}>
                  <div className={classes.SlideImage}>
                    <picture>
                      <img src={item.attributes.url} className="img-cover" />
                    </picture>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        )}
      </div>
      <PressReleaseLinks id={content.id.toString()} />
    </div>
  );
};
