import { WithClosableProps } from "../../../common/modal";
import { Modal } from "../../../components/Modal";

import { FeedbackForm } from "./FeedbackForm";

// import classes from "./Layout.module.css";

export const FeedbackModal = (props: WithClosableProps) => {
  return (
    <Modal {...props}>
      <FeedbackForm />
    </Modal>
  );
};
