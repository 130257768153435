import classes from "./DescriptionBlock.module.css";
import { TextWithImage } from "../../../../components/TextWithImage";
import img from "../../assets/training-1-1440.png";

export const DescriptionBlock = () => {
  return (
    <TextWithImage img={img} isCustomMargin>
      <p className={`${classes.SubTitle} p1 uppercase`}>
        ProfiDrive предлагает квалифицированную систему тренингов, которая
        нацелена на удовлетворение потребностей водителей и одновременно
        учитывает требования управленцев и собственников бизнеса.
      </p>
      <div className={classes.TextBlock}>
        <p className="p2">
          В ассортимент услуг ProfiDrive входят и базовые тренинги по
          экономичному вождению, эксплуатации грузовой техники в условиях
          бездорожья, основам устройства автобусной и грузовой техники.
        </p>
        <p className="p2">
          Даже хорошие водители могут узнать много нового и полезного для себя
          на курсах ProfiDrive. Благодаря специальным тренингам ProfiDrive
          водители грузовых автомобилей и автобусов совершенствуют навыки
          вождения и структурируют свои теоретические знания.
        </p>
        <p className="p2">
          <a href="mailto:profidrive@leader-trucks.ru" className="link">
            profidrive@leader-trucks.ru
          </a>
        </p>
      </div>
    </TextWithImage>
  );
};
