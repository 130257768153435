import { apiInstance } from "../../../core/apiInstance";
import { IPostFormData } from "../interfaces/IPostFormData";

export class FeedbackFormService {
  private BASE_URL = "/os-forms";

  async post(data: IPostFormData) {
    return apiInstance.post(this.BASE_URL, { data });
  }
}

export class FeedbackFormFieldsService {
  private BASE_URL = "/os-form-field";

  async get() {
    return apiInstance.get(`${this.BASE_URL}?populate=deep`);
  }
}
