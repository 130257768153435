import classes from "./GuaranteeList.module.css";
import { GUARANTEES } from "../../constants/guarantees";

export const GuaranteeList = () => {
  return (
    <div className={classes.GuaranteeList}>
      {GUARANTEES.map((guarantee, idx) => (
        <div key={guarantee} className={classes.Guarantee}>
          <h3 className="h3">{`${idx + 1}/`}</h3>
          <p className="p2">{guarantee}</p>
        </div>
      ))}
    </div>
  );
};
