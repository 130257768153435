import classes from "./ProgramBlock.module.css";
import { List } from "../../../../ui/List";
import imgXl from "./../../assets/training-2-1440.png";
import { PROGRAM_LIST } from "../../constants/textContent";

export const ProgramsBlock = () => {
  return (
    <div className={`${classes.Section} container section-m`}>
      <div className={classes.ImageBlock}>
        <picture>
          <img src={imgXl} alt="ProfiDrive обучение" className="img-cover" />
        </picture>
      </div>
      <div className={classes.Content}>
        <p className="p2">ProfiDrive предлагает 5 программ обучения:</p>
        <List list={PROGRAM_LIST} />
      </div>
    </div>
  );
};
