export const phoneNumberMask = [
  "+",
  /[1-9]/,
  "(",
  /[1-9]/,
  /\d/,
  /\d/,
  ")",
  "-",
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];
