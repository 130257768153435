import { apiInstance } from "../../../core/apiInstance";

export class BrandService {
  private BASE_URL = "/brands";

  async get() {
    return apiInstance.get(`${this.BASE_URL}?pagination[limit]=2&populate=*`);
  }

  async getBySlug(slug: string | undefined) {
    return apiInstance.get(
      `${this.BASE_URL}?pagination[limit]=1&populate=*&filters[slug]=${slug}`,
    );
  }
}
