import classNames from "classnames";
import classes from "./Footer.module.css";
import { Logo } from "../../../ui/Logo";
import { useFooter } from "../hooks/useFooter";
import { FooterLink } from "../types";
import { useBreakpoint } from "../../../common/hooks/useBreakpoint";

function mergeArrays(array1: FooterLink[], array2: FooterLink[]) {
  const array3 = [];

  // Добавляем элементы из array1 в array3
  for (let i = 0; i < array1.length; i++) {
    for (let j = 0; j < (array2.length + array1.length) * 2; j++) {
      array3[j] = array1[i];
      i++;
      j += 2;
    }
  }

  // Добавляем элементы из array2 в array3
  for (let i = 0; i < array2.length; i++) {
    for (let j = 1; j < (array2.length + array1.length) * 2; j++) {
      array3[j] = array2[i];
      i++;
      j += 2;
    }
  }

  return array3;
}

export const Footer = () => {
  const footerResponse = useFooter();
  const { footerContent } = footerResponse;
  const breakpoint = useBreakpoint();
  if (!footerContent) return;

  const isMobile = breakpoint === "mobile";

  const fullLinks = isMobile
    ? [
        ...footerContent.attributes.pagesColumn,
        ...footerContent.attributes.linksColumn,
      ]
    : mergeArrays(
        footerContent.attributes.pagesColumn,
        footerContent.attributes.linksColumn,
      );

  return (
    <footer className={classNames("container", classes.Wrapper)}>
      <div className={classes.Footer}>
        <div className={classes.Logo}>
          <Logo />
        </div>
        <ul className={classes.FooterLinks}>
          {fullLinks.map((item, index) => (
            <li
              key={item?.title || ""}
              className={classes.Link}
              style={{ "--mobile-row": index + 1 } as React.CSSProperties}
            >
              <a
                className="p2"
                href={item?.link || ""}
                target={item?.link?.includes("https") ? "_blank" : ""}
                rel="noreferrer"
              >
                {item?.title || ""}
              </a>
            </li>
          ))}
          <ul className={classes.Socials}>
            {footerContent.attributes.socialNetworks.map((item) => (
              <li key={item.link} className={classes.Social}>
                <a href={item.link} target="_blank" rel="noreferrer">
                  <img src={item.iconImage.data.attributes.url} />
                </a>
              </li>
            ))}
          </ul>
        </ul>
      </div>
    </footer>
  );
};
