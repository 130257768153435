import classes from "./PrideBenefits.module.css";
import cardImg from "../../assets/telematics-4.jpg";
import { CARDS } from "../../constants/textContent";
import classNames from "classnames";

export const PrideBenefits = () => {
  return (
    <div className="section container">
      <div className={classes.TopBlock}>
        <h3 className={classNames("h3", classes.Title)}>
          Что получает клиент, который <br />
          использует фирменный телематический сервис
        </h3>
        <div className={classes.TopTextBlock}>
          <div className={classes.TextBlocks}>
            <p className="p1 uppercase">
              ВЛАДЕЛЬЦЫ КОММЕРЧЕСКОГО ТРАНСПОРТА БОЛЕЕ ДРУГИХ ЗАБОТЯТСЯ О
              ПОВЫШЕНИИ ЭФФЕКТИВНОСТИ ЗА СЧЕТ СНИЖЕНИЯ ЗАТРАТ.
            </p>
            <p className="p2">
              И для этого руководители транспортных парков уже давно используют
              телематические сервисы. Наиболее успешны те компании, которые
              внедряют самые современные системы мониторинга и при этом
              бизнес-процессы настраивают так, чтобы данные телематических
              сервисов максимально использовались в ежедневной работе.
            </p>
          </div>
          <p className={classNames("p2", classes.TextBox)}>
            Телематическая система предназначена для российских владельцев
            транспорта. К сервису подключается транспорт из складской программы
            и, соответственно, в течение двух лет клиент сможет бесплатно
            пользоваться системой телематики. Также каждый клиент может
            подключить к сервису новые автомобили находящиеся в автопарке
            клиента.
            <br />
            <br />
            Настроив использование отчетов фирменного телематического сервиса
            различными службами (техническими, диспетчерскими, контроля
            качества, службой безопасности и т.п.), руководители автопарка и
            соответствующие службы получат регулярный поток надежных данных в
            режиме реального времени плюс оповещения ответственных сотрудников в
            случае незапланированной ситуации.
          </p>
        </div>
      </div>
      <div className={classes.CardBlocks}>
        {CARDS.map((item, index) => (
          <div className={classes.Card} key={index}>
            <p className="p1 uppercase">{item.title}</p>
            <p className="p2">{item.text}</p>
          </div>
        ))}
        <div className={classes.ImageBlock}>
          <picture>
            <img src={cardImg} />
          </picture>
        </div>
      </div>
    </div>
  );
};
