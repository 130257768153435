import classNames from "classnames";

import {
  GreenTelegramIcon,
  MailIcon,
  PhoneIcon,
  WhatsAppIcon,
} from "../../../../ui/_icons";

import classes from "./MainInfo.module.css";

interface MainInfoProps {
  className?: string;

  title?: string;
  description?: string;
  address?: string;
  phone?: string;
  email?: string;
  whatsapp?: string;
  telegram?: string;
  isSmall?: boolean;
}

export const MainInfo = ({
  className,

  title,
  description,
  address,
  phone,
  email,
  whatsapp,
  telegram,

  isSmall = false,
}: MainInfoProps) => {
  const showedPhone = phone?.split(",")[0];

  return (
    <div className={classNames(classes.Wrapper, className)}>
      {title ? <h2 className="h2">{title.replace(/\\n/g, "\n")}</h2> : <></>}
      {description ? (
        <h4 className="h4">{description.replace(/\\n/g, "\n")}</h4>
      ) : (
        <></>
      )}
      {address ? <p className="p1">{address.replace(/\\n/g, "\n")}</p> : <></>}
      <div className={classes.Contacts}>
        {phone ? (
          <a className={isSmall ? "p1" : "h3"} href={`tel:${phone}`}>
            {" "}
            <PhoneIcon /> {showedPhone}{" "}
          </a>
        ) : (
          <></>
        )}
        {email ? (
          <a className={isSmall ? "p1" : "h3"} href={`mailto:${email}`}>
            {" "}
            <MailIcon /> {email}{" "}
          </a>
        ) : (
          <></>
        )}
      </div>
      <div className={classes.Contacts}>
        {whatsapp ? (
          <a
            className={classNames(classes.Icon, isSmall ? "p1" : "h3")}
            href={whatsapp}
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            <WhatsAppIcon /> WhatsApp
          </a>
        ) : (
          <></>
        )}
        {telegram ? (
          <a
            className={isSmall ? "p1" : "h3"}
            href={telegram}
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            <GreenTelegramIcon /> Telegram
          </a>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
