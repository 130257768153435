import { PHONE } from "../../../../common/constants/contacts";

import classes from "./MobileContacts.module.css";

import { PhoneIcon } from "../../../../ui/_icons";

export const MobileContacts = () => {
  return (
    <div className={classes.Contacts}>
      <a href={`tel:${PHONE}`}>
        <span className={classes.Number}> {PHONE} </span>
        <PhoneIcon />
      </a>
    </div>
  );
};
