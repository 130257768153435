import classes from "./Brand.module.css";
import { Link } from "react-router-dom";
import { ArrowRightIcon } from "../../../../ui/_icons";

export interface BrandProps {
  title: string;
  link: string;
  img: string | undefined;
}

export const Brand = ({ title, link, img }: BrandProps) => {
  return (
    <div className={classes.Brand}>
      <Link className={classes.Link} to={link}>
        <picture className={classes.Logo}>
          <img src={img} alt={title} />
        </picture>
        <p className={classes.Title}>
          <span className="h4">{title}</span>
          <ArrowRightIcon />
        </p>
      </Link>
    </div>
  );
};
