import { useEffect, useState } from "react";
import { ModelsService } from "../api/ModelsService";
import { IModification } from "../interfaces/IModification";

export const useModifications = () => {
  const [modifications, setModifications] = useState<IModification[]>([]);

  useEffect(() => {
    const models = new ModelsService();

    models.getModifications().then((res) => {
      setModifications(res.data.data);
    });
  }, []);

  return modifications;
};
