import classes from "./OtherSpecialOffers.module.css";
import { ISpecialOffer } from "../../interfaces/ISpecialOffer";
import { Card } from "../../../../components/Card";

interface OtherSpecialOffersProps {
  offers: ISpecialOffer[];
}

export const OtherSpecialOffers = ({ offers }: OtherSpecialOffersProps) => {
  return (
    <aside className={classes.Wrapper}>
      <h4 className="h4">Другие предложения</h4>
      {offers.map((offer) => (
        <Card
          to={`/special-offers/${offer.attributes.slug}`}
          title={offer.attributes.title}
          key={offer.id}
        />
      ))}
    </aside>
  );
};
