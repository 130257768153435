import { Link } from "react-router-dom";

import classNames from "classnames";

import { IModel } from "../../interfaces/IModel";

import { renderMd } from "../../../../common/libs/renderMd";
import { getNumberWithSpaces } from "../../../../common/libs/getNumberWithSpaces";

import { PdfIcon } from "../../../../ui/_icons/PdfIcon";

import classes from "./AutoCardWithProperties.module.css";
import { RubleIcon } from "../../../../ui/_icons/RubleIcon";

type AutoCardWithPropertiesProps = IModel["attributes"];

export const AutoCardWithProperties = ({
  previewImage,
  previewTag,
  title,
  amount,
  slug,
  previewText,
  fileDownload,
}: AutoCardWithPropertiesProps) => {
  return (
    <div className={classes.Card}>
      <picture className={classNames(classes.LeftContent)}>
        <img src={previewImage?.data?.attributes.url} />
      </picture>
      <div className={classes.RightContent}>
        <div className={classes.Top}>
          <div className={classes.TopContent}>
            <h4 className="h4">{title}</h4>
            {amount && (
              <p className={classNames("p2", classes.Price)}>
                {`${getNumberWithSpaces(amount)}`}&nbsp;
                <RubleIcon />
              </p>
            )}
          </div>
          {previewTag && <p className={classes.Sale}>{previewTag}</p>}
        </div>

        {previewText && (
          <div className={classes.Properties}>
            <p
              className="p2"
              dangerouslySetInnerHTML={{
                __html: renderMd(previewText),
              }}
            />
          </div>
        )}

        <div className={classes.Buttons}>
          <Link to={`/models/${slug}`} className="btn block">
            Подробнее
          </Link>
          {fileDownload && (
            <a
              href={fileDownload.data?.attributes.url}
              className="btn block"
              target="_blank"
              rel="noreferrer"
            >
              <span className={classes.DownloadText}>Спецификации</span>
              <PdfIcon />
            </a>
          )}
        </div>
      </div>
    </div>
  );
};
