export const STOCK = [
  "Центральный склад запасных частей расположен на ближнем юго –восточном расстоянии от МКАД в МО в г. Томилино вблизи от основных транспортных развязок и в комфортном доступе до аэропортов г. Москвы и Подмосковья.",
  "На складе содержится более 98% деталей для обязательного технического обслуживания, а также большой ассортимент кузовных запасных частей, оптики и деталей для ремонта.",
  "Транспортно –логистический комплекс склада оснащен всеми характеристиками склада уровня А+, что позволяет на ответственном хранении у высокопрофессионального провайдера своевременно быстро производить подбор и отгрузку заказов.",
  "Бережное отношение к деталям, как поддерживание комнатной температуры круглый год, надежная упаковка при отгрузке создает надежную коммуникацию с клиентами на всех этапах реализации запасных частей.",
];

export const PARTS = [
  "Только оригинальные запасные части заводов-производителей, приобретенные у официальных дистрибьюторов на территории Российской Федерации.",
  "Идеально подходящие для конкретной модели автомобиля/автобуса, учитывая все технические особенности и требования. Все предлагаемые запасные части тщательно отбираются и проходят проверку и одобрение у наших инженеров.",
  "Высокий уровень качества, надежности и безопасности запасных частей, что особенно важно при покупке запасных частей.",
  "Опытные сотрудники и профессиональные консультации по подбору запасных частей.",
];
