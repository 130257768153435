import { useEffect, useState } from "react";

import { ContactsService } from "../api/ContactsService";

import { TContactsResponse } from "../types";

export const useContacts = () => {
  const [contacts, setContacts] = useState<TContactsResponse>();

  useEffect(() => {
    const contacts = new ContactsService();

    contacts.getContactPage().then((res) => {
      setContacts(res.data.data);
    });
  }, []);

  return contacts;
};
