import { useLayoutEffect, useRef, useState } from "react";
import classNames from "classnames";

import { Tabs } from "../../../../ui/Tabs";
import { Line } from "../../../../ui/Line";

import { useBreakpoint } from "../../../../common/hooks/useBreakpoint";

import { Search } from "../../../../components/Search";

import { Points } from "../Points";
import { YMap } from "../YMap";

import { useDealerships } from "../../hooks/useDealerships";

import { IDealership } from "../../interfaces/IDealership";

import classes from "./Dealership.module.css";

interface DealershipProps {
  title: string;
  brandSlug?: string | undefined;
  isWithLine?: boolean;
}

export const Dealership = ({
  title,
  brandSlug,
  isWithLine,
}: DealershipProps) => {
  const refList = useRef<HTMLUListElement>(null);
  const [searchValue, setSearchValue] = useState("");
  const [tab, setTab] = useState("Все");
  const [selected, setSelected] = useState<IDealership | null>(null);
  const [openMobileMap, setOpenMobileMap] = useState(false);
  const [selectedBrand] = useState<string | undefined>(brandSlug);
  const breakpoint = useBreakpoint();

  // const { brands } = useBrands();

  const { points, isLoaded } = useDealerships(
    tab === "Продажа",
    tab === "Сервис",
    selectedBrand,
    searchValue,
  );

  const isEmpty = !points.length && !searchValue;

  const handleTabClick = (tab: string) => {
    setTab(tab);
    setSelected(null);
  };

  useLayoutEffect(() => {
    if (refList.current && !!selected?.id) {
      const listItem = document.querySelector(
        `[data-id="${selected.id}"]`,
      ) as HTMLElement;
      if (listItem) {
        refList.current.scrollTop =
          listItem.offsetTop - (breakpoint === "mobile" ? 250 : 190);
      }
    }
  }, [selected]);

  return !isEmpty ? (
    <>
      {isWithLine ? <Line isWithMargin /> : <></>}
      <section className="container section">
        <h2 className={classNames("h2", classes.Title)}>{title}</h2>
        <div className={classes.Wrapper}>
          <aside
            className={classes.PointsAside}
            style={{ overflow: openMobileMap ? "hidden" : "auto" }}
          >
            <header className={classes.AsideTitle}>
              <h4 className="h4">Поиск дилера</h4>
              <button
                className="link"
                onClick={() => setOpenMobileMap(!openMobileMap)}
              >
                {openMobileMap ? "Список" : "Карта"}
              </button>
            </header>
            <Search setValue={setSearchValue} />
            {!!selected?.id && (
              <button
                className={classNames(classes.ResetButton, "link")}
                onClick={() => setSelected(null)}
              >
                Сбросить
              </button>
            )}
            <Points
              refList={refList}
              points={points}
              setSelected={setSelected}
              selected={selected}
            />
          </aside>
          {/* hide brand filter */}
          <div className={classes.Filters}>
            {/* {!brandSlug && (
              <Select
                placeholder="Бренд"
                value={selectedBrand}
                setValue={setSelectedBrand}
                options={getOptionsFromBrands(brands)}
              />
            )} */}
            <Tabs
              tabs={["Все", "Продажа", "Сервис"]}
              activeTab={tab}
              setTab={handleTabClick}
            />
          </div>
          {isLoaded && (
            <YMap
              points={points}
              openMobileMap={openMobileMap}
              selected={selected}
              setSelected={setSelected}
            />
          )}
        </div>
      </section>
    </>
  ) : (
    <></>
  );
};
