import { JSX, createElement } from "react";
import { Link } from "react-router-dom";

import classNames from "classnames";

import { ButtonElementType, ElementTypes } from "./types";

import classes from "./BorderCard.module.css";

export type BorderCardProps<C extends ButtonElementType> = {
  children: JSX.Element;
  component?: C;
  onClick?: () => void;
  fill?: boolean;
  sm?: boolean;
  isTransparent?: boolean;
} & ElementTypes[C];

export function BorderCard<C extends ButtonElementType>({
  className: initialClassName,
  children,
  component = "link" as C,
  sm,
  fill,
  isTransparent = true,
  ...rest
}: BorderCardProps<C>) {
  const baseComponent = component === "link" ? Link : component;

  const className = classNames(
    classes.Wrapper,
    fill ? classes.Fill : "",
    sm ? classes.Small : "",
    !isTransparent && classes.Background,
    initialClassName,
  );

  return createElement(
    baseComponent,
    {
      className,
      ...rest,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any,
    <>{children}</>,
  );
}
