import { JSX } from "react";
import classNames from "classnames";

import classes from "./NewHero.module.css";

import { IImgSrc } from "../../common/interfaces/IImgSrc";

interface NewHeroProps extends IImgSrc {
  title: string | JSX.Element;
  isFullText?: boolean;
}

export const NewHero = ({
  imgSm,
  imgMd,
  imgLg,
  imgXl,
  title,
  isFullText,
}: NewHeroProps) => {
  return (
    <div className={classNames("container")}>
      <div className={classes.Hero}>
        <picture className={classes.Bg}>
          {imgXl && <source media="(min-width: 1440px)" srcSet={imgXl} />}
          {imgLg && <source media="(min-width: 1024px)" srcSet={imgLg} />}
          {imgMd && <source media="(min-width: 768px)" srcSet={imgMd} />}
          <img src={imgSm} className="img-cover" alt="Background hero" />
          <div className={classes.Overlay} />
        </picture>
        <div className={classes.Content}>
          <h1
            className={classNames(
              "h1",
              classes.Title,
              isFullText && classes.FullText,
            )}
          >
            {title}
          </h1>
        </div>
      </div>
    </div>
  );
};
