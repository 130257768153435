import { useState, useEffect } from "react";

import { Breakpoints, MediaBreakpoints } from "../constants/media";

export const useBreakpoint = (): Breakpoints | undefined => {
  const [breakpoint, setBreakpoint] = useState<Breakpoints | undefined>(
    undefined,
  );
  const resize = () => {
    setBreakpoint(() => {
      if (window.innerWidth < MediaBreakpoints.tablet) return "mobile";
      if (window.innerWidth < MediaBreakpoints.laptop) return "tablet";
      if (window.innerWidth < MediaBreakpoints.desktop) return "laptop";
      return "desktop";
    });
  };

  useEffect(() => {
    resize();
    window.addEventListener("resize", resize);

    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);

  return breakpoint;
};
