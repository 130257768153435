import classes from "./TestTruck.module.css";
import img from "../../assets/test-drive.jpg";
import classNames from "classnames";
import { PHONE } from "../../../../common/constants/contacts";
// import { PHONE } from "../../../../common/constants/contacts";

export const TestTruck = () => {
  return (
    <section className={classNames("section container", classes.Section)}>
      <div className={classes.Content}>
        <p className={classNames(classes.SubTitle, `h2 uppercase`)}>
          Грузовой автомобиль <br />
          для вашего тест-драйва
        </p>
        <div className={classNames(classes.Text, `p2`)}>
          <p>
            Компания ООО «Лидер Трак энд Бас» предлагает лучшие комплексные
            решения на рынке Российской Федерации.
            <br /> Хотите убедиться в этом сами – возьмите грузовой автомобиль в
            тестовую эксплуатацию. <br />
            <br />
            Уточнить наличие и возможность тестирование техники можно у наших
            специалистов.
          </p>
          <a
            href={`tel:${PHONE}`}
            target="_blank"
            rel="noreferrer"
            className="link"
          >
            {PHONE}
          </a>
          <a
            href="mailto:sales@leader-trucks.ru"
            target="_blank"
            rel="noreferrer"
            className="link"
          >
            sales@leader-trucks.ru
          </a>
        </div>
      </div>
      <img className={classNames("img-cover", classes.Img)} src={img} />
    </section>
  );
};
