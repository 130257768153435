import { useEffect, useState } from "react";

import { promiseErrorHandle } from "../../../common/libs/promiseErrorHandle";

import { ServicesService } from "../api/ServicesService";

import { TServiceContent } from "../types";

export const useService = () => {
  const [serviceContent, setServiceContent] = useState<TServiceContent>();

  useEffect(() => {
    const contentResponse = new ServicesService();
    contentResponse
      .get()
      .then((res) => {
        setServiceContent(res.data.data);
      })
      .catch((e) => {
        promiseErrorHandle(e);
      });
  }, []);

  return { serviceContent };
};
