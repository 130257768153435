import { useEffect, useState } from "react";

import { promiseErrorHandle } from "../../../common/libs/promiseErrorHandle";

import { DealerGeographyService } from "../api/MainService";

import { TDealerGeographyContent } from "../types";

export const useDealerGeography = () => {
  const [geographyContent, setGeographyContent] =
    useState<TDealerGeographyContent>();

  useEffect(() => {
    const response = new DealerGeographyService();
    response
      .get()
      .then((res) => {
        setGeographyContent(res.data.data);
      })
      .catch((e) => {
        promiseErrorHandle(e);
      });
  }, []);

  return { geographyContent };
};
