import classes from "./TextWithImage.module.css";
import classNames from "classnames";
import { JSX } from "react";

interface TextWithImageProps {
  children: string | JSX.Element | JSX.Element[];
  img: string;
  title?: string;
  center?: boolean;
  isCustomMargin?: boolean;
}

export const TextWithImage = ({
  title,
  children,
  img,
  center,
  isCustomMargin,
}: TextWithImageProps) => {
  return (
    <section
      className={classNames(
        "container",
        classes.Section,
        center ? classes.Center : "",
        isCustomMargin ? "section-m" : "section",
      )}
    >
      <div
        className={classNames(
          "p2",
          isCustomMargin ? classes.TextBlock : classes.Text,
        )}
      >
        {title && <h2 className={classNames("h2", classes.Title)}>{title}</h2>}
        {children}
      </div>
      <img className={classes.Img} src={img} alt={title} />
    </section>
  );
};
