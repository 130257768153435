import { Layout } from "../modules/Layout";
import { Contacts } from "../modules/Contacts";
// import { BottomLinks } from "../../modules/BottomLinks";

export const ContactsPage = () => {
  return (
    <Layout>
      <Contacts />
      {/*<BottomLinks />*/}
    </Layout>
  );
};
