import classes from "./Select.module.css";
import { useRef } from "react";
import classNames from "classnames";
import { ChevronRightIcon } from "../_icons";
import { useOnClickOutside } from "../../common/hooks/useClickOutside";
import { ISelectOption } from "../../common/interfaces/ISelectOption";

interface SelectProps {
  placeholder: string;
  options: ISelectOption[];
  value: string | undefined;
  setValue: (v: string) => void;
  minHeight?: number;
}

export const Select = ({
  options,
  placeholder,
  value,
  setValue,
  minHeight,
}: SelectProps) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const { isShowing, setIsShowing } = useOnClickOutside(ref);

  return (
    <div className={classes.Wrapper} ref={ref}>
      <button
        role="combobox"
        type="button"
        className={classNames(
          "p1",
          classes.SelectBtn,
          isShowing ? classes.SelectedBtn : "",
        )}
        style={{ minHeight }}
        onClick={() => setIsShowing(!isShowing)}
      >
        {options.find((option) => option.value === value)?.name ?? placeholder}
        <ChevronRightIcon />
      </button>
      {isShowing && (
        <ul role="listbox" className={classes.List}>
          {options.map((option) => (
            <li
              key={option.value}
              value={option.value}
              role="option"
              className="p2"
              onClick={() => {
                setValue(option.value);
                setIsShowing(false);
              }}
            >
              {option.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
