export const SearchIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.3984 4.22083C7.53244 4.22083 4.39844 7.35483 4.39844 11.2208C4.39844 15.0868 7.53244 18.2208 11.3984 18.2208C15.2644 18.2208 18.3984 15.0868 18.3984 11.2208C18.3984 7.35483 15.2644 4.22083 11.3984 4.22083ZM2.39844 11.2208C2.39844 6.25026 6.42787 2.22083 11.3984 2.22083C16.369 2.22083 20.3984 6.25026 20.3984 11.2208C20.3984 16.1914 16.369 20.2208 11.3984 20.2208C6.42787 20.2208 2.39844 16.1914 2.39844 11.2208Z"
      fill="#FAFAFA"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.047 15.4567L22.8112 21.2209L21.397 22.6351L15.6328 16.8709L17.047 15.4567Z"
      fill="#FAFAFA"
    />
  </svg>
);
