import { Link } from "react-router-dom";

import { LeftArrowIcon } from "../../../../ui/_icons";

import { TMenuItem } from "../../types";

import classes from "./MobileSubList.module.css";

type MobileSubListProps = {
  items: TMenuItem;
  onButtonClick: () => void;
};

export const MobileSubList = ({ items, onButtonClick }: MobileSubListProps) => {
  return (
    <ul className={classes.List}>
      {items?.title && (
        <button className={classes.SubButton} onClick={onButtonClick}>
          <LeftArrowIcon />
          {items?.title}
        </button>
      )}
      {items?.items?.map((item) => (
        <li key={item.title}>
          <Link to={item.url || ""} className={classes.Item}>
            {item.title}
          </Link>
        </li>
      ))}
    </ul>
  );
};
