import classNames from "classnames";
import classes from "./LegalList.module.css";

export type ILigalList = {
  titles?: string[];
};

export const LegalList = (props: ILigalList) => {
  return (
    <ul className={classNames(classes.List, "p2 uppercase")}>
      {!!props.titles?.length &&
        props.titles?.map((title, index) => (
          <li key={title}>
            <a href={`#${index}`}>{title}</a>
          </li>
        ))}
    </ul>
  );
};
