import classes from "./FeedbackForm.module.css";
import { Input } from "../../../../ui/Input";
import { Checkbox } from "../../../../ui/Checkbox";
import { Field, Form, Formik, FieldProps } from "formik";
import MaskedInput from "react-text-mask";
import { bool, object, string } from "yup";
import { ERROR_MSGS } from "../../constants/error-messages";
import { FeedbackFormService } from "../../api/FeedbackFormService";
import { format } from "date-fns";
import { phoneNumberMask } from "../../constants/phoneNumberMask";
import { promiseErrorHandle } from "../../../../common/libs/promiseErrorHandle";
import { useState } from "react";
import classNames from "classnames";
import { Select } from "../../../../ui/Select";
import { CheckCheckIcon } from "../../../../ui/_icons/CheckCheckIcon";
import { useFormData } from "../../hooks/useFormData";
import { IQuestion } from "../../interfaces/IPostFormData";

function questionsFromApi(items: IQuestion[]) {
  return items.map((item) => {
    return item.value;
  });
}

interface FeedbackFormProps {
  title?: string;
}

export const FeedbackForm = ({ title: initialTitle }: FeedbackFormProps) => {
  const [success, setSuccess] = useState(false);
  const [fail, setFail] = useState(false);

  const mainResponse = useFormData();
  const { formContent } = mainResponse;
  if (!formContent) return;

  const validationSchema = object().shape({
    name: string().required(ERROR_MSGS.name),
    phone: string()
      .required(ERROR_MSGS.phone)
      .test("phone-check", ERROR_MSGS.phone, (v) => !v?.includes("_")),
    // confidencyAgreement: bool().oneOf([true]),
    processingAgreement: bool().oneOf([true]),
    question: string().required(),
  });

  const isProgress = !success && !fail;

  const title = initialTitle || formContent.attributes.title;

  const questions = questionsFromApi(formContent.attributes.questions);

  const handleFailButtonClick = () => {
    setFail(false);
  };

  return (
    <div className={classes.Wrapper}>
      <h2 className={classNames("h2", classes.Title)}>{isProgress && title}</h2>
      <div className={classes.RightBlock}>
        {fail && (
          <div className={classes["Fail-content"]}>
            <p className={classNames("h3", classes["Result-text"])}>
              Что-то пошло не так, заявка не отправлена.
            </p>
            <button
              className={classNames("btn", classes["Fail-button"])}
              type="button"
              onClick={handleFailButtonClick}
            >
              Заполнить форму повторно
            </button>
          </div>
        )}

        {success && (
          <div className={classes["Success-content"]}>
            <div className={classes["Success-check"]}>
              <CheckCheckIcon />
            </div>
            <p className={classNames("h3", classes["Result-text"])}>
              Спасибо! заявка отправлена.
              <br />
              Мы свяжемся с вами в ближайшее время
            </p>
          </div>
        )}

        {isProgress && (
          <Formik
            enableReinitialize={true}
            initialValues={{
              name: "",
              phone: "",
              // confidencyAgreement: false,
              processingAgreement: false,
              question: "",
            }}
            validationSchema={validationSchema}
            onSubmit={(values, formikHelpers) => {
              const form = new FeedbackFormService();

              form
                .post({
                  name: values.name,
                  phone: values.phone,
                  confidencyAgreement: "true",
                  processingAgreement: values.processingAgreement.toString(),
                  date: format(new Date(), "yyyy-MM-dd"),
                  question: values.question,
                })
                .then(() => {
                  formikHelpers.resetForm();
                  setSuccess(true);
                })
                .catch((e) => {
                  promiseErrorHandle(e);
                  setFail(true);
                });
            }}
          >
            {({ values, errors, touched, setFieldValue }) => (
              <Form className={classes.Form}>
                <Input touched={touched.name} error={errors.name}>
                  <Field
                    name="name"
                    placeholder={formContent?.attributes.namePlaceholder}
                  />
                </Input>
                <Input touched={touched.phone} error={errors.phone}>
                  <Field name="phone">
                    {({ field }: FieldProps) => (
                      <MaskedInput
                        {...field}
                        type="text"
                        guide
                        mask={phoneNumberMask}
                        placeholder="+_(___)-___-____"
                      />
                    )}
                  </Field>
                </Input>
                <Select
                  placeholder={formContent?.attributes.questionPlaceholder}
                  options={questions.map((question) => ({
                    name: question,
                    value: question,
                  }))}
                  value={values.question}
                  setValue={(v) => setFieldValue("question", v)}
                  minHeight={78}
                />
                {/*<Checkbox*/}
                {/*  error={*/}
                {/*    touched.confidencyAgreement*/}
                {/*      ? errors.confidencyAgreement*/}
                {/*      : undefined*/}
                {/*  }*/}
                {/*  text={*/}
                {/*    <>*/}
                {/*      Я согласен с <a>Политикой конфиденциальности</a>*/}
                {/*    </>*/}
                {/*  }*/}
                {/*>*/}
                {/*  <Field name="confidencyAgreement" type="checkbox" />*/}
                {/*</Checkbox>*/}
                <Checkbox
                  error={
                    touched.processingAgreement
                      ? errors.processingAgreement
                      : undefined
                  }
                  text={
                    <>
                      Я согласен с{" "}
                      <a href="/legal" target="_blank">
                        Обработкой персональных данных
                      </a>
                    </>
                  }
                >
                  <Field name="processingAgreement" type="checkbox" />
                </Checkbox>
                <button className="btn block" type="submit">
                  Отправить заявку
                </button>
              </Form>
            )}
          </Formik>
        )}
      </div>
    </div>
  );
};
