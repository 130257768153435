import classNames from "classnames";

import classes from "./Line.module.css";

type LineProps = {
  className?: string;
  isWithMargin?: boolean;
};

export const Line = ({ className, isWithMargin = false }: LineProps) => {
  return (
    <div className={classNames("container", isWithMargin && classes.Margin)}>
      <span className={classNames(classes.Line, className)} />
    </div>
  );
};
