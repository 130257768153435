export const ANALITICS_LIST = [
  "Контроль местоположения и движения по маршрутам",
  "Расчет и контроль движения топлива",
  "Эффективность и качество вождения",
  "Учет времени труда и отдыха водителей",
  "Контроль нарушений, связанных с эксплуатацией транспорта",
  "Ретрансляция данных в региональные навигационно-информационные системы (РНИС)",
  "Возможность настройки гостевого доступа (токена) для грузополучателя",
];

export const CARDS = [
  {
    title: "КОНТРОЛЬ МЕСТОПОЛОЖЕНИЯ И ДВИЖЕНИЯ ПО МАРШРУТАМ",
    text: "Диспетчеры получают возможность составлять маршруты для ТС и выставлять на маршрутах контрольные точки, которые автомобиль должен обязательно посетить. Все данные будут отображаться на карте в системе, а также в отчетах. При необходимости можно настроить оповещения об отклонениях от заданного маршрута или, например, о посещении контрольных точек. В итоге – повышение эффективности использования транспорта, сокращение времени простоя и нецелевого использования автомобилей, минимизация рисков угона транспорта и хищения груза.",
  },
  {
    title: "КОНТРОЛЬ ЭФФЕКТИВНОСТИ И КАЧЕСТВА ВОЖДЕНИЯ",
    text: "Контроль эффективности и качества вождения, а также отображаемые в системе данные тахографа о времени труда и отдыха водителей, помогают соответствующим службам выявить слабые места в вождении и использовании транспортных средств водителями, сократить расходы на штрафы и простои техники из-за незапланированного ремонта, выработать у водителей привычку соблюдать принципы экономичного вождения и в итоге - снизить расходы на содержание и обслуживание автопарка.",
  },
  {
    title: "КОНТРОЛЬ ТОПЛИВА",
    text: "Система отображает расход топлива двигателем, позволяет автоматически рассчитывать средневзвешенный расход топлива на различном транспорте, а также контролировать заправки. Расчет и контроль движения топлива в системе – инструмент, позволяющий разработать более точные топливные нормативы, и, соответственно, сократить расход топлива в целом по компании. Использование фирменной телематики от ООО «Лидер Трак энд Бас» приводит к повышению эффективности работы различных служб и подразделений транспортных компаний, поскольку управленческие решения принимаются на основе надежных и своевременных данных. В итоге повышается эффективность использования транспорта, оптимизируются эксплуатационные расходы и повышается безопасность автопарка в целом.",
  },
];
