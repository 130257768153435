import { useEffect, useState } from "react";

import { promiseErrorHandle } from "../../../common/libs/promiseErrorHandle";

import { IFormData } from "../interfaces/IPostFormData";
import { FeedbackFormFieldsService } from "../api/FeedbackFormService";

export const useFormData = () => {
  const [formContent, setFormContent] = useState<IFormData>();

  useEffect(() => {
    const formContentResponse = new FeedbackFormFieldsService();
    formContentResponse
      .get()
      .then((res) => {
        setFormContent(res.data.data);
      })
      .catch((e) => {
        promiseErrorHandle(e);
      });
  }, []);

  return { formContent };
};
