import classes from "./Tabs.module.css";
import classNames from "classnames";

interface TabsProps {
  tabs: string[];
  activeTab: string;
  setTab: (v: string) => void;
}

export const Tabs = ({ tabs, setTab, activeTab }: TabsProps) => {
  return (
    <ul className={classes.Tabs}>
      {tabs.map((tab) => (
        <li key={tab}>
          <button
            className={classNames("tab", tab === activeTab ? "active" : "")}
            onClick={() => setTab(tab)}
          >
            {tab}
          </button>
        </li>
      ))}
    </ul>
  );
};
