import { apiInstance } from "../../../core/apiInstance";

export class ModelsService {
  private BASE_URL = "/models";

  async getByBrandSlug(brandSlug: string, modificationId?: number) {
    const filterBrand = brandSlug ? `&filters[brands][slug]=${brandSlug}` : "";
    const filterModificationIdId =
      modificationId !== undefined
        ? `&filters[modifications][id]=${modificationId}`
        : "";

    return apiInstance.get(
      `${this.BASE_URL}?populate=*${filterBrand}${filterModificationIdId}`,
    );
  }

  async getWithFilters(
    page: number,
    pageSize: number,
    brandsId: string[],
    types: string[],
    modifications: string[],
  ) {
    const filtersQuery = [
      { key: "brands", ids: brandsId },
      { key: "auto_type", ids: types },
      { key: "modifications", ids: modifications },
    ] // ключи по полям модели для фильтрации
      .map((item) =>
        item.ids.map((id) => ({
          start: `filters[$or][`, // разделение на start и end необходимо, чтобы добавить между ними индекс
          end: `][${item.key}][id][$eq]=${id}`,
        })),
      )
      .reduce((a, b) => [...a, ...b]) // объединяем массив массивов в один массив
      .map((item, idx) => `${item.start}${idx}${item.end}`)
      .join("&");

    return apiInstance.get(
      `${this.BASE_URL}?populate=*&pagination[pageSize]=${
        pageSize ?? ""
      }&pagination[page]=${page ?? ""}&${filtersQuery}`,
    );
  }

  async getById(id: string) {
    return apiInstance.get(`${this.BASE_URL}/${id}?populate=*`);
  }

  async getBySlug(slug: string) {
    return apiInstance.get(`${this.BASE_URL}?populate=*&filters[slug]=${slug}`);
  }

  async getTechnicalParameters() {
    return apiInstance.get(`/technical-parameters?populate=*`);
  }

  async getModifications() {
    return apiInstance.get(`/modifications?populate=*`);
  }

  async getAutoTypes() {
    return apiInstance.get(`/auto-types?populate=*`);
  }

  async getTthGroupById(id: string) {
    return apiInstance.get(
      `${this.BASE_URL}/${id}?populate[tthGroup][populate]=*`,
    );
  }
}
