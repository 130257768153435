import classes from "./NewsPreviewCard.module.css";
import { INew } from "../../interfaces/INew";
import classNames from "classnames";
import { getFormatDate } from "../../libs/getFormatDate";
import { Link } from "react-router-dom";
import { renderMd } from "../../../../common/libs/renderMd";

interface NewsCardProp {
  newItem: INew;
  sm?: boolean;
}

export const NewsPreviewCard = ({ newItem, sm }: NewsCardProp) => {
  return (
    <Link
      to={`/news/${newItem.attributes.slug}`}
      className={classNames(classes.New, sm ? classes.Small : "")}
    >
      <picture className={classes.Picture}>
        <img
          src={newItem.attributes.previewImage.data?.attributes.url}
          className="img-cover"
        />
      </picture>
      <div className={classes.Bottom}>
        <p className={classNames("p2", classes.Date)}>
          {getFormatDate(newItem.attributes.date)}
        </p>
        <h4 className="h4">{newItem.attributes.title}</h4>
        {sm && newItem.attributes.description && (
          <p
            className={classNames("p2", classes.Description)}
            dangerouslySetInnerHTML={{
              __html: renderMd(
                `${newItem.attributes.description.slice(0, 100)}...`,
              ),
            }}
          />
        )}
      </div>
    </Link>
  );
};
