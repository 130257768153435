export const ChevronRightIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M17.7141 12.0995C17.7141 12.5495 17.5361 12.9725 17.2141 13.2885L7.21711 23.0745L6.28711 22.1245L16.2831 12.3395C16.4141 12.2095 16.4151 11.9905 16.2821 11.8595L6.28711 2.07546L7.21711 1.12446L17.2131 10.9095C17.5371 11.2265 17.7141 11.6495 17.7141 12.0995Z"
      fill="#CFD04C"
    />
  </svg>
);
