import { ErrorBlock } from "../components/ErrorBlock";
import { Layout } from "../modules/Layout";

export const NotFoundPage = () => {
  return (
    <Layout>
      <ErrorBlock
        text={
          <>
            Извините, такой страницы <span>&nbsp;не существует</span>
          </>
        }
        code={404}
      />
    </Layout>
  );
};
