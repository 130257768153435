import { Link } from "react-router-dom";

import classNames from "classnames";

// import { NAVIGATION } from "../../../common/constants/navigation";
import { ArrowRightIcon } from "../../../ui/_icons";

// import img from "../../assets/about.png";
// import imgMd from "../../assets/aboutMd.png";
// import imgSm from "../../assets/aboutSm.png";

// import imgSmall from "../../assets/working-small.png";

import classes from "./NewAboutSection.module.css";

type NewAboutSectionProps = {
  title?: string;
  text?: string;
  primaryImage?: string;
  secondaryImage?: string;
  buttonText?: string;
  buttonLink?: string;
};

export const NewAboutSection = ({
  title,
  text,
  primaryImage,
  secondaryImage,
  buttonText,
  buttonLink,
}: NewAboutSectionProps) => {
  return (
    <section className={classNames("container section", classes.Section)}>
      <div className={classNames("container", classes.Wrapper)}>
        <div className={classes.Text}>
          {title ? <h2 className="h2">{title}</h2> : <></>}
          <div>
            <p className="p2">{(text || "").replace(/\\n/g, "\n")}</p>
            <Link
              className={classNames("link", classes.Link)}
              to={buttonLink || ""}
            >
              {buttonText} <ArrowRightIcon />
            </Link>
          </div>
        </div>
        <div className={classes.Images}>
          <picture>
            {/* <source media="(min-width: 1024px)" srcSet={img} />
            <source media="(min-width: 768px)" srcSet={imgMd} /> */}

            {/* <img src={imgSm} /> */}
            <img src={primaryImage} />
          </picture>
          <picture>
            {/* <img src={imgSmall} /> */}
            <img src={secondaryImage} />
          </picture>
        </div>
      </div>
    </section>
  );
};
