import classes from "./LinkCard.module.css";
import {
  BorderCard,
  BorderCardProps,
  ButtonElementType,
} from "../../../../ui/BorderCard";
import { LinkIcon } from "../../../../ui/_icons/LinkIcon";

type LinkCardProps<C extends ButtonElementType> = {
  title?: string;
} & Omit<BorderCardProps<C>, "children">;

export function LinkCard<C extends ButtonElementType>({
  title,
  ...borderCardProps
}: LinkCardProps<C>) {
  return (
    <BorderCard {...(borderCardProps as BorderCardProps<C>)}>
      <>
        <div className={classes.Icon}>
          <LinkIcon />
        </div>
        <h4 className="h4">{title}</h4>
      </>
    </BorderCard>
  );
}
