import { useState } from "react";

import classNames from "classnames";

import { IGroupsTTH } from "../../interfaces/IGroupsTTH";

import { PlusIcon } from "../../../../ui/_icons/PlusIcon";
import { MinusIcon } from "../../../../ui/_icons/MinusIcon";

import classes from "./ModelCharacteristicsCard.module.css";

export const ModelCharacteristicsCard = ({
  dataInfo,
  isClosed = true,
}: {
  dataInfo: IGroupsTTH;
  isClosed?: boolean;
}) => {
  const [close, setClose] = useState(isClosed);

  return dataInfo.tthParams.length ? (
    <div className={classes.Wrap}>
      <button
        className={classes.Headline}
        onClick={() => (!close ? setClose(true) : setClose(false))}
      >
        <div className={classes.Title}>
          <img src={dataInfo.icon.data?.attributes.url} />
          <h4 className="h4">{dataInfo.title}</h4>
        </div>
        <div className={classes.Button}>
          {close ? <PlusIcon /> : <MinusIcon />}
        </div>
      </button>
      {!close && (
        <div className={classNames(classes.Info)}>
          {dataInfo.tthParams[0] && (
            <div className={classes.Card}>
              {dataInfo.tthParams.map(
                (item, index) =>
                  index % 2 === 0 && (
                    <div className={classes.CardCharacteristic} key={index}>
                      <p className="p2">{item.title}</p>
                      <p className="p2">{item.value}</p>
                    </div>
                  ),
              )}
            </div>
          )}
          {dataInfo.tthParams[1] && (
            <div className={classes.Card}>
              {dataInfo.tthParams.map(
                (item, index) =>
                  index % 2 !== 0 && (
                    <div className={classes.CardCharacteristic} key={index}>
                      <p className="p2">{item.title}</p>
                      <p className="p2">{item.value}</p>
                    </div>
                  ),
              )}
            </div>
          )}
        </div>
      )}
    </div>
  ) : null;
};
