import classes from "./Section.module.css";
import { Card } from "../../../../components/Card";
import { ISpecialOffer } from "../../interfaces/ISpecialOffer";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { ArrowRightIcon } from "../../../../ui/_icons";

interface SectionProps {
  specialOffers: ISpecialOffer[];
  title: string;
  to: string;
  img: string;
  guarantee?: boolean;
}

export const Section = ({
  specialOffers,
  title,
  to,
  img,
  guarantee,
}: SectionProps) => {
  return (
    <section className="container section">
      <h2 className={classNames("h2 center", classes.Title)}>{title}</h2>
      <div
        className={classNames(
          classes.Cards,
          guarantee ? classes.GuaranteeCards : "",
          classes[`Cards-${specialOffers.length}`],
        )}
      >
        {specialOffers.map((specialOffer) => (
          <Card
            key={specialOffer.id}
            img={guarantee ? null : specialOffer.attributes.previewImage.data}
            to={`/special-offers/${specialOffer.attributes.slug}`}
            title={specialOffer.attributes.title}
          />
        ))}
        <Link to={to} className={classes.Link}>
          <picture className={classes.Picture}>
            <img src={img} className="img-cover" alt={title} />
          </picture>
          <span
            className={classNames("icon-link p1 uppercase", classes.Button)}
          >
            Перейти <ArrowRightIcon />
          </span>
        </Link>
      </div>
    </section>
  );
};
