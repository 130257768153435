import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useBrandBySlug } from "../../modules/Brands";
import { Dealership } from "../../modules/Dealership";
import { Layout } from "../../modules/Layout";
import { BrandProducts } from "../../modules/Brands/components/BrandProducts";
import { TernaryBrandInfo } from "../../modules/Brands/components/TernaryBrandInfo";

export const BrandPage = () => {
  const navigate = useNavigate();

  const { slug } = useParams();
  const { brand } = useBrandBySlug(slug);

  useEffect(() => {
    if (brand === null) return navigate("/404");
  }, [brand]);

  return brand ? (
    <Layout>
      <BrandProducts
        title={brand.attributes.brandName}
        brandSlug={slug || ""}
      />
      <TernaryBrandInfo {...brand.attributes} />
      <Dealership
        title="Дилеры ЛИДЕР ТРАК ЭНД БАС"
        brandSlug={slug}
        isWithLine
      />
    </Layout>
  ) : null;
};
