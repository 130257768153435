import classes from "./ModelCharacteristicSection.module.css";
import classNames from "classnames";
// import { EngineIcon } from "../../../../ui/_icons/EngineIcon";
import { ModelCharacteristicsCard } from "../ModelCharacteristicsCard";
import { PdfIcon } from "../../../../ui/_icons/PdfIcon";
import { IModel } from "../../interfaces/IModel";
import { useTthGroup } from "../../hooks/useTthGroup";

export const ModelCharacteristicSection = ({
  model,
  id,
}: {
  model: IModel;
  id?: string;
}) => {
  const parameters = useTthGroup(id);

  return parameters ? (
    <section className={classNames("section container", classes.Section)}>
      <h2 className="h2">технические характеристики</h2>
      <div className={classes.Links}>
        {model.attributes.fileDownload.data && (
          <a
            className={classNames("p2 uppercase", classes.Link)}
            href={model.attributes.fileDownload.data.attributes.url}
            target="_blank"
            rel="noreferrer"
          >
            скачать
            <div className={classes.PdfIcon}>
              <PdfIcon />
            </div>
          </a>
        )}
      </div>
      <div className={classes.Cards}>
        {parameters.map((item, index) => (
          <ModelCharacteristicsCard
            dataInfo={item}
            key={item.id}
            isClosed={index !== 0}
          />
        ))}
      </div>
    </section>
  ) : null;
};
