import { NAVIGATION, TNavigation } from "../../../common/constants/navigation";

export const bottomLinks: TNavigation[] = [
  NAVIGATION.contacts,
  {
    title: "География дилеров",
    link: NAVIGATION.dealerMap.link,
  },
  NAVIGATION.models,
];
