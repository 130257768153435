import classNames from "classnames";
import classes from "./JobSection.module.css";
import { IContent } from "../../../../modules/JobSection/types";
import { MarkdownRenderer } from "../../../../components/MarkdownRenderer/MarkdownRenderer";

interface IJobSectionProps {
  data: IContent;
}

export const JobSection = ({ data }: IJobSectionProps) => {
  return (
    <section className="section container">
      <div className={classNames(classes.TopBlock, "section-m")}>
        <div className={classes.TopText}>
          <h3 className="h3">{data.title}</h3>
          <MarkdownRenderer>{data.primaryText}</MarkdownRenderer>
        </div>
        <picture>
          <source
            media="(min-width: 1440px)"
            srcSet={data.primaryImage?.data.attributes.url}
          />
          <source
            media="(min-width: 1024px)"
            srcSet={data.primaryImage?.data.attributes.url}
          />
          <img src={data.primaryImage?.data.attributes.url} />
        </picture>
      </div>
      <div className={classes.BottomBlock}>
        <picture>
          <source
            media="(min-width: 1440px)"
            srcSet={data.secondaryImage?.data.attributes.url}
          />
          <img src={data.secondaryImage?.data.attributes.url} />
        </picture>
        <MarkdownRenderer className={classes.BottomText}>
          {data.secondaryText}
        </MarkdownRenderer>
      </div>
    </section>
  );
};
