import classNames from "classnames";

import { Modal } from "../../components/Modal";

import { WithClosableProps } from "../../common/modal";

import classes from "./RequisitesModal.module.css";

export type TRequisite = {
  id: number;
  title: string;
  description: string;
};

interface RequisitesModalProps extends WithClosableProps {
  title: string;
  requisites: TRequisite[];
}

export const RequisitesModal = ({
  requisites,
  title,
  ...rest
}: RequisitesModalProps) => {
  return (
    <Modal
      closeClassName={classes.Close}
      containerClassName={classes.ModalContainer}
      {...rest}
    >
      <h3 className={classNames(classes.Title, "h3")}>{title}</h3>
      <ul className={classes.Wrapper}>
        {requisites.length ? (
          requisites.map((item) => (
            <li key={item.id}>
              <span className={classNames(classes.Key, "p1")}>
                {item.title.replace(/\\n/g, "\n")}
              </span>
              <span className={classNames(classes.Value, "h4")}>
                {item.description.replace(/\\n/g, "\n")}
              </span>
            </li>
          ))
        ) : (
          <></>
        )}
      </ul>
    </Modal>
  );
};
