import { NewHero } from "../../components/NewHero";
import { BottomLinks } from "../../modules/BottomLinks";
import { Layout } from "../../modules/Layout";
import { GuaranteeSection } from "./components/GuaranteeSection";
import imgXl from "./assets/guarantee-hero.png";
import imgLg from "./assets/guarantee-hero-1024.png";
import imgMd from "./assets/guarantee-hero-768.png";
import imgSm from "./assets/guarantee-hero-320.png";
import { Line } from "../../ui/Line";

export const GuaranteePage = () => {
  return (
    <Layout>
      <NewHero
        title={"Гарантия"}
        imgSm={imgSm}
        imgMd={imgMd}
        imgLg={imgLg}
        imgXl={imgXl}
      />
      <Line />
      <GuaranteeSection />
      <BottomLinks />
    </Layout>
  );
};
