import { useLayoutEffect } from "react";

export const useLockBodyScroll = (isLocked = true): void => {
  useLayoutEffect((): (() => void) => {
    const originalOverflow = "initial";
    document.body.style.overflow = isLocked ? "hidden" : originalOverflow;
    return () => {
      document.body.style.overflow = originalOverflow;
    };
  }, [isLocked]);
};
