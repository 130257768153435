import { useState, useEffect } from "react";
import { ModelsService } from "../api/ModelsService";
import { IGroupsTTH } from "../interfaces/IGroupsTTH";

export const useTthGroup = (id: string | undefined) => {
  const [data, setData] = useState<IGroupsTTH[] | undefined | null>();

  useEffect(() => {
    if (id) {
      const model = new ModelsService();
      model
        .getTthGroupById(id)
        .then((res) => {
          setData(res.data.data.attributes.tthGroup);
        })
        .catch(() => {
          setData(null);
        });
    }
  }, [id]);
  return data;
};
