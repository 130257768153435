import classes from "./Points.module.css";
import { IDealership } from "../../interfaces/IDealership";
import classNames from "classnames";
import { MutableRefObject } from "react";

interface PointsProps {
  refList: MutableRefObject<HTMLUListElement | null>;
  points: IDealership[];
  setSelected: (v: IDealership | null) => void;
  selected: IDealership | null;
}

export const Points = ({
  points,
  setSelected,
  selected,
  refList,
}: PointsProps) => {
  const handlePointClick = (point: IDealership) => {
    setSelected(point);
  };

  return (
    <ul className={classes.List} ref={refList}>
      {points.map((point) => (
        <li
          className={classNames(
            classes.Item,
            selected?.id === point.id ? classes.Selected : "",
          )}
          data-id={point.id}
          key={point.id}
          onClick={() => handlePointClick(point)}
        >
          <h6 className={"p2"}>{point.attributes.title}</h6>
          <p className="p4">{point.attributes.address}</p>
          {point.attributes.website && (
            <a
              className="p4"
              href={point.attributes.website}
              target="_blank"
              rel="noreferrer"
            >
              {point.attributes.website}
            </a>
          )}

          {point.attributes.email && (
            <a className="p4" href={`mailto:${point.attributes.email}`}>
              {point.attributes.email}
            </a>
          )}

          {point.attributes.phone && (
            <a className="p4" href={`tel:${point.attributes.phone}`}>
              {point.attributes.phone}
            </a>
          )}
        </li>
      ))}
    </ul>
  );
};
