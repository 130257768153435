import { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

import { Contacts } from "../Contacts";

import { ArrowDownIcon, LeftArrowIcon } from "../../../../ui/_icons";

import { useOnClickOutside } from "../../../../common/hooks/useClickOutside";

import classes from "./DesktopMenu.module.css";
import { CommonContext } from "../../../../contexts/CommonContext";

import { TMenuItem } from "../../types";

type DesktopMenuProps = {
  isHeaderHidden: boolean;
};

export const DesktopMenu = ({ isHeaderHidden }: DesktopMenuProps) => {
  const [selectedMenuItem, setSelectedMenuItem] = useState<{
    title: string;
    list: TMenuItem[];
    subList?: TMenuItem;
  }>();

  const [commonValue] = useContext(CommonContext);

  const ref = useRef<HTMLDivElement | null>(null);
  const { isShowing, setIsShowing } = useOnClickOutside(ref);

  const list = selectedMenuItem?.subList?.items || selectedMenuItem?.list || [];

  const handleItemClick = (item: TMenuItem) => {
    setSelectedMenuItem((prev) => {
      if (item.title === prev?.title) return undefined;

      return {
        title: item.title,
        list: item.items!,
        subList: undefined,
      };
    });
  };

  const handleSubItemClick = (item: TMenuItem) => {
    setSelectedMenuItem((prev) => {
      if (!prev) return;
      return {
        ...prev,
        subList: item,
      };
    });
  };

  const handleSubItemBackClick = () => {
    setSelectedMenuItem((prev) => {
      if (!prev) return;
      return {
        ...prev,
        subList: undefined,
      };
    });
  };

  useEffect(() => {
    if (!isShowing || isHeaderHidden) setSelectedMenuItem(undefined);
  }, [isShowing, isHeaderHidden]);

  return (
    <nav className={classes.Nav} ref={ref} onClick={() => setIsShowing(true)}>
      <ul className={classNames(classes.List, "p2")}>
        {commonValue.menu.map((item) => (
          <li key={item.title}>
            {item.url ? (
              <Link to={item.url}>{item.title}</Link>
            ) : (
              <button
                className={classes.MenuButton}
                onClick={() => handleItemClick(item)}
                data-selected={selectedMenuItem?.title === item.title}
              >
                {item.title}
                <ArrowDownIcon />
              </button>
            )}
          </li>
        ))}
      </ul>
      {selectedMenuItem && (
        <div className={classes.SubListWrapper}>
          <div className={classNames(classes.SubList, "p2", "container")}>
            {selectedMenuItem?.subList?.title && (
              <button
                className={classes.SubButton}
                onClick={handleSubItemBackClick}
              >
                <LeftArrowIcon />
                {selectedMenuItem?.subList?.title}
              </button>
            )}
            <ul>
              {list.map((item) => (
                <li key={item.title} className={classes.SubItem}>
                  {item.url ? (
                    <Link to={item.url}>{item.title}</Link>
                  ) : (
                    <button onClick={() => handleSubItemClick(item)}>
                      {item.title}
                    </button>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
      <div className={classes.Contacts}>
        <Contacts />
      </div>
    </nav>
  );
};
