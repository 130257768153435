import classes from "./CarouselNavigation.module.css";
import classNames from "classnames";
import { ArrowRightIcon } from "../../ui/_icons";

export const CarouselNavigation = () => {
  return (
    <div className={classNames(classes.Navigation, "navigation")}>
      <button className="prev">
        <ArrowRightIcon />
      </button>
      <button className="next">
        <ArrowRightIcon />
      </button>
    </div>
  );
};
