import { Layout } from "../../modules/Layout";
import { useParams } from "react-router-dom";
import { SpecialOfferContent } from "../../modules/SpecialOffers/components/SpecialOfferContent";

export const SpecialOfferPage = () => {
  const { id } = useParams();

  return (
    <Layout>
      <SpecialOfferContent id={id} />
    </Layout>
  );
};
