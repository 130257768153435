import { useEffect, useState } from "react";
import classNames from "classnames";

import { useModal } from "../../../common/modal";

import { Line } from "../../../ui/Line";

import { LinkCard } from "../../BottomLinks/components/LinkCard";
import { RequisitesModal } from "../../RequisitesModal/RequisitesModal";

import { MainInfo } from "./MainInfo";
import { OfficesMap } from "./OfficesMap";

import { TOffice } from "../types";

import { useContacts } from "../hooks/useContacts";

import classes from "./Contacts.module.css";

export const Contacts = () => {
  const [selectedOffice, setSelectedOffice] = useState<TOffice>();
  const [mappedOffices, setMappedOffices] = useState<TOffice[]>();

  const contactsResponse = useContacts();
  const contacts = contactsResponse?.attributes;

  const [openModal] = useModal(RequisitesModal);

  const officesOnMap = [
    {
      title: "Офис компании",
      address: contacts?.address,
      latitude: contacts?.latitude,
      longitude: contacts?.longitude,
    },
    ...(mappedOffices || []),
  ];

  const handleOpenModal = () => {
    openModal({
      title: contacts?.modalTitle || "",
      requisites: contacts?.requisites || [],
      onClose: () => {},
    });
  };

  const handleButtonClick = (value: TOffice) => {
    setTimeout(() => {
      document?.getElementById("officesMap")!.scrollIntoView({
        behavior: "smooth",
      });
    });

    handleOfficeChange(value);
  };

  const handleOfficeChange = (value: TOffice) => {
    setSelectedOffice(value);
  };

  useEffect(() => {
    if (!contacts?.offices.data?.length) return;

    setMappedOffices(() => {
      const officesResponse = contacts?.offices.data;
      return officesResponse.map((item) => item.attributes);
    });
  }, [contacts]);

  return (
    <section className={classNames("section", classes.Section)}>
      <div className="container">
        {contacts?.title ? (
          <h2 className={classNames("h2", classes.Title)}>{contacts?.title}</h2>
        ) : (
          <></>
        )}

        <MainInfo
          className={classes.MainInfo}
          description={contacts?.description}
          phone={contacts?.phone}
          email={contacts?.email}
          whatsapp={contacts?.whatsapp}
          telegram={contacts?.telegram}
        />

        <LinkCard
          className={classes.RequisitesButton}
          title={contacts?.buttonText?.replace(/\\n/g, "\n")}
          component="button"
          isTransparent={false}
          onClick={handleOpenModal}
        />

        {mappedOffices?.length ? (
          <ul className={classes.List}>
            {mappedOffices.map((item) => (
              <li key={item.title}>
                <MainInfo isSmall {...item} />
                <button
                  className={classNames("btn", classes.Button)}
                  type="button"
                  onClick={() => handleButtonClick(item)}
                >
                  Смотреть на карте
                </button>
              </li>
            ))}
          </ul>
        ) : (
          <></>
        )}
      </div>

      {officesOnMap?.length ? (
        <>
          <Line className={classes.Line} isWithMargin />
          <div id="officesMap" className="container">
            <OfficesMap
              title="Наши адреса"
              selectedOffice={selectedOffice}
              offices={officesOnMap}
              onClick={handleOfficeChange}
            />
          </div>
        </>
      ) : (
        <></>
      )}
    </section>
  );
};
