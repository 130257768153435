import classes from "./RepairParts.module.css";
import { List } from "../../../../ui/List";
import { PARTS, STOCK } from "../../constants/repairParts";
import classNames from "classnames";

import img1 from "../../assets/repair-1.jpg";
import img3 from "../../assets/repair-3.jpg";
import img4 from "../../assets/repair-4.jpg";

export const RepairParts = () => {
  return (
    <div className={classes.Section}>
      <section className={classNames("container", classes.TextSection)}>
        <div className={classes.Main}>
          <h4 className="h4">
            Компания ООО «Лидер Трак энд Бас» имеет собственный центральный
            склад запасных частей для оснащения своих сервисных станций и
            сервисных станций своих дилеров на территории РФ.
          </h4>
          <List list={STOCK} />
        </div>
        <picture className={classes.Img}>
          <img src={img1} />
        </picture>
      </section>
      <div className={classNames(classes.BottomSide, "container section")}>
        <picture>
          <img src={img3} className="img-cover" />
        </picture>
        <div className={classes.BottomText}>
          <p className="p2">Запасные части от ООО «Лидер Трак энд Бас» это:</p>
          <List list={PARTS} />
          <p className="p2">
            Поставка запасных частей с центрального склада в г. Томилино до
            официальных сервисных мастерских компании в Москве и
            Санкт-Петербурге выполняется два раза в день, а в регионы силами
            транспортной компанией запасные части уезжают каждый день, что
            помогает обеспечивать СТО всеми необходимыми деталями для планового
            ремонта и обслуживания техники FAW.
          </p>
        </div>
        <picture>
          <img src={img4} className="img-cover" />
        </picture>
        <p className="p2">
          Все это обеспечивает высокий уровень Коэффициента Технической
          Готовности (КТГ) транспортных средств клиентов, который характеризует
          степень готовности технически исправного грузового автотранспорта
          выполнять перевозки.
          <br />
          <br /> Учитывая качество и надежность оригинальной продукции или
          продукции одобренных производителей в долгосрочной перспективе
          наиболее экономично.
          <br />
          <br /> Качественные детали – это увеличенный ресурс дорогих агрегатов.
          Наши детали уменьшают простои транспорта, тем самым повышается уровень
          КТГ.
          <br />
          <br /> Использование оригинальных запчастей также положительно
          сказывается на стоимости автомобиля при продаже на вторичном рынке.
        </p>
      </div>
    </div>
  );
};
