import { useLayoutEffect, useRef } from "react";
import classNames from "classnames";

import { Points } from "../Points";
import { YMap } from "../YMap";

import { TOffice } from "../../types";

import { useBreakpoint } from "../../../../common/hooks/useBreakpoint";

import classes from "./OfficesMap.module.css";

interface OfficesMapProps {
  className?: string;

  title: string;
  selectedOffice?: TOffice;
  offices: TOffice[];
  onClick: (value: TOffice) => void;
}

export const OfficesMap = ({
  className,

  title,
  selectedOffice,
  offices,
  onClick,
}: OfficesMapProps) => {
  const refList = useRef<HTMLUListElement>(null);
  const breakpoint = useBreakpoint();

  useLayoutEffect(() => {
    if (refList.current && !!selectedOffice?.title) {
      const listItem = document.querySelector(
        `[data-id="${selectedOffice.title}"]`,
      ) as HTMLElement;
      if (listItem) {
        refList.current.scrollTop =
          listItem.offsetTop - (breakpoint === "mobile" ? 250 : 190);
      }
    }
  }, [selectedOffice]);

  return (
    <div className={classNames(classes.Section, className)}>
      {title ? (
        <h2 className={classNames(classes.Title, "h2")}>{title}</h2>
      ) : (
        <></>
      )}

      <div className={classes.Wrapper}>
        <aside className={classes.Aside}>
          <Points
            refList={refList}
            selectedOffice={selectedOffice}
            offices={offices}
            onClick={onClick}
          />
          {selectedOffice ? (
            <a
              className={classNames("btn", classes.Button)}
              href={`https://maps.yandex.ru?ll=${selectedOffice.longitude},${selectedOffice.latitude}&mode=routes&rtext=~${selectedOffice.latitude},${selectedOffice.longitude}&rtt=mt&ruri=~&z=10`}
              target="_blank"
              rel="noreferrer"
            >
              Как добраться
            </a>
          ) : (
            <></>
          )}
        </aside>
        <YMap
          selectedOffice={selectedOffice}
          offices={offices}
          onClick={onClick}
        />
      </div>
    </div>
  );
};
