import { useEffect, useState } from "react";
import { BrandService } from "../api/BrandService";
import { IBrand } from "../interfaces/IBrand";
import { promiseErrorHandle } from "../../../common/libs/promiseErrorHandle";

export const useBrands = () => {
  const [brands, setBrands] = useState<IBrand[]>([]);

  useEffect(() => {
    const brands = new BrandService();
    brands
      .get()
      .then((res) => {
        setBrands(res.data.data);
      })
      .catch((e) => {
        promiseErrorHandle(e);
      });
  }, []);

  return { brands };
};

export const useBrandBySlug = (slug?: string | undefined) => {
  const [brand, setBrand] = useState<IBrand | undefined | null>();

  useEffect(() => {
    const brands = new BrandService();
    if (slug) {
      brands
        .getBySlug(slug)
        .then((res) => {
          setBrand(res.data.data[0] ?? null);
        })
        .catch((e) => {
          promiseErrorHandle(e);
        });
    }
  }, [slug]);

  return { brand };
};
