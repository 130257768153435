import classes from "./Card.module.css";
import { IImage } from "../../common/interfaces/IImage";
import { Link } from "react-router-dom";
import { ArrowRightIcon } from "../../ui/_icons";

interface CardProps {
  title: string;
  to: string;
  img?: IImage | null;
}

export const Card = ({ img, title, to }: CardProps) => {
  return (
    <div className={classes.Card}>
      {img && (
        <picture className={classes.Preview}>
          <img src={img.attributes.url} className="img-cover" alt={title} />
        </picture>
      )}
      <Link to={to} className={classes.Content}>
        <p className="p1">{title}</p>
        <div className="icon-link">
          Перейти
          <ArrowRightIcon />
        </div>
      </Link>
    </div>
  );
};
