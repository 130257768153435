import classes from "./TopContent.module.css";
import { format } from "date-fns";
import { ru } from "date-fns/locale";
import { useNavigate } from "react-router-dom";
import { ArrowRightIcon } from "../../ui/_icons";

interface TopContentProps {
  title: string;
  publishedAt: string;
}

export const TopContent = ({ title, publishedAt }: TopContentProps) => {
  const navigate = useNavigate();

  return (
    <header className={classes.Top}>
      <div className={classes.Header}>
        <button className={classes.Back} onClick={() => navigate(-1)}>
          <ArrowRightIcon />
        </button>
        <h1 className="h2">{title}</h1>
      </div>
      <p className="p3">
        {format(new Date(publishedAt), "dd MMMM yyyy", {
          locale: ru,
        })}
      </p>
    </header>
  );
};
