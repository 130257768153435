export const FilterIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 4.25H13.3546C13.0579 3.51704 12.3393 3 11.5 3C10.6607 3 9.94207 3.51704 9.64538 4.25H0V5.75H9.64538C9.94207 6.48296 10.6607 7 11.5 7C12.3393 7 13.0579 6.48296 13.3546 5.75H16V4.25Z"
      fill="#CFD04C"
    />
    <path
      d="M6.35462 11.75H16V10.25H6.35462C6.05793 9.51704 5.33934 9 4.5 9C3.66066 9 2.94207 9.51704 2.64538 10.25H0V11.75H2.64538C2.94207 12.483 3.66066 13 4.5 13C5.33934 13 6.05793 12.483 6.35462 11.75Z"
      fill="#CFD04C"
    />
  </svg>
);
