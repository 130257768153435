import { useEffect, useState } from "react";
import { DealershipService } from "../api/DealershipService";
import { IDealership } from "../interfaces/IDealership";
import { promiseErrorHandle } from "../../../common/libs/promiseErrorHandle";

export const useDealerships = (
  isSale: boolean,
  isService: boolean,
  brandSlug?: string,
  searchQuery?: string,
) => {
  const [dealerships, setDealerships] = useState<IDealership[]>([]);
  const [isLoaded, setIsLoaded] = useState(false);

  const sort = (items: IDealership[]) => {
    return items.sort((a, b) => {
      const nameA = a.attributes.title.toUpperCase().replace("ооо", "");
      const nameB = b.attributes.title.toUpperCase().replace("ооо", "");
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    });
  };

  useEffect(() => {
    const dealership = new DealershipService();

    dealership
      .get(isSale, isService, brandSlug)
      .then((res) => {
        setDealerships(sort(res.data.data));
      })
      .catch((e) => {
        promiseErrorHandle(e);
      })
      .finally(() => {
        setIsLoaded(true);
      });
  }, [isSale, isService, brandSlug]);

  return {
    points: searchQuery
      ? dealerships.filter(
          (point) =>
            point.attributes.title
              .toLowerCase()
              .includes(searchQuery.toLowerCase()) ||
            point.attributes.address
              .toLowerCase()
              .includes(searchQuery.toLowerCase()),
        )
      : dealerships,
    isLoaded,
  };
};
