import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Line } from "../../ui/Line";

import { NAVIGATION } from "../../common/constants/navigation";

import { NewHero } from "../../components/NewHero";

import { SecondaryBrandInfo, useBrandBySlug } from "../../modules/Brands";
import { Layout } from "../../modules/Layout";
import { ProductsLinksBlock } from "../../modules/Products";
import { AutoSection } from "../../modules/Models";

export const AsiastarPage = () => {
  const navigate = useNavigate();

  const slug = NAVIGATION.buses.link.replace("/brands/", "");
  const { brand } = useBrandBySlug(slug);

  useEffect(() => {
    if (brand === null) return navigate("/404");
  }, [brand]);

  return brand ? (
    <Layout>
      <NewHero
        title={brand.attributes.brandName || ""}
        imgSm={brand.attributes.coverImage.data?.attributes.url}
      />
      <SecondaryBrandInfo brand={brand} />
      <Line />
      <ProductsLinksBlock
        title={brand.attributes.brandName || ""}
        additionalItems={[]}
        brandSlug={slug}
      />
      <AutoSection brandSlug={brand.attributes.slug} />
    </Layout>
  ) : null;
};
