import { Navigate, useParams } from "react-router-dom";

import { Layout } from "../../modules/Layout";
import { ModelGarantee } from "../../modules/Models/components/ModelGarantee";
import { useModel } from "../../modules/Models/hooks/useModels";
import { BottomLinks } from "../../modules/BottomLinks";
import { ModelCharacteristicSection } from "../../modules/Models/components/ModelCharacteristicSection";
import { Preview } from "../../modules/Models/components/Preview";
import { SimpleAutoSection } from "../../modules/Models/components/SimpleAutoSection";

export const ModelPage = () => {
  const { id: slug } = useParams();
  const model = useModel(slug);

  if (model === null) {
    return <Navigate to="/404" />;
  }

  return model ? (
    <Layout>
      <Preview {...model.attributes} />
      <ModelCharacteristicSection model={model} id={model.id.toString()} />
      <ModelGarantee slug={slug} />
      <SimpleAutoSection
        brandSlug={
          model.attributes.brands.data
            ? model.attributes.brands.data[0]?.attributes.slug
            : null
        }
      />
      <BottomLinks />
    </Layout>
  ) : null;
};
