export const LinkIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
  >
    <path
      d="M37.7573 17.6406L17.3926 38.0052"
      stroke="#CFD04C"
      strokeWidth="2"
      strokeLinecap="square"
    />
    <path
      d="M38.6072 35.4594L38.6072 16.7918L19.9396 16.7918"
      stroke="#CFD04C"
      strokeWidth="2"
      strokeLinecap="square"
    />
  </svg>
);
