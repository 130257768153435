import classes from "./Input.module.css";
import { JSX } from "react";
import classNames from "classnames";

interface InputProps {
  children: JSX.Element;
  error?: string;
  description?: string;
  touched?: boolean;
}

export const Input = ({
  children,
  error,
  description,
  touched,
}: InputProps) => {
  return (
    <label
      className={classNames(
        classes.Label,
        error && touched ? classes.Error : "",
      )}
    >
      {children}
      {error && touched && <small className={classes.ErrorMsg}>{error}</small>}
      {description && (
        <small className={classes.Description}>{description}</small>
      )}
    </label>
  );
};
